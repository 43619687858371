import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checklist-card',
  templateUrl: './checklist-card.component.html',
  styleUrls: ['./checklist-card.component.scss'],
})
export class ChecklistCardComponent  implements OnInit {
  @Input() cardTitle: string = "";
  @Input() existBottomLeftText:boolean = false;
  @Input() cardBottomRightText: string = "";
  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  onClickCard() {
    this.cardClick.emit();
  }

}
