import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { SelectBoxServiceService } from '../service/select-box-service.service';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
})
export class SelectBoxComponent implements OnInit {

  @Input() value: String;
  @Input() addCustomClass: string;
  @Input() addBackground: string;
  expandir_contraer: boolean = false
  @Input() addFilterIcon: boolean = false

  constructor(private service: SelectBoxServiceService) { }

  ngOnInit() {
    this.service.open_and_close.subscribe(res => this.expandir_contraer = res)
  }

  abrirCerrar() {
    this.expandir_contraer = !this.expandir_contraer
  }

}
