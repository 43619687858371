<div class="border-2 select-box rounded-lg custom-bg-select-box" [ngClass]="addCustomClass ? addCustomClass : ''">
  <div class="p-3 flex items-center " [ngClass]="expandir_contraer && addBackground ? addBackground :  expandir_contraer && !addBackground ? 'border-b-1 border-bottom-color' : ''" (click)="abrirCerrar()">
    <span class="w-11/12 flex gap-2 items-center">
      <ion-icon *ngIf="addFilterIcon" name="funnel" class="text-base leading-4 text-primary"></ion-icon>
      {{ value }}
    </span>
    <ion-icon [src]="expandir_contraer  ? 'assets/icon/up-arrow.svg' : 'assets/icon/down-arrow.svg' "
    class="text-lgmax self-center w-1/12"></ion-icon>
  </div>
  <div class="transition duration-300 overflow-y-auto max-h-0 scrollbar " [ngClass]="{'max-h-50': expandir_contraer}">
    <ng-content></ng-content>
  </div>
</div>
