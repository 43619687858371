import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pregunta-opc-texto',
  templateUrl: './pregunta-opc-texto.component.html',
  styleUrls: ['./pregunta-opc-texto.component.scss'],
})
export class PreguntaOpcTextoComponent  implements OnInit {
  @Input() question: any;
  @Input() customMaxlength: number | undefined = 100;
  @Input() customMinLength: number | undefined = undefined;
  @Input() displayErrorText: string | undefined = undefined;
  @Input() displayHelperText: string | undefined = undefined;

  constructor() { }

  ngOnInit() {}

  onKeyUp(event: any) {
    if( this.question.answer && this.customMaxlength ) {
      this.question.answer = this.question.answer.substring(0, this.customMaxlength);
      // console.log("key event:: ", this.question.answer);
    }

  }

}
