   <div appClickedOutside class="relative flex flex-col" (clickedOutside)="clickedOutside()">
    <div class="relative">
      <label class="label-floating z-2">{{placeholder}}</label>
      <input
        [value]="value"
        (input)="handleInputDefault($event)"
        (click)="handleInput($event)"
        [placeholder]="placeholder"
        ref="input"
        tabindex="0"
        [ngClass]="inputClass"
        *ngIf="!can_be_created_by_user"
        class="border-1 border-primary w-full"
        style="text-overflow: ellipsis"
      />
      <input
      [value]="value"
      (input)="handleInputDefaultCreate($event)"
      (click)="handleInput($event)"
      [placeholder]="placeholder"
      ref="input"
      tabindex="0"
      [ngClass]="inputClass"
      *ngIf="can_be_created_by_user && data_type === 'default'"
      class="border-1 border-primary w-full"
      style="text-overflow: ellipsis"
    />
      <input
        [value]="value"
        [placeholder]="placeholder"
        (input)="handleInput2($event)"
        type="date"
        ref="input"
        tabindex="0"
        [ngClass]="inputClass"
        class="border-1 border-primary w-full"
        *ngIf="can_be_created_by_user && data_type === 'date'"
        style="text-overflow: ellipsis"
        required
      />
      <input
        [value]="value"
        [placeholder]="placeholder"
        (input)="handleInput2($event)"
        type="text"
        ref="input"
        tabindex="0"
        class="border-1 border-primary w-full"
        [ngClass]="inputClass"
        (keypress)="filterKeyNumber($event)"
        *ngIf="can_be_created_by_user && data_type === 'number'"
        style="text-overflow: ellipsis"
      />
      <span
        *ngIf="value && !showOptions && (!can_be_created_by_user || (can_be_created_by_user && data_type !== 'date'))"
        (click)="reset()"
        class="absolute z-5 inset-y-0 right-0 pr-5 flex items-center cursor-pointer"
      >
        x
      </span>
      <ion-icon name="chevron-up" class="text-lg" *ngIf="showOptions && (!can_be_created_by_user || (can_be_created_by_user && data_type == 'default') ) && data?.length " class="absolute z-5 inset-y-0 right-0 pr-5 flex items-center cursor-pointer h-full" (click)="changeshowOptions()"></ion-icon>
    </div>
    <!-- <div (click)="changeshowOptions()"> -->
      <!-- <mat-icon class="mdi mdi-chevron-up text-4xl" *ngIf="showOptions && !can_be_created_by_user" (click)="changeshowOptions()" class="absolute z-5 inset-y-0 right-0 pr-5 flex items-center cursor-pointer"></mat-icon> -->

    <!-- </div> -->
    <div
      [hidden]="!showOptions || !data?.length"
      (focusout)="showOptions = false"
      tabindex="0"
      class="border-1 border-primary"
      [ngClass]="dropdownClass"
      [id]="'inputAuto'+code_api"
    >
      <ul class="py-1">
        <li
          *ngFor="let item of searchResults; let index = index"
          (click)="handleClick(item)"
          class="px-3 py-2 cursor-pointer hover:bg-gray-200"
        >
          {{ item.value_text }}
        </li>
        <li *ngIf="!searchResults.length" class="px-3 py-2 text-center">
          No hay resultados.
        </li>
      </ul>
    </div>
  </div>
