import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnimationOptions } from 'ngx-lottie';
import { RewardsService } from 'src/app/service/rewards/rewards.service';

@Component({
  selector: 'app-reward-received-modal',
  templateUrl: './reward-received-modal.component.html',
  styleUrls: ['./reward-received-modal.component.scss'],
})
export class RewardReceivedModalComponent  implements OnInit {
  @Input() coinsReceived: string = "";
  @Input() coinsName: string = "";
  @Input() reasons = [];
  @Input() coinImage: string = "";
  // currencyName: string = "";
  showConfetti = true

  coinJumpOptions: AnimationOptions = {
    path: '/assets/animations/coins-jump.json',
    loop: true,
    autoplay: true
  };

  constructor(
    private modalCtrl: ModalController,
    // private rewardsService: RewardsService,
  ) {
    // this.currencyName =  this.rewardsService.currencyName;
  }

  ngOnInit() {
    setTimeout(() => {
      this.showConfetti = false;
    }, 2000);
  }

  closeModal() {
		this.modalCtrl.dismiss();
	}

}
