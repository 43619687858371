import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-download-resource-item',
  templateUrl: './download-resource-item.component.html',
  styleUrls: ['./download-resource-item.component.scss'],
})
export class DownloadResourceItemComponent  implements OnInit {
  @Input() mediaTilte : string = "";
  @Output() resourceClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  onresourceClicked(){
    this.resourceClick.emit();
  }

}
