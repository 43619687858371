import { Component, Input, OnInit, Output } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-currency-counter',
  templateUrl: './currency-counter.component.html',
  styleUrls: ['./currency-counter.component.scss'],
})
export class CurrencyCounterComponent  implements OnInit {
  coinOptions: AnimationOptions = {
    path: '/assets/animations/coin.json',
    loop: true,
    autoplay: true
  };

  @Input() coins = 0;
  @Input() currencyName = "";
  @Input() coinCustomImg = "";
  @Output() coinClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  onCoinClicked() {
    this.coinClicked.emit();
  }

}
