import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ArrayrandonPipe } from './arrayrandom/arrayrandon.pipe';
import { KeysobjectPipe } from './Keysobject/keysobject.pipe';
import { ZoomUrl } from '../pipes/zoomurl/zoomurl';
import { DateAlumnoPipe } from './date-alumno/date-alumno.pipe';
import { HtmlRenderPipe } from './htmlRender/html-render.pipe';
import { MaskStringPipe } from './mask-string/mask-string.pipe';
import { RenderLocalFilePipe } from './render-local-file/render-local-file.pipe';
import { TrustHtmlPipe } from './trust-html/trust-html.pipe';
import { CompleteUrlPipe } from './chek-complete-url/complete-url.pipe';
import { FormatDatePipe } from './formatDate/format-date.pipe';
import { CapitalizePipe } from './capitalize-first/capitalize.pipe';

@NgModule({
  declarations: [
    ArrayrandonPipe,
    KeysobjectPipe,
    ZoomUrl,
    DateAlumnoPipe,
    HtmlRenderPipe,
    MaskStringPipe,
    RenderLocalFilePipe,
    TrustHtmlPipe,
    CompleteUrlPipe,
    FormatDatePipe,
    CapitalizePipe,
  ],
  exports: [
    ArrayrandonPipe,
    KeysobjectPipe,
    ZoomUrl,
    DateAlumnoPipe,
    HtmlRenderPipe,
    MaskStringPipe,
    RenderLocalFilePipe,
    TrustHtmlPipe,
    CompleteUrlPipe,
    FormatDatePipe,
    CapitalizePipe,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ]
})
export class PipesModule { }
