<ion-header>
	<ion-toolbar>
		<ion-title>{{ Escuela.nombre }}</ion-title>
	</ion-toolbar>
	<hr class="z-10 bg-white h-1 w-24 rounded-full mx-auto absolute -bottom-1 left-38" />
</ion-header>

<ion-content>
	<div *ngFor="let curso of Cursos; let i = index" class="my-2" [id]="curso.id">
		<!-- Curso nombre -->
		<div
			(click)="changeViewCurso(curso)"
			[ngClass]="CursoIndexActivo == curso.id ? 'rounded-t-lgmax' : 'rounded-lgmax opacity-90'"
			class="flex items-center py-4 px-3 bg-primary hover:bg-opacity-90 text-white shadow-xl relative cursor-pointer"
		>
			<ion-icon
				[src]="verificarIndexIcon(null, curso)"
				class="text-white text-xl align-text-bottom inline-block"
			>
			</ion-icon>

			<span class="ml-2 text-sm font-semibold leading-5 mr-6">{{ curso.nombre }}</span>

			<div class="flex items-center absolute right-0 mr-5 mt-1">
				<button
					*ngIf="curso.compatible"
					(click)="presentPopover($event, curso.compatible)"
					class="z-2 rounded-full flex items-center justify-center h-4 w-4 bg-white opacity-90 mr-3"
				>
					<ion-icon name="information-outline" class="info-icon noHover"></ion-icon>
				</button>

				<ion-icon
					[src]="
						CursoIndexActivo == curso.id ? 'assets/icon/up-arrow.svg' : 'assets/icon/down-arrow.svg'
					"
					class="curso-arrow text-white text-xl"
				>
				</ion-icon>
			</div>

		</div>
		<!-- Temas -->
		<div
			[ngClass]="CursoIndexActivo == curso.id ? 'temas-open' : 'temas-close'"
			class="temas-bg rounded-b-xl overflow-hidden"
		>
			<div
				*ngFor="let tema of curso.temas; let t = index"
				[ngClass]="TemaIndexActivo == tema.id ? 'tema-active' : 'text-gray-700'"
				(click)="changeViewTema(tema.id, curso.id, tema.id, tema.disponible)"
				class="tema py-5 my-1 px-3 text-sm font-bold flex cursor-pointer"
				[id]="tema.id"
			>
				<ion-icon
					[src]="verificarIndexIcon(tema)"
					class="text-xl align-text-bottom inline-block mr-2 w-10"
				>
				</ion-icon>
				<p class="w-full">{{ tema.nombre }}</p>
				<ion-icon
					[name]="verificarEstadoTemaIcon(tema.estado_tema)"
					class="text-xl align-text-bottom inline-block mr-2 w-6 opacity-50"
				>
				</ion-icon>
			</div>
			<div
				*ngIf="curso.encuesta && !curso.compatible"
				class="flex items-center justify-center p-2 text-sm"
				[ngClass]="{
					'bg-primary bg-opacity-20':
						!curso.encuesta_habilitada || (curso.encuesta_habilitada && curso.encuesta_resuelta)
				}"
			>
				<button
					(click)="rendirEncuesta(curso)"
					class="px-8 py-2 xl:px-16 xl:py-3 xl:text-xl bg-primary rounded-full font-semibold text-white text-xs md:text-base shadow-xl"
					*ngIf="curso.encuesta_habilitada && !curso.encuesta_resuelta"
				>
					Realizar encuesta
				</button>
				<!-- <button (click)="rendirEncuesta(curso)"
          class="px-8 py-2 xl:px-16 xl:py-3 xl:text-xl bg-primary rounded-full font-semibold text-white text-xs md:text-base shadow-xl">
          Realizar encuesta
        </button> -->
				<p *ngIf="curso.encuesta_resuelta" class="text-primary font-medium">Encuesta realizada</p>
				<p
					*ngIf="!curso.encuesta_habilitada && !curso.encuesta_resuelta"
					class="text-primary font-medium"
				>
					Realiza todas las evaluaciones para rendir la encuesta
				</p>
			</div>
		</div>
	</div>
</ion-content>

<ion-footer>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-button (click)="cerrarModal()"> Cerrar </ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-footer>
