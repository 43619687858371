<div class="flex flex-col gap-3 ">
  <ng-content select="[questionLabel]"></ng-content>

  <!-- <div class=""> -->
    <ion-radio-group
      class="question-radio flex flex-col gap-4"
      [(ngModel)]="question.answer"
      (ionChange)="handleChange($event)"
    >
      <ng-container *ngIf="optionsType === 'obj'">
        <ion-radio
          *ngFor="let option of questionOptions | keyvalue; let j = index"
          [value]="option.key"
          [disabled]="isRadioDisabled && option.key !== question.answer"
          color="primary"
          mode="md"
          labelPlacement="end"
          justify="start"
          ><span [outerHTML]="option.value"></span></ion-radio
        >
      </ng-container>

      <ng-container *ngIf="optionsType === 'array'">
        <ion-radio
          *ngFor="let option of questionOptions; let j = index"
          [value]="option.id"
          [disabled]="isRadioDisabled && option.id !== question.answer"
          color="primary"
          mode="md"
          labelPlacement="end"
          justify="start"
          ><span [outerHTML]="option.opc"></span></ion-radio
        >
      </ng-container>
    </ion-radio-group>
</div>
