<div class="meeting-card  flex flex-col md:flex-row md:p-3.5"
	[ngClass]="customClass ? customClass : 'card-default-with'">
	<!-- imagen -->
	<div class="meeting-image bg-image rounded-t-lg relative w-full">
		<img [src]="coverImg ? coverImg : '../../../assets/icon/event.png'" alt="Evento" class="w-full h-full object-cover rounded-t-lg md:rounded-lg" />
		<div class="absolute top-2 right-2 flex flex-col items-end md:hidden">
			<div class="flex chip-type-session text-base leading-4 px-3 py-1.5 justify-center items-center gap-2"
			[attr.data-tooltip]="typeDescription"
			[ngClass]="{
				'bg-primary_focused': typeOfSession === 'live',
				'bg-custom-lilac': typeOfSession === 'Presencial',
				'bg-custom-pink': typeOfSession === 'virtual',
				'tooltip-type-session': !isFisrtCard,
				'first-tooltip-session': isFisrtCard
			}"
			>
				<ion-icon *ngIf="typeOfSession === 'live'" name="videocam" class="text-white"></ion-icon>
				<mat-icon *ngIf="typeOfSession === 'Presencial'"  class="mdi mdi-human-male-board-poll text-white ">	</mat-icon>
				<!-- <ion-icon *ngIf="typeOfSession === 'virtual'" name="videocam" class=""></ion-icon> -->
				<span class="text-white" >{{ typeOfSession === "live" ? 'Sesiones live' : typeOfSession === "virtual" ? 'Curso síncrono' : typeOfSession }} </span>
			</div>
		</div>
	</div>
	<!-- information -->

	<div class="meeting-information-container py-3 px-6 lg:pl-6 lg:pr-2.5 md:py-1 flex flex-col items-center">
		<div class="session-title-area gap-2 border-b-1 border-solid division-line pb-3 mb-3 w-full">
			<div class=" flex flex-col gap-1">
				<h4 class="font-bold text-xl leading-5">{{ title }}</h4>
				<p *ngIf="subtitle" class="text-base font-normal text-gray-700">{{ subtitle }}</p>
			</div>

			<div class="hidden md:flex chip-type-session text-base leading-4 px-3 py-1.5 justify-center items-center gap-2"
			[attr.data-tooltip]="typeDescription"
			[ngClass]="{
				'bg-primary_focused': typeOfSession === 'live',
				'bg-custom-lilac': typeOfSession === 'Presencial',
				'bg-custom-pink': typeOfSession === 'virtual',
				'tooltip-type-session': !isFisrtCard,
				'first-tooltip-session': isFisrtCard
			}"
			>
				<ion-icon *ngIf="typeOfSession === 'live'" name="videocam" class="text-white"></ion-icon>
				<mat-icon *ngIf="typeOfSession === 'Presencial'"  class="mdi mdi-human-male-board-poll text-white ">	</mat-icon>
				<!-- <ion-icon *ngIf="typeOfSession === 'virtual'" name="videocam" class=""></ion-icon> -->
				<span class="text-white text-nowrap" >{{ typeOfSession === "live" ? 'Sesiones live' : typeOfSession === "virtual" ? 'Curso síncrono' : typeOfSession }} </span>
			</div>
		</div>
		<!-- recibe el template de más detalles o btns -->
		<ng-content></ng-content>
	</div>


</div>
