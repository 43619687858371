import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-anuncios',
  templateUrl: './card-anuncios.component.html',
  styleUrls: ['./card-anuncios.component.scss'],
})
export class CardAnunciosComponent  implements OnInit {
  @Input() mediaType: string;
  @Input() cardChipLeftIsActive: boolean = false;
  @Input() smScrollIsActive: boolean = false;
  @Input() cardMediaUrl: string;
  @Input() customClass: string;
  // @Input() audioUrl: string;
  @Input() audioAutoplay: boolean = false;
  @Input() applyBlur: boolean = false;
  @Input() applyScale: boolean = false;
  @Output() multimediaClick: EventEmitter<any> = new EventEmitter<any>();
  audioPlayerIsActive:boolean = false;

  constructor() { }

  ngOnInit() {
    // console.log(this.mediaType);
  }

  onClick() {
      this.multimediaClick.emit();
  }

  playAudio() {
    this.audioPlayerIsActive = true;
  }

}
