import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class CustomSearchBarComponent  implements OnInit {
  @Input() placeholder = "";
  @Input() debounce = 1000;
  @Output() searchQuery: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  onSearch(event) {
    event.preventDefault();
    this.searchQuery.emit(event.target.value);
  }

}
