<div class="title-padding" [ngClass]="{'title-grid-comp division-line border-b-1 border-solid': !isMobileSearchbarActive}">
  <div *ngIf="!isMobileSearchbarActive" class="title flex flex-row items-center ">
    <button  (click)="onBackClick()" class="flex justify-center items-center bg-transparent border-none h-fit">
      <mat-icon class="mdi mdi-chevron-left text-xl lg:text-[26px] leading-5 lg:leading-[26px] mr-3 font-bold mt-[2px]" [ngClass]="backIconClass ? backIconClass : ''"></mat-icon>
    </button>

    <!-- el icono debería tener la clase title-icon -->
    <ng-content select="[pageTitleIcon]"></ng-content>
    <span class="">{{title}} </span>
  </div>
  <div class="flex" [ngClass]="isMobileSearchbarActive ? 'w-full' : 'justify-end'">
    <!-- Buscar -->
    <ng-content select="[titleExtraComponent]"></ng-content>
  </div>
</div>
