<div class="platform-modal mx-auto text-center bg-white my-auto w-65 md:w-80 lg:w-106">
	<div class="bg-primary header-modal py-3 lg:py-3.5 xxl:py-4 font-bold text-lg lg:text-xl xxl:text-2xl text-white text-center relative">
		¡Redescubre {{esMultimarca ? 'Cursalab' : 'tu plataforma' }}!
		<ion-button  class="btn-close absolute -right-5 -top-5 lg:-right-3 lg:-top-3 flex justify-center items-center text-white rounded-full" (click)="cancelar()">
			<ion-ripple-effect></ion-ripple-effect>
			<ion-icon name="close" class="w-5 h-5"></ion-icon>
		</ion-button>
	</div>
	<div class="flex flex-col px-4 lg:px-10 pt-6 pb-4 lg:pt-8 lg:pb-6 text-left text-gray-900 text-sm lg:text-base font-normal leading-5">
		<div class="mb-6">
			<b>Descarga la app</b> y empieza a disfrutar de los beneficios de tener tu plataforma de capacitación al alcance de tu mano.
		</div>
		<div class="flex items-center justify-center">
			<button
				(click)="downloads()"
				class="btn-store flex items-center py-1.5 px-2 bg-primary text-white font-bold">
				<ion-icon src="assets/icon/google_play.svg" class="h-7 w-30">
				</ion-icon>
			</button>
		</div>
	</div>
</div>
