import { Component, OnInit } from "@angular/core";
import { ModalController } from '@ionic/angular';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-platform-available",
  templateUrl: "./platform-available.component.html",
  styleUrls: ["./platform-available.component.scss"],
})
export class PlatformAvailableComponent implements OnInit {
  esMultimarca : boolean = environment.APP_MULTIMARCA;
  constructor(private modalCtrl: ModalController) {

  }

  ngOnInit() { }

  downloads() {
    this.modalCtrl.dismiss({
      confirm: true
    });
  }
  cancelar() {
    this.modalCtrl.dismiss()
  }


}
