<!-- <div class="flex items-center w-full lg:justify-center" (click)="showFilters()">
  <ion-icon name="funnel" class="mx-2.5 lg:ml-0" ></ion-icon>
  <span class="filter-title" [ngClass]="{'block' : showFilter, 'hidden lg:block' : !showFilter}">Filtros</span>
</div> -->
<div [ngClass]="{'opacity-0 z-0 max-h-[1px] overflow-hidden': filterActive}">
  <ng-content select="[filterButton]"></ng-content>
</div>

<div *ngIf="filterActive" class="content-toggle bg-[#2A3649] px-6 py-3 rounded-lg text-white">
  <div class="flex gap-3 pb-1">
    <ion-icon name="funnel" class="text-lg" ></ion-icon>
    <span class="text-sm">Filtros</span>
  </div>
  <hr>
  <div class="list-toggle flex" *ngFor="let filter of filters; let i = index" >
    <span class="text-sm pr-4 xl:pr-6">{{filter.name}}</span>
    <ion-toggle class="toggle-filter" [checked]="filter.checked"
      (ionChange)="filter.checked = !filter.checked"></ion-toggle>
  </div>
  <hr />
  <button (click)="filter()" class="text-sm rounded-lg px-4 py-2 mt-2 bg-white text-primary font-medium hover:bg-gray-100">
    Aplicar
  </button>
</div>
