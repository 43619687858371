<div class="md-mask" *ngIf="showModal" (click)="closeModal()"></div>
<div *ngIf="showModal" class="modal-mask" id="modal-nps"
[ngClass]="from === 'home' ? 'lg:top-[11.5rem] lg:right-[8.5rem] right-large' : 'lg:top-[6rem] lg:right-[11.5rem]'"
>
	<div class="modal-wrapper">
		<div class="modal-container">
			<div class="text-base w-full lg:text-xl">
				<div class="flex text-xl font-bold items-center header-modal">
					<mat-icon class="lg:hidden mdi mdi-arrow-left text-2xl" (click)="closeModal()"></mat-icon>
					<mat-icon class="lg:hidden mdi mdi-bell"></mat-icon>
					<span>Notificaciones</span>
				</div>
				<div *ngIf="!loading" class="content-notification">
					<div *ngIf="listNotifications">
						<ion-list lines="none" *ngFor="let notifications of listNotifications; let i = index">
							<span class="text-base px-4">{{notifications.date}}</span>
							<div *ngFor="let notification of notifications.notifications;">
								<ion-item class="py-4 pl-4 pr-6" [ngClass]="{'unread': !notification.is_read, 'cursor-pointer': !notification.is_read || notification.path }" (click)="!notification.is_read ? readNotification(notification) : pathNotification(notification)" *ngIf="notification.is_visible">
									<mat-icon class="mdi text-primary mx-4 text-xl" [ngClass]="iconNotification(notification.type.code)"></mat-icon>
									<span class="text-sm leading-4 pr-6" [innerHtml]="notification.content"></span>
									<mat-icon class="mdi icon-rigth cursor-pointer" [ngClass]="notification.path ? 'mdi-chevron-right text-xl' : 'mdi-close text-base'" (click)="notification.path ? '' : hideNotification(notification, i)"></mat-icon>
									<mat-icon class="mdi mdi-circle-medium icon-left cursor-pointer" *ngIf="!notification.is_read"></mat-icon>
								</ion-item>
							</div>
						</ion-list>
					</div>
					<div *ngIf="!listNotifications || listNotifications.length == 0" class="container-list w-56 m-auto flex flex-col justify-center items-center gap-6">
						<!-- <IconSvg name="notifications-undraw" customClass="w-34 h-34 text-primary"></IconSvg> -->
						<app-image-svg name="notifications-image" customClass="w-48" ></app-image-svg>
						<span class="text-center block">
							No tienes <b>notificaciones</b> por el momento.
						</span>
					</div>
				</div>
				<div *ngIf="loading">
					<ion-list lines="none" class="pb-2" *ngFor="let notifications of [[0,1,2],[0]];">
						<ion-skeleton-text [animated]="true" class="w-50 h-5 mx-4 rounded-lg"></ion-skeleton-text>
						<div *ngFor="let notification of notifications;" class="px-4">
							<ion-skeleton-text [animated]="true" class="w-full h-26"></ion-skeleton-text>
						</div>
					</ion-list>
				</div>
			</div>
		</div>
	</div>
</div>
