<div class="relative w-full h-full " [ngClass]="{'bg-gray-1000': typeMedia === 'video'}">
  <div *ngIf="typeMedia === 'video'"  class="flex justify-between items-center px-1 lg:px-3 pt-1 absolute top-0  right-0 left-0 text-white text-base md:text-xl lg:text-2xl font-bold z-2" >
    <span >{{videoTitle}}</span>
    <button (click)="onClick()" class="flex justify-center items-center px-2 rounded-full hover:scale-105">
      <mat-icon class="mdi mdi-close leading-5 lg:leading-[24px] "></mat-icon>
    </button>
  </div>

  <video
  *ngIf="typeMedia == 'video'"
  class=" h-full object-cover"
  [src]="videoUrl"
  [poster]="posterImg"
  [autoplay]="setAutoPlay"
  controlsList="nodownload"
  width="100%"
  controls
  >
  </video>

  <iframe
    *ngIf="typeMedia == 'youtube'"
    class=""
    width="100%"
    height="100%"
    [src]="'https://www.youtube.com/embed/' + videoUrl + '?rel=0&modestbranding=0&showinfo=0&controls=1' | safe"
    frameborder="0"
    allowfullscreen
  ></iframe>

  <iframe
    *ngIf="typeMedia == 'vimeo'" class=""
    width="100%"
    height="100%"
    [src]="'https://player.vimeo.com/video/' + videoUrl | safe"
    frameborder="0"
    allow="autoplay; fullscreen"
    allowfullscreen
  >
  </iframe>
</div>
