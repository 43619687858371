<div
	class="pasantia-modal-container mx-auto text-center bg-white my-auto"
	[ngClass]=" customStyle ? customStyle : 'w-72 md:w-84'"
>
	<div
		class="bg-primary header-modal py-3 px-2 lg:py-3.5 font-bold text-base leading-5 text-white text-center relative"
	>
		<span [ngClass]=" customTitleSize ? customTitleSize : ''">{{ title }}</span> <br>
		<span *ngIf="subtitle">{{ subtitle }}</span>

		<ion-button
			class="btn-close absolute -right-5 -top-5 lg:-right-3 lg:-top-3 flex justify-center items-center text-white rounded-full"
			(click)="closeModal(false)"
			*ngIf="!notClose"
		>
			<ion-ripple-effect></ion-ripple-effect>
			<ion-icon name="close" class="w-5 h-5"></ion-icon>
		</ion-button>
	</div>
	<div class="flex flex-col px-4 py-4 text-gray-900 text-left text-base font-normal leading-5">
		<div class="pb-4 mb-4 w-full flex flex-col gap-2 text-center bx_step_1" *ngIf="step == 1">
			<ion-row>
				<ion-col size="12">
					<p class="font-bold text-md text-left">Selecciona a quién solicitarás una reunión</p>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col size="12">
					<div class="flex w-full my-1">
						<ion-searchbar
							class="search-list search-assistants py-1 text-sm bg-white px-1 focus:outline-none placeholder-gray-350 w-full text-left"
							placeholder="Buscar"
							debounce="700"
							inputmode="text"
							show-clear-button="never"
							(ionInput)="filterLeader()"
							[(ngModel)]="search_input"
							>
						</ion-searchbar>
					</div>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col size="12" size-lg="12" class="col-checklist">
					<div class="scroll-content-checklists">
						<div class="loading" *ngIf="cargando"><ion-spinner name="crescent"></ion-spinner></div>
						<div *ngIf="leaders_list && !cargando">
							<ion-list class="ion-list-checklist">
								<ion-card class="list-checklist m-0" *ngFor="let assistant of leaders_list">
									<ion-grid fixed class="grid-asistentes" (click)="verActividad(assistant)">
										<div class="flex w-full list_assistants_item items-center">
											<div class="w-full px-1">
												<p class="text-sm font-normal text-left">{{assistant.fullname}}</p>
											</div>
											<div class="icon_arrow">
												<mat-icon *ngIf="assistant.status == 'email_sent'" class="mdi mdi-check-circle text-lg" style="color: #DDDDDD;"></mat-icon>
												<mat-icon *ngIf="assistant.status == 'confirmed'" class="mdi mdi-check-circle text-lg" style="color: #4CAF50;"></mat-icon>
												<mat-icon *ngIf="assistant.status == 'pending'" class="mdi mdi-chevron-right text-sm"></mat-icon>
											</div>
										</div>
									</ion-grid>
								</ion-card>
							</ion-list>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</div>
		<div class="pb-4 mb-4 w-full flex flex-col gap-2 text-center bx_step_2" *ngIf="step == 2">
			<ion-row>
				<ion-col>
          <div class="text-center">
            <p class="font-normal text-sm text-left">Indica la fecha y hora que tienes disponible para reunirte con el lider</p>
          </div>
					<div class="text-center">
						<ion-icon src="assets/icon/calendar-pasantia.svg"  class="icon_calendar"></ion-icon>
					</div>
          <div class="text-center mb-4">
						<p class="font-normal text-sm text-left">Estas opciones le llegarán por correo al líder</p>
					</div>
					<div class="box_row_input">
						<span class="label_bri">Opción 1</span>
						<ion-row>
							<ion-col size="7" size-md="7" class="relative cursor-pointer">
								<div class="relative">
									<span class="label_bi">Fecha</span>
									<div class="relative" [ngClass]="{'px-4 py-4': !overlayInput1, 'p-y-date-ios': isIos && fecha_reunion1}">
										<input
										#DateInput1
										required
										type="date"
										[(ngModel)]="fecha_reunion1"

										class="native-input-date  w-full"

										[ngClass]="{
											'z-5 relative opacity-100 date-padding': overlayInput1,
											'opacity-0 z-0': !overlayInput1 && !isIos,
											'ios-date-input ios-date-padding': isIos

										}"
										/>

										<ion-input (click)="displayDatePicker1()"
										[ngClass]="isIos ? 'reset-ionic-style' : 'cover-date-input'"
										[readonly]="true"
										class="  cursor-pointer absolute w-full top-0 left-0  bottom-0 right-0  rounded-default bg-white"
										placeholder="Fecha"
                    fill="outline"
										[(ngModel)]="fecha_reunion1">
											<mat-icon class="mdi mdi-calendar-blank  text-2xl text-primary absolute top-5 right-1 date-input-icon z-10 "></mat-icon>
										</ion-input>
									</div>
								</div>
							</ion-col>
							<ion-col size="5" size-md="5" class="relative">
								<div class="box_input">
									<span class="label_bi">Hora</span>
									<app-time-picker
									class="block h-full w-full custom-input-time custom-input-time-pasantia"
									placeholder="Hora"
                  position_top="true"
									customClass="text-xl font-normal"
									(onSelectedTime)="setTime1($event)"
									[(selectedTime)]="hora_reunion1"
									></app-time-picker>
								</div>
							</ion-col>
						</ion-row>
					</div>
					<div class="box_row_input">
						<span class="label_bri">Opción 2 <span>(opcional)</span></span>
						<ion-row>
							<ion-col size="7" size-md="7" class="relative cursor-pointer">
								<div class="relative">
									<span class="label_bi">Fecha</span>
									<div class="relative" [ngClass]="{'px-4 py-4': !overlayInput2, 'p-y-date-ios': isIos && fecha_reunion2}">
										<input
										#DateInput2
										required
										type="date"
										[(ngModel)]="fecha_reunion2"

										class="native-input-date  w-full"

										[ngClass]="{
											'z-5 relative opacity-100 date-padding': overlayInput2,
											'opacity-0 z-0': !overlayInput2 && !isIos,
											'ios-date-input ios-date-padding': isIos

										}"
										/>

										<ion-input (click)="displayDatePicker2()"
										[ngClass]="isIos ? 'reset-ionic-style' : 'cover-date-input'"
										[readonly]="true"
										class="  cursor-pointer absolute w-full top-0 left-0  bottom-0 right-0  rounded-default bg-white"
										placeholder="Fecha"
                    fill="outline"
										[(ngModel)]="fecha_reunion2">
											<mat-icon class="mdi mdi-calendar-blank  text-2xl text-primary absolute top-5 right-1 date-input-icon z-10 "></mat-icon>
										</ion-input>
									</div>
								</div>
							</ion-col>
							<ion-col size="5" size-md="5" class="relative">
								<div class="box_input">
									<span class="label_bi">Hora</span>
									<app-time-picker
									class="block h-full w-full custom-input-time custom-input-time-pasantia"
									placeholder="Hora"
                  position_top="true"
									customClass="text-xl font-normal"
									(onSelectedTime)="setTime2($event)"
									[(selectedTime)]="hora_reunion2"
									></app-time-picker>
								</div>
							</ion-col>
						</ion-row>
					</div>
          <div class="flex items-center justify-center">
						<ion-button class="" type="button" color="primary" (click)="sendDate()" [disabled]="!(fecha_reunion1 && hora_reunion1)">
							<span> Mandar tentativa de fechas </span>
						</ion-button>
					</div>
				</ion-col>
			</ion-row>
		</div>
	</div>
</div>
