<div
	*ngIf="!parentForm"
	appClickedOutside
	(clickedOutside)="handleClickedOutside()"
	class="relative dropdown-select"
>
	<button
		(click)="openSelect = !openSelect"
		type="button"
		class="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm border-gray sm:text-sm sm:leading-6"
	>
		<ion-input [value]="selectedValue" [placeholder]="placeholder" [readonly]="true"></ion-input>
		<span class="absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
			<ion-icon
				[ngClass]="{ 'rotate-180': openSelect, 'rotate-0': !openSelect }"
				class="transition-transform duration-200 transform"
				name="chevron-down-outline"
			></ion-icon>
		</span>
	</button>

	<ul
		*ngIf="openSelect"
		class="max-h-50 absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg border-gray sm:text-sm"
	>
		<li
			*ngFor="let option of selectOptions"
			(click)="onSelectedOption(option)"
			[ngClass]="{ 'hover-cursalab-color': optionHoverColor === 'cursalab-color' }"
			class="text-gray-900 relative cursor-default select-none py-2 px-3"
		>
			<div class="flex items-center">
				<span class="font-normal block truncate">{{ option }} </span>
			</div>
		</li>
	</ul>
</div>

<div
	class="relative dropdown-select"
	*ngIf="parentForm"
	[formGroup]="parentForm"
	appClickedOutside
	(clickedOutside)="handleClickedOutside()"
>
	<button
		(click)="openSelect = !openSelect"
		type="button"
		class="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm border-gray sm:text-sm sm:leading-6"
	>
		<ion-input
			[formControlName]="parentFormControlName"
			[value]="selectedValue"
			[placeholder]="placeholder"
			[readonly]="true"
		></ion-input>
		<span class="absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
			<ion-icon
				[ngClass]="{ 'rotate-180': openSelect, 'rotate-0': !openSelect }"
				class="transition-transform duration-200 transform"
				name="chevron-down-outline"
			></ion-icon>
		</span>
	</button>

	<ul
		*ngIf="openSelect"
		class="max-h-50 absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg border-gray sm:text-sm"
	>
		<li
			*ngFor="let option of selectOptions"
			(click)="onSelectedOption(option)"
			[ngClass]="{ 'hover-cursalab-color': optionHoverColor === 'cursalab-color' }"
			class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
			id="listbox-option-0"
		>
			<div class="flex items-center">
				<span class="font-normal block truncate">{{ option }} </span>
			</div>
		</li>
	</ul>
</div>

