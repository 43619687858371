import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string | Date): string {
    const datePipe = new DatePipe('en-US'); // Instancia de DatePipe
    const dateValue = typeof value === 'string' ? new Date(value) : value;
    return datePipe.transform(dateValue, 'dd/MM/yyyy'); // Formateo de la fecha
  }
}
