<div class="flex w-full items-center gap-3">
  <ng-container *ngIf="!searchIsActive">
    <button class="py-1 px-2 flex justify-center items-center bg-transparent" (click)="onOpenSearchbar()">
      <ion-icon name="search-outline" class="header-search-sm text-black text-xl"></ion-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="searchIsActive">
    <button class="text-black  bg-transparent flex items-center justify-start" (click)="onCloseSearchbar()" >
      <!-- <ng-content select="[closeIcon]"></ng-content> -->
      <ion-icon class="text-xl text-black" name="arrow-back"></ion-icon>
    </button>
    <app-custom-search-bar [placeholder]="placeholderMobile" (searchQuery)="onQueryhMobile($event)" class="grow-default" ></app-custom-search-bar>
  </ng-container>
</div>
