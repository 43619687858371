import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent  implements OnInit {
  @Output() closePlayerClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() posterImg: string = "";
  @Input() typeMedia: string = "";
  @Input() videoUrl: string = "";
  @Input() videoTitle: string = "";
  @Input() setAutoPlay: boolean = false;

  constructor() { }

  ngOnInit() {}

  onClick() {
    this.closePlayerClick.emit();
  }

}
