import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { PopoverComponent } from 'src/app/components/popover/popover.component';

@Component({
  selector: 'app-cursos-list-modal',
  templateUrl: './cursos-list-modal.component.html',
  styleUrls: ['./cursos-list-modal.component.scss'],
})
export class CursosListModalComponent implements OnInit {

  Cursos: any;
  CursoIndexActivo: any
  TemaIndexActivo: any
  TemaActivo: any
  CursoActivo: any
  Escuela: any

  constructor(private activatedRoute: ActivatedRoute,
    private navParams: NavParams,
    private modalController: ModalController,
    private popoverController: PopoverController,
    ) { }

  ngOnInit() {
    this.Cursos = this.navParams.get('cursos')
    this.CursoIndexActivo = this.navParams.get('CursoIndexActivo')
    this.CursoActivo = this.CursoIndexActivo;
    this.TemaIndexActivo = this.navParams.get('TemaIndexActivo')
    this.Escuela = this.navParams.get('escuela')
    console.log('Index curso ', this.navParams.get('CursoIndexActivo'));
  }

  changeViewCurso(curso) {
    if (!curso.disponible) return
    if (this.CursoIndexActivo == curso.id) {
      this.CursoIndexActivo = null;
      return;
    }
    this.CursoIndexActivo = curso.id;
  }

  changeViewTema(index, cursoID, temaID, disponible) {
    if (!disponible) return
    this.TemaIndexActivo = index;
    let Curso = this.Cursos.find((obj) => obj.id === cursoID);
    let Tema = Curso.temas.find((obj) => obj.id === temaID);

    console.log('Curso ', Curso);
    console.log('tema ', Tema);

    this.TemaActivo = Tema;
    this.modalController.dismiss({
      'dismissed': true, // Opcional
      tema_id: Tema.id,
      curso_id: Curso.id,
      disponible
    });
  }

  verificarEstadoTemaIcon(estado) {
    switch (estado) {
      case "revisado":
        return "checkmark";
        break;
      case "aprobado":
        return "checkmark-done";
        break;
      default:
        "--";
        break;
    }
  }

  verificarIndexIcon(tema?, curso?) {
    if (tema) {
      if (!tema.disponible) {
        return 'assets/icon/lock.svg'
      } else {
        if (tema.id == this.TemaIndexActivo)
          return 'assets/icon/view.svg'
        else
          return 'assets/icon/candado_abierto.svg'
      }
    }
    if (curso) {
      if (!curso.disponible) {
        return 'assets/icon/lock.svg'
      } else {
        if (curso.id == this.CursoActivo)
          return 'assets/icon/view.svg'
        else
          return 'assets/icon/candado_abierto.svg'
      }
    }
  }

  rendirEncuesta(curso) {
    this.modalController.dismiss({
      'dismissed': true, // Opcional
      curso: curso,
      escuela: this.Escuela.data.id
    });
  }

  cerrarModal() {
    this.modalController.dismiss()
  }

  async presentPopover(ev: Event, cursoCompatible) {

		ev.preventDefault();
		ev.stopPropagation();
		const popover = await this.popoverController.create({
		  component: PopoverComponent,
		  cssClass: 'custom-popover',
      	  event: ev,
		  mode: "ios",
		  componentProps: { tooltipMsg: "Curso convalidado" }

		});

		await popover.present();
	  }
}
