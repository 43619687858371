import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit {

  @Input() tiempo: any;

  @Input() delay: string = '0';

  @Input() classes: string = '';

  @Output() onComplete = new EventEmitter();

  private interval: number;

  public countdown: string;

  public days: number;

  private completed: boolean;

  private countdownObservable: Subscription

  constructor() { }

  ngOnInit() {
    try {
      console.log(this.tiempo, this.delay);
      let tiempo: Array<any> = (this.tiempo.split(' ')[1]).split(':')
      let dias: Array<any> = (this.tiempo.split(' ')[0]).split('/')
      console.log(dias);
      this.days = parseInt(dias[2])
      this.interval = parseInt(tiempo[0]) * 3600 + parseInt(tiempo[1]) * 60 + parseInt(tiempo[2]) + parseInt(this.delay)
      this.countdown = this.getTime()
      this.countdownObservable = timer(1000, 1000).subscribe(val => {
        this.manipulateInterval()
        this.countdown = this.getTime()
        if (this.interval === 0) {
          console.log(this.interval)
          this.completed = true;
        }
      });

    } catch (error) {
      console.log(error);
    }
  }

  private getTime(): string {
    if (this.interval < 0) {
      this.interval = Math.abs(this.interval);
      this.completed = true;
    }
    const hours = Math.floor(this.interval / 3600);
    const minutes = Math.floor((this.interval - (hours * 3600)) / 60);
    const seconds = (this.interval - (hours * 3600) - (minutes * 60));
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  private manipulateInterval() {
    if (this.completed) {
      this.onComplete.emit();
      this.countdownObservable.unsubscribe();
    } else {
      this.interval--;
    }
  }

  ngOnDestroy() {
    try {
      this.countdownObservable.unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }
}
