import { Component, Input, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-mobile-color-options',
  templateUrl: './select-mobile-color-options.component.html',
  styleUrls: ['./select-mobile-color-options.component.scss'],
})
export class SelectMobileColorOptionsComponent implements OnInit {

  title: string = "";
  optionsList: string[];
  @Input()customClass = "";

  constructor(
    private navParams: NavParams, 
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.title = this.navParams.get('title');
    this.optionsList = this.navParams.get('optionsList');
  }

  async onSelectedOption(selectedValue: any) {
    await this.modalController.dismiss({ selectedValue });
  }


}
