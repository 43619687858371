<span class="">
    <svg *ngIf="name == 'notebook'" [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M3,7V5H5V4C5,2.89 5.9,2 7,2H13V9L15.5,7.5L18,9V2H19C20.05,2 21,2.95 21,4V20C21,21.05 20.05,22 19,22H7C5.95,22 5,21.05 5,20V19H3V17H5V13H3V11H5V7H3M7,11H5V13H7V11M7,7V5H5V7H7M7,19V17H5V19H7Z" />
    </svg>
  
    <svg *ngIf="name == 'notebook-check'"  [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M16.75 22.16L14 19.16L15.16 18L16.75 19.59L20.34 16L21.5 17.41L16.75 22.16M3 7V5H5V4C5 2.89 5.9 2 7 2H13V9L15.5 7.5L18 9V2H19C20.05 2 21 2.95 21 4V13.8C20.12 13.29 19.09 13 18 13C14.69 13 12 15.69 12 19C12 20.09 12.29 21.12 12.8 22H7C5.95 22 5 21.05 5 20V19H3V17H5V13H3V11H5V7H3M5 5V7H7V5H5M5 19H7V17H5V19M5 13H7V11H5V13Z" />
    </svg>
  
    <svg *ngIf="name == 'notebook-remove'"  [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M3 7V5H5V4C5 2.9 5.9 2 7 2H13V9L15.5 7.5L18 9V2H19C20 2 21 3 21 4V13.8C20.1 13.3 19.1 13 18 13C14.7 13 12 15.7 12 19C12 20.1 12.3 21.1 12.8 22H7C5.9 22 5 21 5 20V19H3V17H5V13H3V11H5V7H3M5 5V7H7V5H5M5 19H7V17H5V19M5 13H7V11H5V13M20.1 15.5L18 17.6L15.9 15.5L14.5 16.9L16.6 19L14.5 21.1L15.9 22.5L18 20.4L20.1 22.5L21.5 21.1L19.4 19L21.5 16.9L20.1 15.5Z" />
    </svg>
  
    <svg *ngIf="name == 'notebook-edit'"  [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M21.04 13.13C21.18 13.13 21.31 13.19 21.42 13.3L22.7 14.58C22.92 14.79 22.92 15.14 22.7 15.35L21.7 16.35L19.65 14.3L20.65 13.3C20.76 13.19 20.9 13.13 21.04 13.13M19.07 14.88L21.12 16.93L15.06 23H13V20.94L19.07 14.88M3 7V5H5V4C5 2.89 5.9 2 7 2H13V9L15.5 7.5L18 9V2H19C20.05 2 21 2.95 21 4V10L11 20V22H7C5.95 22 5 21.05 5 20V19H3V17H5V13H3V11H5V7H3M5 7H7V5H5V7M5 11V13H7V11H5M5 17V19H7V17H5Z" />
    </svg>
  
    <svg *ngIf="name == 'file-clock-outline'" [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
          d="M4 2A2 2 0 0 0 2 4V20A2 2 0 0 0 4 22H12.41A7 7 0 0 0 16 23A7 7 0 0 0 23 16A7 7 0 0 0 18 9.3V8L12 2H4M4 4H11V9H16A7 7 0 0 0 9 16A7 7 0 0 0 10.26 20H4V4M16 11A5 5 0 0 1 21 16A5 5 0 0 1 16 21A5 5 0 0 1 11 16A5 5 0 0 1 16 11M15 12V17L18.61 19.16L19.36 17.94L16.5 16.25V12H15Z" />
    </svg>
  
    <svg *ngIf="name == 'download'" [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
    </svg>
  
    <svg *ngIf="name == 'clipboard-text-clock-outline'" [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
          d="M21 11.11V5C21 3.9 20.11 3 19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H11.11C12.37 22.24 14.09 23 16 23C19.87 23 23 19.87 23 16C23 14.09 22.24 12.37 21 11.11M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M5 19V5H7V7H17V5H19V9.68C18.09 9.25 17.08 9 16 9H7V11H11.1C10.5 11.57 10.04 12.25 9.68 13H7V15H9.08C9.03 15.33 9 15.66 9 16C9 17.08 9.25 18.09 9.68 19H5M16 21C13.24 21 11 18.76 11 16S13.24 11 16 11 21 13.24 21 16 18.76 21 16 21M16.5 16.25L19.36 17.94L18.61 19.16L15 17V12H16.5V16.25Z" />
    </svg>
  
    <svg *ngIf="name == 'trophy-outline'" [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
          d="M18 2C17.1 2 16 3 16 4H8C8 3 6.9 2 6 2H2V11C2 12 3 13 4 13H6.2C6.6 15 7.9 16.7 11 17V19.08C8 19.54 8 22 8 22H16C16 22 16 19.54 13 19.08V17C16.1 16.7 17.4 15 17.8 13H20C21 13 22 12 22 11V2H18M6 11H4V4H6V11M16 11.5C16 13.43 15.42 15 12 15C8.59 15 8 13.43 8 11.5V6H16V11.5M20 11H18V4H20V11Z" />
    </svg>
  
    <svg *ngIf="name == 'medal-outline'" [ngClass]="customClass"  fill="currentColor"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
          d="M14.94 19.5L12 17.77L9.06 19.5L9.84 16.16L7.25 13.92L10.66 13.63L12 10.5L13.34 13.63L16.75 13.92L14.16 16.16M20 2H4V4L8.86 7.64A8 8 0 1 0 15.14 7.64L20 4M18 15A6 6 0 1 1 10.82 9.12A5.86 5.86 0 0 1 13.18 9.12A6 6 0 0 1 18 15M12.63 7H11.37L7.37 4H16.71Z" />
    </svg>
  
    <svg  *ngIf="name == 'medal-outline2'" [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" >
      <rect fill="none" height="24" width="24" />
      <path
          d="M9.68,13.69L12,11.93l2.31,1.76l-0.88-2.85L15.75,9h-2.84L12,6.19L11.09,9H8.25l2.31,1.84L9.68,13.69z M20,10 c0-4.42-3.58-8-8-8s-8,3.58-8,8c0,2.03,0.76,3.87,2,5.28V23l6-2l6,2v-7.72C19.24,13.87,20,12.03,20,10z M12,4c3.31,0,6,2.69,6,6 s-2.69,6-6,6s-6-2.69-6-6S8.69,4,12,4z M12,19l-4,1.02v-3.1C9.18,17.6,10.54,18,12,18s2.82-0.4,4-1.08v3.1L12,19z" />
    </svg>
  
    <svg  *ngIf="name == 'file-checkbox'" [ngClass]="customClass"  fill="currentColor"  id="bold" enable-background="new 0 0 24 24" viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg">
      <rect width="13" height="17" rx="2"/>
      <path d="M4 8.33333L5.78571 10L9 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  
    <svg  *ngIf="name == 'file-closebox'" [ngClass]="customClass"  fill="currentColor" id="bold" enable-background="new 0 0 24 24" viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg">
      <rect width="13" height="17" rx="2"/>
      <path d="M9 6L4 11" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 6L9 11" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  
    <svg  *ngIf="name == 'file-filled'" [ngClass]="customClass"  fill="currentColor" id="bold" enable-background="new 0 0 24 24" viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8523 7.28571H7.68182C7.21166 7.28571 6.76076 7.09381 6.42831 6.75223C6.09586 6.41065 5.90909 5.94736 5.90909 5.46429V0.151786C5.90909 0.11153 5.89353 0.0729224 5.86582 0.044457C5.83812 0.0159917 5.80054 0 5.76136 0H2.36364C1.73676 0 1.13556 0.255867 0.692293 0.711312C0.249025 1.16676 0 1.78447 0 2.42857V14.5714C0 15.2155 0.249025 15.8332 0.692293 16.2887C1.13556 16.7441 1.73676 17 2.36364 17H10.6364C11.2632 17 11.8644 16.7441 12.3077 16.2887C12.751 15.8332 13 15.2155 13 14.5714V7.4375C13 7.39724 12.9844 7.35864 12.9567 7.33017C12.929 7.30171 12.8915 7.28571 12.8523 7.28571Z"/>
      <path d="M12.2205 5.91029L7.1803 0.482401C7.1705 0.471908 7.15804 0.464769 7.14448 0.461882C7.13092 0.458996 7.11687 0.46049 7.1041 0.466178C7.09133 0.471865 7.0804 0.481491 7.0727 0.493847C7.06499 0.506202 7.06085 0.520736 7.06079 0.535619V5.4351C7.06079 5.59527 7.11987 5.74887 7.22503 5.86212C7.3302 5.97538 7.47283 6.039 7.62155 6.039H12.1711C12.1849 6.03894 12.1984 6.03448 12.2099 6.02618C12.2213 6.01788 12.2303 6.00611 12.2356 5.99236C12.2408 5.9786 12.2422 5.96347 12.2395 5.94887C12.2369 5.93427 12.2302 5.92085 12.2205 5.91029Z"/>
    </svg>
  
    <svg *ngIf="name == 'arm-flex-outline'" [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
          d="M7 7.76V16.25H11.08L11.68 15.34C12.84 13.55 14.93 12.75 16.47 12.75C17 12.75 17.45 12.84 17.79 13C18.7 13.41 18.95 14.18 19 14.74C19.08 15.87 18.5 17.03 17.5 17.71C16.6 18.33 14.44 19 11.87 19C10.12 19 7.61 18.69 5.12 17.3C5.41 14.85 6 10.88 7 7.76M7 3C4 7.09 3 18.34 3 18.34C5.9 20.31 9.08 21 11.87 21C14.86 21 17.39 20.21 18.64 19.36C21.64 17.32 21.94 12.71 18.64 11.18C18 10.89 17.26 10.75 16.47 10.75C14.17 10.75 11.5 11.96 10 14.25H9V7.09H11L12 4L7 3Z" />
    </svg>
  
    <svg  *ngIf="name == 'user-lock'" [ngClass]="customClass"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 640 512">
      <path
        d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H392.6c-5.4-9.4-8.6-20.3-8.6-32V352c0-2.1 .1-4.2 .3-6.3c-31-26-71-41.7-114.6-41.7H178.3zM528 240c17.7 0 32 14.3 32 32v48H496V272c0-17.7 14.3-32 32-32zm-80 32v48c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32V272c0-44.2-35.8-80-80-80s-80 35.8-80 80z" />
    </svg>


    <!-- Imagenes -->
      <!-- <svg *ngIf="name === 'search-not-found'" [ngClass]="customClass"  viewBox="0 0 436 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M436 0.248047H0.186523V279.362H436V0.248047Z" fill="#E6E6E6"/>
        <path d="M423.537 35.2539H12.6475V262.848H423.537V35.2539Z" fill="white"/>
        <path d="M435.814 0H0V18.515H435.814V0Z" fill="currentColor"/>
        <path d="M13.7588 12.8964C15.654 12.8964 17.1904 11.36 17.1904 9.46481C17.1904 7.56958 15.654 6.0332 13.7588 6.0332C11.8635 6.0332 10.3271 7.56958 10.3271 9.46481C10.3271 11.36 11.8635 12.8964 13.7588 12.8964Z" fill="white"/>
        <path d="M26.7841 12.8964C28.6794 12.8964 30.2157 11.36 30.2157 9.46481C30.2157 7.56958 28.6794 6.0332 26.7841 6.0332C24.8889 6.0332 23.3525 7.56958 23.3525 9.46481C23.3525 11.36 24.8889 12.8964 26.7841 12.8964Z" fill="white"/>
        <path d="M39.8095 12.8964C41.7048 12.8964 43.2411 11.36 43.2411 9.46481C43.2411 7.56958 41.7048 6.0332 39.8095 6.0332C37.9143 6.0332 36.3779 7.56958 36.3779 9.46481C36.3779 11.36 37.9143 12.8964 39.8095 12.8964Z" fill="white"/>
        <path d="M155.563 58.8691H49.9834V239.234H155.563V58.8691Z" fill="#E6E6E6"/>
        <path d="M261.77 58.8691H173.787V108.517H261.77V58.8691Z" fill="currentColor"/>
        <path d="M261.77 122.342H173.787V175.76H261.77V122.342Z" fill="#E6E6E6"/>
        <path d="M261.77 189.588H173.787V239.235H261.77V189.588Z" fill="#E6E6E6"/>
        <path d="M386.202 58.8691H280.623V137.471H386.202V58.8691Z" fill="#E6E6E6"/>
        <path d="M386.202 160.633H280.623V239.235H386.202V160.633Z" fill="#E6E6E6"/>
        <path d="M259.612 40.8112C250.28 32.9564 238.86 27.9765 226.742 26.4776C214.624 24.9787 202.33 27.0254 191.357 32.3685C180.384 37.7117 171.204 46.121 164.935 56.5731C158.666 67.0252 155.578 79.0695 156.046 91.2404C156.515 103.411 160.52 115.184 167.574 125.126C174.628 135.068 184.427 142.75 195.778 147.239C207.13 151.727 219.544 152.828 231.511 150.406C243.478 147.985 254.482 142.147 263.183 133.601L340.448 198.632C341.624 199.623 343.146 200.108 344.68 199.98C346.214 199.852 347.634 199.121 348.628 197.948C349.622 196.776 350.108 195.257 349.98 193.727C349.851 192.197 349.119 190.78 347.943 189.789L347.934 189.782L270.67 124.751C279.646 111.902 283.487 96.1722 281.442 80.6448C279.396 65.1173 271.611 50.9115 259.612 40.8112ZM254.631 118.489C248.612 125.605 240.612 130.78 231.644 133.361C222.676 135.941 213.142 135.812 204.248 132.988C195.354 130.164 187.5 124.772 181.677 117.495C175.855 110.219 172.327 101.383 171.539 92.1063C170.751 82.8294 172.739 73.5278 177.251 65.3778C181.763 57.2277 188.596 50.5952 196.887 46.319C205.178 42.0427 214.554 40.3149 223.83 41.3539C233.105 42.3929 241.863 46.1521 248.997 52.1561C253.733 56.1426 257.636 61.0206 260.483 66.5116C263.329 72.0027 265.064 77.9992 265.587 84.1588C266.11 90.3184 265.412 96.5205 263.532 102.411C261.652 108.301 258.628 113.765 254.631 118.489Z" fill="#3F3D56"/>
        <path opacity="0.3" d="M187.842 124.063C178.75 116.468 172.876 105.767 171.386 94.0849C169.896 82.4022 172.9 70.5923 179.8 61C178.894 61.9033 178.018 62.8493 177.172 63.838C173.158 68.5462 170.121 73.9907 168.233 79.8608C166.345 85.7309 165.644 91.9116 166.169 98.0499C166.695 104.188 168.436 110.164 171.295 115.636C174.154 121.108 178.073 125.969 182.83 129.942C187.587 133.915 193.087 136.921 199.018 138.79C204.949 140.658 211.193 141.353 217.395 140.833C223.596 140.313 229.634 138.589 235.162 135.759C240.691 132.93 245.602 129.05 249.616 124.342C250.46 123.352 251.254 122.338 252 121.3C243.608 129.646 232.351 134.564 220.466 135.075C208.58 135.587 196.935 131.656 187.842 124.063Z" fill="black"/>
      </svg> -->

      <svg *ngIf="name === 'progress-undraw'" [ngClass]="customClass"  viewBox="0 0 584 427" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M498.162 218.162H0V0H498.162L498.162 218.162Z" fill="white"/>
        <path d="M498.162 218.162H0V0H498.162L498.162 218.162ZM4.81903 213.343H493.343V4.81902H4.81903V213.343Z" fill="#E5E5E5"/>
        <path d="M249.285 175.642L163.756 111.579L79.3959 174.766L77.4648 172.189L163.756 107.556L248.877 171.313L333.465 78.7465L333.805 78.6095L419.13 44.2305L420.333 47.2175L335.348 81.4585L249.285 175.642Z" fill="#3F3D56"/>
        <path d="M78.431 183.137C83.7657 183.137 88.0904 178.813 88.0904 173.478C88.0904 168.143 83.7657 163.818 78.431 163.818C73.0962 163.818 68.7715 168.143 68.7715 173.478C68.7715 178.813 73.0962 183.137 78.431 183.137Z" fill="currentColor"/>
        <path d="M163.755 120.35C169.09 120.35 173.415 116.025 173.415 110.691C173.415 105.356 169.09 101.031 163.755 101.031C158.42 101.031 154.096 105.356 154.096 110.691C154.096 116.025 158.42 120.35 163.755 120.35Z" fill="currentColor"/>
        <path d="M249.081 183.137C254.416 183.137 258.741 178.813 258.741 173.478C258.741 168.143 254.416 163.818 249.081 163.818C243.747 163.818 239.422 168.143 239.422 173.478C239.422 178.813 243.747 183.137 249.081 183.137Z" fill="currentColor"/>
        <path d="M334.407 89.7623C339.741 89.7623 344.066 85.4376 344.066 80.1028C344.066 74.7681 339.741 70.4434 334.407 70.4434C329.072 70.4434 324.747 74.7681 324.747 80.1028C324.747 85.4376 329.072 89.7623 334.407 89.7623Z" fill="currentColor"/>
        <path d="M419.732 54.3443C425.066 54.3443 429.391 50.0196 429.391 44.6849C429.391 39.3501 425.066 35.0254 419.732 35.0254C414.397 35.0254 410.072 39.3501 410.072 44.6849C410.072 50.0196 414.397 54.3443 419.732 54.3443Z" fill="currentColor"/>
        <path d="M430.11 202.802C431.273 202.674 432.393 202.293 433.392 201.685C434.392 201.077 435.246 200.257 435.894 199.283C436.542 198.31 436.969 197.206 437.145 196.049C437.32 194.893 437.24 193.712 436.91 192.589L460.048 176.062L446.259 170.752L426.4 187.261C424.552 187.941 423.017 189.275 422.086 191.011C421.156 192.747 420.894 194.764 421.352 196.679C421.809 198.595 422.953 200.277 424.567 201.405C426.181 202.534 428.154 203.031 430.11 202.802Z" fill="#FFB7B7"/>
        <path d="M442.955 191.917L435.279 178.806L435.442 178.62L466.413 143.361L490.459 102.354L511.341 105.015L511.649 111.327L511.586 111.421L478.411 161.291L478.376 161.321L442.955 191.917Z" fill="#3F3D56"/>
        <path d="M436.49 202.322L395.002 225.802L364.641 172.156L406.129 148.676L436.49 202.322Z" fill="#E5E5E5"/>
        <path d="M404.983 169.093L382.736 181.684L384.9 185.506L407.147 172.915L404.983 169.093Z" fill="currentColor"/>
        <path d="M410.607 179.032L388.36 191.623L390.524 195.446L412.771 182.855L410.607 179.032Z" fill="currentColor"/>
        <path d="M416.232 188.97L393.985 201.561L396.149 205.383L418.396 192.792L416.232 188.97Z" fill="currentColor"/>
        <path d="M545.283 413.763H533.475L527.857 368.215L545.285 368.216L545.283 413.763Z" fill="#FFB7B7"/>
        <path d="M548.295 425.209L510.219 425.208V424.726C510.219 420.796 511.781 417.026 514.56 414.247C517.339 411.468 521.109 409.906 525.039 409.906L548.296 409.907L548.295 425.209Z" fill="#2F2E41"/>
        <path d="M443.653 413.377L432.168 410.634L437.281 365.027L454.232 369.075L443.653 413.377Z" fill="#FFB7B7"/>
        <path d="M443.924 425.21L406.889 416.366L407 415.898C407.913 412.075 410.308 408.771 413.656 406.713C417.005 404.655 421.034 404.012 424.857 404.925L447.478 410.326L443.924 425.21Z" fill="#2F2E41"/>
        <path d="M489.547 81.5287V61.6516C489.545 58.1524 490.232 54.6871 491.57 51.4537C492.907 48.2203 494.869 45.2821 497.342 42.8071C499.816 40.332 502.753 38.3686 505.986 37.0291C509.218 35.6895 512.683 35 516.182 35C519.681 35 523.146 35.6895 526.379 37.0291C529.611 38.3686 532.548 40.332 535.022 42.8071C537.495 45.2821 539.457 48.2203 540.795 51.4537C542.132 54.6871 542.82 58.1524 542.817 61.6516V81.5287C542.816 82.4773 542.439 83.3867 541.768 84.0574C541.098 84.7282 540.188 85.1055 539.24 85.1066H493.125C492.176 85.1055 491.267 84.7282 490.596 84.0574C489.925 83.3867 489.548 82.4772 489.547 81.5287Z" fill="#2F2E41"/>
        <path d="M509.608 84.8061C520.393 84.8061 529.136 76.0631 529.136 65.2781C529.136 54.493 520.393 45.75 509.608 45.75C498.823 45.75 490.08 54.493 490.08 65.2781C490.08 76.0631 498.823 84.8061 509.608 84.8061Z" fill="#FFB7B7"/>
        <path d="M483.246 63.2435C483.252 57.6574 485.474 52.3019 489.424 48.352C493.374 44.402 498.73 42.1801 504.316 42.1738H508.291C513.878 42.1801 519.233 44.402 523.183 48.352C527.133 52.302 529.355 57.6575 529.361 63.2435V63.6411H520.96L518.094 55.6172L517.521 63.6411H513.179L511.733 59.5927L511.444 63.6411H483.246V63.2435Z" fill="#2F2E41"/>
        <path d="M520.998 168.346L471.277 166.453L468.497 133.924C466.354 127.97 466.319 122.858 468.395 118.73C470.179 115.34 473.178 112.748 476.791 111.473L487.671 98.3329C489.071 96.6435 490.828 95.2864 492.817 94.3595C494.806 93.4326 496.975 92.9592 499.169 92.9734L527.436 93.1666C528.118 92.7567 532.25 90.6564 540.402 94.1897C549.243 98.0226 547.19 111.334 547.168 111.468L547.148 111.593L547.05 111.675L528.247 127.51L520.998 168.346Z" fill="#3F3D56"/>
        <path d="M471.674 162.957L448.155 205.837L430.324 400.919L450.47 407.072L486.132 254.467L527.518 402.742L551.668 403.347L535.225 232.236L520.855 165.987L471.674 162.957Z" fill="#2F2E41"/>
        <path d="M534.508 232.376C535.379 231.594 536.068 230.631 536.526 229.555C536.984 228.479 537.201 227.315 537.161 226.146C537.12 224.977 536.824 223.831 536.293 222.789C535.762 221.746 535.009 220.833 534.087 220.113L543.269 193.201L528.967 196.917L522.441 221.903C521.334 223.533 520.864 225.511 521.118 227.464C521.373 229.417 522.335 231.209 523.823 232.5C525.31 233.791 527.22 234.491 529.189 234.468C531.159 234.445 533.051 233.701 534.508 232.376Z" fill="#FFB7B7"/>
        <path d="M538.61 216.046L524.734 209.86L529.397 162.915L525.058 115.576L543.581 105.576L547.508 110.527L547.512 110.639L549.593 170.5L549.582 170.546L538.61 216.046Z" fill="#3F3D56"/>
        <path d="M583.324 427H325.676C325.497 427 325.325 426.895 325.198 426.707C325.071 426.52 325 426.265 325 426C325 425.735 325.071 425.48 325.198 425.293C325.325 425.105 325.497 425 325.676 425H583.324C583.503 425 583.675 425.105 583.802 425.293C583.929 425.48 584 425.735 584 426C584 426.265 583.929 426.52 583.802 426.707C583.675 426.895 583.503 427 583.324 427Z" fill="#CBCBCB"/>
      </svg>

      <svg *ngIf="name === 'notifications-undraw'" [ngClass]="customClass"  viewBox="0 0 561 493" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_62_5626)">
        <path d="M556.531 485.951C555.55 487.321 554.561 488.681 553.581 490.031C550.763 491.971 547.421 493.007 544 493.001H17.0005C13.7321 493.003 10.533 492.059 7.79053 490.281C6.88037 489.081 5.98047 487.871 5.07031 486.66L5.98047 486.16L272.771 338.281C275.138 336.971 277.798 336.283 280.503 336.279C283.208 336.276 285.87 336.957 288.241 338.261L555.63 485.451L556.531 485.951Z" fill="currentColor"/>
        <path d="M544 175L312.782 41.4699C303.057 35.8536 292.025 32.8942 280.795 32.8887C269.565 32.8831 258.531 35.8317 248.8 41.4384L17 175C12.4933 175.007 8.17297 176.8 4.98624 179.987C1.7995 183.173 0.00637539 187.494 0 192V476C0.00525083 480.507 1.798 484.828 4.98499 488.015C8.17197 491.202 12.4929 492.995 17 493H544C548.506 492.993 552.826 491.199 556.012 488.013C559.199 484.826 560.992 480.507 561 476V192C560.994 187.494 559.201 183.173 556.014 179.986C552.827 176.8 548.507 175.007 544 175ZM559 476C558.99 479.976 557.407 483.785 554.596 486.596C551.785 489.407 547.975 490.991 544 491H17C13.0239 490.993 9.2128 489.41 6.40131 486.599C3.58981 483.787 2.00715 479.976 2 476V192C2.00484 188.024 3.58675 184.211 6.39874 181.399C9.21074 178.587 13.0232 177.005 17 177L248.8 43.4384C258.531 37.8317 269.565 34.8831 280.795 34.8887C292.025 34.8942 303.057 37.8536 312.782 43.4699L544 177C547.977 177.005 551.789 178.587 554.601 181.399C557.413 184.211 558.995 188.024 559 192V476Z" fill="#3F3D56"/>
        <path d="M556.57 182.38L484 223.18L471.5 230.21L288.25 333.25C285.973 334.52 283.408 335.184 280.8 335.18C279.115 335.185 277.442 334.904 275.85 334.35C275.408 334.208 274.974 334.042 274.55 333.85C274.141 333.67 273.741 333.47 273.35 333.25L102 236.96L89.5004 229.94L4.58044 182.22C4.34953 182.088 4.18015 181.869 4.10903 181.613C4.03791 181.356 4.07079 181.082 4.20055 180.85C4.33067 180.619 4.54695 180.45 4.8019 180.379C5.05685 180.307 5.32964 180.34 5.56042 180.47L89.5004 227.64L102 234.66L274.33 331.5C274.902 331.821 275.497 332.098 276.11 332.33C276.37 332.43 276.641 332.52 276.911 332.6C278.622 333.125 280.422 333.299 282.203 333.11C283.983 332.921 285.707 332.373 287.27 331.5L471.5 227.92L484 220.89L555.59 180.64C555.704 180.576 555.83 180.534 555.96 180.519C556.091 180.503 556.223 180.513 556.349 180.548C556.475 180.584 556.593 180.643 556.696 180.724C556.8 180.805 556.886 180.906 556.95 181.02C557.015 181.134 557.056 181.26 557.071 181.39C557.087 181.52 557.077 181.652 557.042 181.779C557.007 181.905 556.947 182.023 556.866 182.126C556.785 182.229 556.684 182.316 556.57 182.38Z" fill="#3F3D56"/>
        </g>
        <defs>
        <clipPath id="clip0_62_5626">
        <rect width="561" height="493" fill="white"/>
        </clipPath>
        </defs>
      </svg>    
      
      <svg 
			*ngIf="name === 'icon-first_badge_state'"
			 [ngClass]="customClass" 
			viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M32.5845 24.298H17.5844L0 0H15L32.5845 24.298Z" fill="#E2574C"/>
			<path d="M12.418 24.298H27.418L45.0002 0H30.0002L12.418 24.298Z" fill="#BF392C"/>
			<path d="M23.3757 50C33.6384 50 41.9579 42.2124 41.9579 32.606C41.9579 22.9995 33.6384 15.212 23.3757 15.212C13.113 15.212 4.79346 22.9995 4.79346 32.606C4.79346 42.2124 13.113 50 23.3757 50Z" fill="#E4E7E7"/>
			<path d="M23.3757 44.8267C20.7931 44.8267 18.2685 44.1099 16.1211 42.7668C13.9738 41.4237 12.3001 39.5148 11.3118 37.2814C10.3235 35.0479 10.0649 32.5903 10.5688 30.2193C11.0726 27.8483 12.3163 25.6705 14.1424 23.9611C15.9686 22.2517 18.2952 21.0876 20.8282 20.6159C23.3612 20.1443 25.9867 20.3864 28.3727 21.3115C30.7586 22.2366 32.798 23.8032 34.2328 25.8133C35.6676 27.8233 36.4334 30.1864 36.4334 32.6039C36.4293 35.8444 35.0523 38.9511 32.6044 41.2425C30.1564 43.5339 26.8375 44.8229 23.3757 44.8267V44.8267ZM23.3757 23.2953C21.4083 23.2953 19.4851 23.8414 17.8494 24.8646C16.2136 25.8878 14.9387 27.342 14.1859 29.0434C13.4332 30.7448 13.2363 32.617 13.6203 34.4232C14.0043 36.2293 14.9518 37.8883 16.3431 39.1903C17.7344 40.4924 19.5069 41.379 21.4365 41.738C23.3661 42.097 25.3661 41.9123 27.1836 41.2073C29.0011 40.5023 30.5544 39.3086 31.6471 37.7772C32.7398 36.2458 33.3228 34.4455 33.3223 32.6039C33.3211 30.1343 32.273 27.7661 30.4081 26.0193C28.5432 24.2724 26.0139 23.2898 23.3757 23.287V23.2953Z" fill="#CDCFCF"/>
			<path d="M23.9488 27.1165L24.9488 29.9996C24.9887 30.1141 25.0663 30.2138 25.1705 30.2844C25.2747 30.355 25.4001 30.3929 25.5288 30.3927H28.7665C28.8977 30.39 29.0263 30.4269 29.1333 30.4981C29.2402 30.5693 29.3197 30.6709 29.3599 30.7878C29.4001 30.9048 29.3989 31.0307 29.3564 31.1469C29.314 31.2631 29.2326 31.3634 29.1243 31.4328L26.5043 33.2155C26.3997 33.2856 26.3218 33.3852 26.2818 33.4996C26.2418 33.6141 26.2419 33.7376 26.2821 33.852L27.2821 36.735C27.3218 36.8496 27.3217 36.9729 27.2819 37.0874C27.2421 37.2019 27.1645 37.3017 27.0604 37.3724C26.9562 37.443 26.8309 37.481 26.7022 37.4809C26.5736 37.4808 26.4483 37.4425 26.3443 37.3716L23.7265 35.5826C23.6227 35.5116 23.4974 35.4734 23.3688 35.4734C23.2402 35.4734 23.1149 35.5116 23.011 35.5826L20.3932 37.3632C20.2892 37.4341 20.164 37.4724 20.0353 37.4726C19.9067 37.4727 19.7813 37.4347 19.6772 37.364C19.573 37.2933 19.4955 37.1936 19.4556 37.0791C19.4158 36.9646 19.4157 36.8412 19.4554 36.7267L20.4577 33.8437C20.4971 33.7292 20.4969 33.606 20.4569 33.4916C20.417 33.3773 20.3395 33.2777 20.2354 33.2071L17.6154 31.4245C17.5072 31.3551 17.4258 31.2548 17.3833 31.1386C17.3409 31.0224 17.3397 30.8964 17.3799 30.7795C17.4201 30.6626 17.4996 30.561 17.6065 30.4898C17.7134 30.4186 17.842 30.3816 17.9732 30.3844H21.2132C21.3415 30.3842 21.4664 30.3461 21.5702 30.2755C21.6739 30.2049 21.7512 30.1054 21.791 29.9913L22.791 27.1082C22.8317 26.9942 22.91 26.8951 23.0146 26.8252C23.1191 26.7553 23.2446 26.7181 23.3731 26.7191C23.5016 26.72 23.6265 26.7589 23.7299 26.8303C23.8333 26.9017 23.9099 27.0019 23.9488 27.1165Z" fill="#CDCFCF"/>
		  </svg>

      <svg 
        *ngIf="name === 'icon-second_badge_state'"
        [ngClass]="customClass"  viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.5844 24.298H17.5844L0 0H15L32.5844 24.298Z" fill="#E2574C"/>
        <path d="M12.418 24.298H27.418L45.0002 0H30.0002L12.418 24.298Z" fill="#BF392C"/>
        <path d="M23.3757 50C33.6384 50 41.9579 42.2124 41.9579 32.606C41.9579 22.9995 33.6384 15.212 23.3757 15.212C13.113 15.212 4.79346 22.9995 4.79346 32.606C4.79346 42.2124 13.113 50 23.3757 50Z" fill="#F8D57D"/>
        <path d="M23.3757 44.8267C20.7931 44.8267 18.2685 44.1099 16.1211 42.7668C13.9738 41.4237 12.3001 39.5148 11.3118 37.2814C10.3235 35.0479 10.0649 32.5903 10.5688 30.2193C11.0726 27.8483 12.3163 25.6705 14.1424 23.9611C15.9686 22.2517 18.2952 21.0876 20.8282 20.6159C23.3612 20.1443 25.9867 20.3864 28.3727 21.3115C30.7586 22.2366 32.798 23.8032 34.2328 25.8133C35.6676 27.8233 36.4334 30.1864 36.4334 32.6039C36.4293 35.8444 35.0523 38.9511 32.6044 41.2425C30.1564 43.5339 26.8375 44.8229 23.3757 44.8267V44.8267ZM23.3757 23.2953C21.4083 23.2953 19.4851 23.8414 17.8494 24.8646C16.2136 25.8878 14.9387 27.342 14.1859 29.0434C13.4332 30.7448 13.2363 32.617 13.6203 34.4232C14.0043 36.2293 14.9518 37.8883 16.3431 39.1903C17.7344 40.4924 19.5069 41.379 21.4365 41.738C23.3661 42.097 25.3661 41.9123 27.1836 41.2073C29.0011 40.5023 30.5544 39.3086 31.6471 37.7772C32.7398 36.2458 33.3228 34.4455 33.3223 32.6039C33.3211 30.1343 32.273 27.7661 30.4081 26.0193C28.5432 24.2724 26.0139 23.2898 23.3757 23.287V23.2953Z" fill="#F2C03E"/>
        <path d="M23.9488 27.1165L24.9488 29.9996C24.9887 30.1141 25.0663 30.2138 25.1705 30.2844C25.2747 30.355 25.4001 30.3929 25.5288 30.3927H28.7665C28.8977 30.39 29.0263 30.4269 29.1333 30.4981C29.2402 30.5693 29.3197 30.6709 29.3599 30.7878C29.4001 30.9048 29.3989 31.0307 29.3564 31.1469C29.314 31.2631 29.2326 31.3634 29.1243 31.4328L26.5043 33.2155C26.3997 33.2856 26.3218 33.3852 26.2818 33.4996C26.2418 33.6141 26.2419 33.7376 26.2821 33.852L27.2821 36.735C27.3218 36.8496 27.3217 36.9729 27.2819 37.0874C27.2421 37.2019 27.1645 37.3017 27.0604 37.3724C26.9562 37.443 26.8309 37.481 26.7022 37.4809C26.5736 37.4808 26.4483 37.4425 26.3443 37.3716L23.7265 35.5826C23.6227 35.5116 23.4974 35.4734 23.3688 35.4734C23.2402 35.4734 23.1149 35.5116 23.011 35.5826L20.3932 37.3632C20.2892 37.4341 20.164 37.4724 20.0353 37.4726C19.9067 37.4727 19.7813 37.4347 19.6772 37.364C19.573 37.2933 19.4955 37.1936 19.4556 37.0791C19.4158 36.9646 19.4157 36.8412 19.4554 36.7267L20.4577 33.8437C20.4971 33.7292 20.4969 33.606 20.4569 33.4916C20.417 33.3773 20.3395 33.2777 20.2354 33.2071L17.6154 31.4245C17.5072 31.3551 17.4258 31.2548 17.3833 31.1386C17.3409 31.0224 17.3397 30.8964 17.3799 30.7795C17.4201 30.6626 17.4996 30.561 17.6065 30.4898C17.7134 30.4186 17.842 30.3816 17.9732 30.3844H21.2132C21.3415 30.3842 21.4664 30.3461 21.5702 30.2755C21.6739 30.2049 21.7512 30.1054 21.791 29.9913L22.791 27.1082C22.8317 26.9942 22.91 26.8951 23.0146 26.8252C23.1191 26.7553 23.2446 26.7181 23.3731 26.7191C23.5016 26.72 23.6265 26.7589 23.7299 26.8303C23.8333 26.9017 23.9099 27.0019 23.9488 27.1165Z" fill="#F2C03E"/>
      </svg>
        
  </span>
  