<ion-button 
  mode="md"
  class="app-secondary-btn text-base font-semibold"
  [ngClass]="customClassAppBtn ? customClassAppBtn : '' " 
  [type]="type"
  (click)="onClickBtn()"
  [disabled]="isDisabled"
>
    <span *ngIf="addIcon && addIcon === 'left'" class="mr-2" [ngClass]="iconClass ? iconClass : '' ">
      <ng-content select="[leftIcon]"></ng-content>
    </span>
    {{btnText}}
    <span *ngIf="addIcon && addIcon === 'right'" class="mr-2" [ngClass]="iconClass ? iconClass : '' ">
      <ng-content select="[rightIcon]"></ng-content>
    </span>
</ion-button>

