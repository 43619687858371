<div class="question-type-chekbox flex flex-col gap-3">
  <ng-content select="[questionLabel]"></ng-content>

  <div class="z-1  flex flex-col gap-4">
    <ng-container *ngIf="optionsType === 'obj'">
      <ion-checkbox
        *ngFor="let option of questionOptions | keyvalue; let j = index"
        (ionChange)="updateQuestionResponses(option.key)"
        mode="md"
        labelPlacement="end"
        justify="start"
      ><span [outerHTML]="option.value"></span>
      </ion-checkbox>
    </ng-container>

    <ng-container *ngIf="optionsType === 'array'">
      <ion-checkbox
        *ngFor="let option of questionOptions; let j = index"
        (ionChange)="updateQuestionResponses(option)"
        mode="md"
        labelPlacement="end"
        justify="start"
      ><span [outerHTML]="option"></span>
      </ion-checkbox>
    </ng-container>
  </div>
</div>
