import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Network } from "@ionic-native/network/ngx";
import { Network } from '@capacitor/network';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

/*
  Generated class for the ConexionServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export enum ConnectionStatus {
  Online,
  Offline,
}
@Injectable({ providedIn: 'root' })
export class ConexionServiceProvider {
  //   private hasConnection = new BehaviorSubject(false);
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(
    ConnectionStatus.Offline
  );

  constructor(
    // private network: Network,
    private platform: Platform,
    public http: HttpClient,
    public router: Router
  ) {
    console.log('constructor network service');
  }

  public async initializeNetworkEvents() {
    Network.addListener('networkStatusChange', (status) => {
      console.log('***addListener Network status changed', status);

      if (status.connected) {
        if (this.status.getValue() === ConnectionStatus.Offline) {
          console.log('WE ARE ONLINE');
          const forceOffline = localStorage.getItem('chooseOffline');
          if ( forceOffline && forceOffline === 'true' && status.connectionType !== 'none') {
            console.log('offline switch is active');
          } else {
            this.updateNetworkStatus(ConnectionStatus.Online);
          }
        }
      } else {
        if (this.status.getValue() === ConnectionStatus.Online) {
          console.log('WE ARE OFFLINE');
          this.updateNetworkStatus(ConnectionStatus.Offline);
        }
      }
    });

    // this.network.onDisconnect().subscribe(() => {
    // 	if (this.status.getValue() === ConnectionStatus.Online) {
    // 		console.log("WE ARE OFFLINE");
    // 		this.updateNetworkStatus(ConnectionStatus.Offline);
    // 	}
    // });

    // this.network.onConnect().subscribe(() => {
    // 	if (this.status.getValue() === ConnectionStatus.Offline) {
    // 		console.log("WE ARE ONLINE");
    //             const forceOffline = localStorage.getItem("chooseOffline");
    //             if(forceOffline && forceOffline === "true" && this.network.type !== "none") {
    //                 console.log("offline switch is active");
    //             } else {
    //                 this.updateNetworkStatus(ConnectionStatus.Online);
    //             };
    // 	}
    // });
    const statusObj = await Network.getStatus();
    console.log('Network getStatus statusObj:', statusObj);

    let status = statusObj.connectionType !== 'none'
        ? ConnectionStatus.Online
        : ConnectionStatus.Offline;
    this.status.next(status);
    console.log('initialize network service22: ', this.status.getValue());
    if (status == ConnectionStatus.Offline) {
      console.log('**offline - redirect from service!!**');
      //si tiene coneción
      if (statusObj.connectionType !== 'none') {
        this.router.navigate(['cursos']);
      } else {
        this.router.navigate(['offline-message']);
      }
    }
  }

  public async updateNetworkStatus(
    status: ConnectionStatus,
    fromTopic = false
  ) {
    this.status.next(status);

    if (status == ConnectionStatus.Offline && !fromTopic) {
      console.log('offline - redirect from service!!');
      //si tiene coneción
      const statusObj = await Network.getStatus();
      if (statusObj.connectionType !== 'none') {
        this.router.navigate(['cursos']);
      } else {
        this.router.navigate(['offline-message']);
      }
    }
    if (status == ConnectionStatus.Online && !fromTopic) {
      console.log('online - redirect from service!!');
      this.router.navigate(['anuncios']);
    }
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }
  public async getDeviceNetworkType(): Promise<any> {
    //	return this.network.type;
    const statusObj = await Network.getStatus();
    return statusObj.connectionType;
  }


}
