<div
	appClickedOutside
	(clickedOutside)="handleClickedOutside()"
	class="relative w-full h-full custom-time-picker"
>
	<button
		(click)="openTimer = !openTimer"
		type="button"
		class="relative flex w-full h-full cursor-pointer rounded-md bg-white py-1.5 pl-0 pr-10 text-left  border-none shadow-none"
    [ngClass]="customClass ? customClass : ''"
	>
		<ion-input mode="ios" [(ngModel)]="selectedTime" [placeholder]="placeholder" [readonly]="true" class="my-auto"></ion-input>
		<!-- <ion-input mode="md" [value]="selectedTime ? (selectedHour | number:'2.0-0')  + ':' + (selectedMinute | number:'2.0-0')  : ''" [placeholder]="placeholder" [readonly]="true" class="my-auto"></ion-input> -->
		<span class="absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
      <mat-icon class="mdi mdi-clock  text-2xl text-primary"></mat-icon>
		</span>
	</button>

	<div
		*ngIf="openTimer"
		class="picker-container px-2  absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg border-gray sm:text-sm flex flex-col"
    [ngClass]="{'position_top': position_top}"
	>

     <div class="w-full wrapper flex gap-1">

        <div class="w-1/2 flex flex-col items-center h-full">
         <div class="w-full py-1 flex justify-center items-center"> <p class="h-6 leading-6">Horas</p></div>
          <div class="select-time-wrapper flex flex-col items-center ">
            <div *ngFor="let hour of hours; index as i"
              class="cursor-pointer font-medium p-2 w-11/12 rounded-md flex justify-center"
              [ngClass]="selectedHour === i ? 'bg-primary_light_variant' : 'hover:bg-gray-100'"
              (click)="setHour(hour)"
            >{{hour | number:'2.0-0'}}
            </div>
          </div>
        </div>

        <div class="w-1/2 flex flex-col items-center h-full ">
          <div class="w-full py-1 flex justify-center items-center"> <p class="h-6 leading-6">Minutos</p></div>
          <div class="select-time-wrapper  flex flex-col items-center ">
            <div *ngFor="let minute of minutes; index as i"
                  class="cursor-pointer font-medium p-2 w-11/12 rounded-md flex justify-center"
                  [ngClass]="selectedMinute === i ? 'bg-primary_light_variant' : 'hover:bg-gray-100'"
                  (click)="setMinute(minute)"
            >
              {{minute | number:'2.0-0'}}
            </div>
          </div>
        </div>

     </div>

     <div class="picker-btns flex justify-end items-center gap-2 lg:gap-3 xl:gap-5 pt-2 pb-1">
        <button class="bg-transparent border-none px-4 xl:px-5 py-2 text-primary rounded-md hover:bg-gray-100 " (click)="handleClickedOutside()">Cancelar</button>
        <button class="bg-primary border-none px-4 xl:px-5 py-2 text-white rounded-md hover:bg-primary_focused" (click)="setTime()" [disabled]="selectedHour === null || selectedMinute === null">Aceptar</button>
     </div>
	</div>
</div>
