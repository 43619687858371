import { Injectable } from "@angular/core";
import { UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { FuncionesServiceProvider, UsuarioServiceProvider } from "src/app/service/index.providers";

@Injectable({
	providedIn: "root",
})
export class CheckLoginGuard {
	constructor(
		private up: UsuarioServiceProvider,
		private router: Router,
		private fp: FuncionesServiceProvider
	) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.up.isLogged.pipe(
			take(1),
			map((isLogged: boolean) => {
				if (isLogged) {
					// console.log('Login on');
					this.fp.cargarFunciones();
					this.router.navigate([""]);
					return false;
				}
				// console.log('Login off');
				return true;
			})
		);
	}
}
