<div class="flex flex-col gap-3 q-valoration">
  <ng-content select="[questionLabel]"></ng-content>

  <div class="">
    <ng-container *ngIf="valorationType === 'emoji'">
      <ion-radio-group
        (ionChange)="handleChange($event)"
        [(ngModel)]="question.answer"
        class="question-radio flex flex-col"
      >
        <div class="flex flex-col items-center relative" *ngFor="let option of question.opciones">
          <ion-radio
            class="opacity-0"
            [value]="option.value"
            color="primary"
            mode="md"
            labelPlacement="stacked" alignment="center"
          >{{option.text}}
          </ion-radio>

          <div class=" absolute top-0 left-0 right-0 bottom-0  flex flex-col items-center gap-3 hover:bg-gray-100">
            <ion-icon *ngIf="valorationSelected != option.value" name="ellipse-outline" class="text-3xl leading-[30px] text-gray-500 min-w-[30px]  min-h-[30px]"></ion-icon>
            <span *ngIf="valorationSelected == option.value"  class="text-3xl leading-[30px]" >{{option.emoji}} </span>
            <span class="text-xs lg:text-sm text-center"><span [outerHTML]="option.text"></span></span>
          </div>
        </div>
      </ion-radio-group>
    </ng-container>

    <ng-container *ngIf="valorationType === 'stars'">
      <ion-radio-group
      (ionChange)="handleChange($event)"
      [(ngModel)]="question.answer"
      class="question-radio flex flex-col"
    >
      <div class="flex flex-col items-center relative" *ngFor="let option of question.opciones">
        <ion-radio
          class="opacity-0"
          [value]="option.value"
          color="primary"
          mode="md"
          labelPlacement="stacked" alignment="center"
        >{{option.text}}
        </ion-radio>

        <div class="absolute top-0 left-0 right-0 bottom-0 w-[60px] flex flex-col items-center gap-3 hover:bg-gray-100">
          <img *ngIf="valorationSelected >= option.value; else outlineStar" src="assets/icon/star-filled.svg" class="w-[30px] h-[30px]">
          <span class="text-xs lg:text-sm"><span [outerHTML]="option.text"></span></span>
        </div>
      </div>

      <!-- <div class="flex flex-col items-center relative">
        <ion-radio
          class="opacity-0"
          [value]="2"
          color="primary"
          mode="md"
          labelPlacement="stacked" alignment="center"
        >2
        </ion-radio>

        <div class="absolute top-0 left-0 right-0 bottom-0 w-[60px] flex flex-col items-center gap-3">
          <img *ngIf="valorationSelected >= 2; else outlineStar" src="assets/icon/star-filled.svg" class="w-[30px] h-[30px]">
          <span class="text-xs lg:text-sm">Malo</span>
        </div>
      </div>

      <div class="flex flex-col items-center relative">
        <ion-radio
          class="opacity-0"
          [value]="3"
          color="primary"
          mode="md"
          labelPlacement="stacked" alignment="center"
        >3
        </ion-radio>

        <div class="absolute top-0 left-0 right-0 bottom-0 w-[60px] flex flex-col items-center gap-3">
          <img *ngIf="valorationSelected >= 3; else outlineStar" src="assets/icon/star-filled.svg" class="w-[30px] h-[30px]">
          <span class="text-xs lg:text-sm">Regular</span>
        </div>
      </div>

      <div class="flex flex-col items-center relative">
        <ion-radio
          class="opacity-0"
          [value]="4"
          color="primary"
          mode="md"
          labelPlacement="stacked" alignment="center"
        >4
        </ion-radio>

        <div class="absolute top-0 left-0 right-0 bottom-0 w-[60px] flex flex-col items-center gap-3">
          <img *ngIf="valorationSelected >= 4; else outlineStar" src="assets/icon/star-filled.svg" class="w-[30px] h-[30px]">
          <span class="text-xs lg:text-sm">Bueno</span>
        </div>
      </div>

      <div class="flex flex-col items-center relative">
        <ion-radio
          class="opacity-0"
          [value]="5"
          color="primary"
          mode="md"
          labelPlacement="stacked" alignment="center"
        >5
        </ion-radio>

        <div class="absolute top-0 left-0 right-0 bottom-0 w-[60px] flex flex-col items-center gap-3">
          <img *ngIf="valorationSelected >= 5; else outlineStar" src="assets/icon/star-filled.svg" class="w-[30px] h-[30px]">
          <span class="text-xs lg:text-sm">Excelente</span>
        </div>
      </div> -->

      <ng-template #outlineStar>
        <img src="assets/icon/star-outline.svg" class="w-[30px] h-[30px]">
      </ng-template>
    </ion-radio-group>

    </ng-container>
  </div>
</div>
