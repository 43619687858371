<div (click)="onCoinClicked()"  class="currency-counter gap-3 py-2 px-4 lg:py-4 lg:px-5 shadow-coin items-center bg-white">
  <div class="w-[40px] h-[40px]">
    <img *ngIf="coinCustomImg" [src]="coinCustomImg" class="w-full h-full object-contain">
    <ng-lottie
    *ngIf="!coinCustomImg"
    [options]="coinOptions"
    width="100%"
    height="100%"
    ></ng-lottie>
  </div>
  <div class="flex flex-col gap-1">
    <span class="text-3xl leading-[30px] font-bold">{{coins}}</span>
    <span class="text-[11px]">{{currencyName  | capitalize}}</span>
  </div>

</div>
