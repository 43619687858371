import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pregunta-opc-nps',
  templateUrl: './pregunta-opc-nps.component.html',
  styleUrls: ['./pregunta-opc-nps.component.scss'],
})
export class PreguntaOpcNpsComponent  implements OnInit {
  @Input() question: any;
  @Input() textMax: string = "";
  @Input() textMin: string = "";

  constructor() { }

  ngOnInit() {}

}
