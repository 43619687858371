<div
	class="signature-modal-container mx-auto text-center bg-white my-auto"
	[ngClass]=" customStyle ? customStyle : 'w-72 md:w-84'"
>
	<div class="bg-primary header-modal py-3 px-2 lg:py-3.5 font-bold text-base leading-5 text-white text-center relative">
		<span [ngClass]=" customTitleSize ? customTitleSize : 'text-lg lg:text-xl font-bold'">{{ title }}</span> <br>
		<button
			class="btn-close-sign absolute -right-3 -top-3 flex justify-center items-center text-white rounded-full w-6 h-6 lg:h-7 lg:w-7 "
			(click)="closeModal(false)"
			*ngIf="!notClose"
		>
			<ion-ripple-effect></ion-ripple-effect>
			<ion-icon name="close" class="w-5 h-5 lg:w-6 lg:h-6"></ion-icon>
		</button>
	</div>
	<div class=" flex flex-col px-4 py-4 text-gray-900 text-left text-base font-normal leading-5 gap-6">

	<ng-container *ngIf="courseParagraph">
		<p class="text-center">Como último paso, firma aquí para completar el curso: <span class="font-bold">{{courseText}} </span>. </p>
		<p class="text-center">Recuerda que tu firma estará en el <span class="font-bold">registro de cumplimiento</span> de este curso, no podrá ser modificada y tu firma no se guardará en nuestra base de datos.</p>
	</ng-container>

	<ng-container *ngIf="customParagraph">
		<p class="text-center">{{customParagraph}} </p>
	</ng-container>

	<div class="canva-container w-full border-1 border-black rounded-default relative ">
      <canvas id="main-canvas" class="z-1 bg-transparent relative"
      (mousedown)="startDraw($event)"
      (mouseup)="endDraw($event)"
      (mousemove)="draw($event, 'mousemove')"

	  (touchstart)="startDraw($event)"
	  (touchend)="endDraw($event)"
	  (touchmove)="draw($event, 'touchmove')"
      ></canvas>

	  <div *ngIf="canvaIsTouched" class="absolute top-0 right-0 p-2 cursor-pointer z-2 rounded-full hover:bg-gray-100">
		<mat-icon class="mdi mdi-eraser text-primary text-2xl" (click)="clearCanva()"></mat-icon>
	  </div>

	  <div  *ngIf="!canvaIsTouched" class="z-0 absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center gap-5 flex-col">
		<p class="text-base font-normal text-gray-1000 opacity-50 text-center">Usa tu dedo o mouse en <br> esta área</p>
		<ion-icon src="assets/icon/signature-icon.svg"  class="text-gray-1000 opacity-50 text-6xl"></ion-icon>
	  </div>

    </div>

		<div *ngIf="buttonText" class="flex items-center justify-center ">
			<ion-button class="" type="button" color="primary" (click)="closeModal(true)" [disabled]="!canvaIsTouched">
				<span> {{buttonText}} </span>
			</ion-button>
		</div>
	</div>
</div>
