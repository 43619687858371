import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-multiple-option-modal',
  templateUrl: './multiple-option-modal.component.html',
  styleUrls: ['./multiple-option-modal.component.scss'],
})
export class MultipleOptionModalComponent implements OnInit {

  // @Input()title: string | null = "";
  // @Input()subtitle: string | null = "";
  @Input()firstButtonText: string | null = "";
  @Input()secondButtonText: string | null = "";
  @Input()customStyle: string | null = null;
  @Input()closeModalBtn: boolean = false;

  constructor(private modalCtrl: ModalController, private navParams: NavParams,) { }

  ngOnInit() { }
  closeModal(selectedOption) {
    this.modalCtrl.dismiss({
      option: selectedOption
    });
  }

}
