<div class="modal-mask" id="modal-nps" [ngClass]="{ 'modal-mobile': mobile && !initNPS, 'modal-init': mobile && initNPS, 'mt-6': platform.is('ios') && mobile }" *ngIf="nps">
	<div class="modal-wrapper">
		<div class="modal-container" [ngClass]="{ 'modal-container-mobile': mobile && !initNPS , 'mt-14': mobile && initNPS && platform.is('ios') }" *ngIf="!sendNPS">
			<div class="text-base w-full md:text-xl" *ngIf="nps">
				<div class="relative">
					<button id="btn-encuesta" *ngIf="nps.encuesta" class="button-secondary bg-secondary text-white text-base font-medium md:text-lg md:font-semibold lg:text-xl lg:font-bold" [ngClass]="{'button-secondary-init': mobile && !initNPS}" (click)="initializeNPS()">
						{{nps.encuesta.texto_boton ? nps.encuesta.texto_boton : 'Ayúdanos a mejorar'}}
					</button>					
					<ion-button *ngIf="!mobile || mobile && initNPS" class="btn-close absolute -right-5 -top-5 flex justify-center items-center bg-primary text-white w-8 h-8 rounded-full" (click)="closeModal()">
						<ion-ripple-effect></ion-ripple-effect>
						<ion-icon name="close"></ion-icon>
					</ion-button>
				</div>
	
				<ion-card *ngIf="initNPS && nps.encuesta.secciones[section1]" class="card-one">
					<ion-card-header>
						<ion-card-title class="text-sm md:text-base">{{nps.encuesta.secciones[section1].pregunta}}</ion-card-title>
					</ion-card-header>
					<ion-card-content>
						<div class="group-star grid grid-cols-10 mt-2">
							<img *ngFor="let star of stars" [src]="star.id > selectStar ? 'assets/nps/star.svg' : 'assets/nps/star-selected.svg'" (click)="SelectStars(star.id)" [ngClass]="{'star-selected': star.id <= selectStar}" class="img-star self-center justify-self-center px-05 md:px-1">	
						</div>
					</ion-card-content>
				</ion-card>
	
				<ion-card *ngIf="selectStar > 0 && nps.encuesta.secciones[section2]">
					<ion-card-header>
						<ion-card-title class="text-sm md:text-base">{{ nps.encuesta.secciones[section2][selectType].pregunta }}</ion-card-title>
					</ion-card-header>
	
					<ion-card-content>
						<div class="group-card grid grid-cols-4 mt-2">
							<div *ngFor="let option of (nps.encuesta.secciones[section2][selectType].caracteristicas)" class="card-option" [id]="option.id" (click)="SelectOptions(option)">
								<img [src]="option.icono" alt="" class="mx-auto mb-2"/>
								<span class="text-card">{{option.nombre}}</span>
							</div>
						</div>
						
					</ion-card-content>
				</ion-card>
	
				<ion-card *ngIf="(selectOption.length > 0 || ( mobile && selectStar > 0 )) && nps.encuesta.secciones[section3]">
					<ion-card-header>
						<ion-card-title class="text-sm md:text-base">{{nps.encuesta.secciones[section3].pregunta}}</ion-card-title>
					</ion-card-header>
	
					<ion-card-content class="card-textarea">
						<ion-textarea rows="4" placeholder="{{nps.encuesta.secciones[section3].hint}}" [(ngModel)] = "response" class="mt-3"></ion-textarea>
						<p class="text-xs 2xl:text-sm">{{response.length}}/240</p>
					</ion-card-content>
					<ion-button color="primary" class="button-send disabled:opacity-40 disabled:cursor-default" (click)="sendButton = true; Send()" [disabled] = "response.length > 240 || selectOption.length == 0">Enviar</ion-button>
				</ion-card>
			</div>
		</div>

		<div class="modal-container nps-modal-send" *ngIf="sendNPS">
			<div class="w-full text-base md:text-xl text-center">
				<h1>¡Gracias por tu opinión!</h1>
			</div>
		</div>
	</div>
</div>