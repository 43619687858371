<div class="reload-modal-container mx-auto text-center bg-white my-auto"
	[ngClass]=" customStyle ? customStyle : 'w-72 md:w-84'">
	<div class="bg-primary header-modal py-4 px-2 lg:py-5 font-bold text-lg lg:text-xl leading-5 text-white text-center relative">
		<span>😎 Nueva versión disponible 😎</span>

		<ion-button *ngIf="closeModalBtn"
			class="btn-close absolute -right-5 -top-5 lg:-right-3 lg:-top-3 flex justify-center items-center text-white rounded-full"
			(click)="closeModal(false)">
			<ion-ripple-effect></ion-ripple-effect>
			<ion-icon name="close" class="w-5 h-5"></ion-icon>
		</ion-button>
	</div>
	<div class=" flex flex-col px-4 py-4 text-gray-900 text-left text-base font-normal leading-5">
		<!-- <ng-container [ngTemplateOutlet]="projectedContent"></ng-container> -->
		<div class="p-4 mb-4 w-full flex flex-col items-center justify-items-center">
			<!-- <app-image-svg name="reload-modal-image" customClass="text-secondary w-52 mb-7 " ></app-image-svg> -->
			<p class="font-normal text-lg text-center">Disfruta la nueva versión de nuestra plataforma disponible para ti.</p>
		</div>

		<div *ngIf="firstButtonText || secondButtonText"
			class=" flex flex-col lg:flex-row items-center justify-center gap-3 ">
			<ion-button *ngIf="firstButtonText" class="filled-btn custom-rounded text-base font-semibold" type="button"
				(click)="closeModal('first')">
				<span> {{firstButtonText}} </span>
			</ion-button>
			<ion-button *ngIf="secondButtonText" class="clear-btn custom-rounded text-base font-semibold" type="button"
				fill="clear" (click)="closeModal('second')">
				<span class="underline"> {{secondButtonText}} </span>
			</ion-button>
		</div>
	</div>
</div>