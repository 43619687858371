import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Platform } from "@ionic/angular";
import { ConexionServiceProvider, ConnectionStatus } from "../conexion-service/conexion-service";
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';

@Injectable({
	providedIn: "root",
})
export class OfflineService {
	disconnectSubscription: any;
	connectSubscription: any;
	networkAlert: HTMLIonAlertElement;
	networkStatus = true;
	requestSubscriber: any;
	mutex = true;

	private database: SQLiteObject;

	constructor(
		private http: HttpClient,
		private platform: Platform,
		private networkService: ConexionServiceProvider,
		private sqlite: SQLite,
	) {
		if (this.platform.is("cordova")) {
			this.createPendingRequestTable();
		}
	}

	async checkNewtworkStatus() {
		this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
			if (status == ConnectionStatus.Online) {
				this.networkStatus = true;
				this.sendPendingsHttpRequests();
			} else {
				this.networkStatus = false;
			}
		});
	}

	async createSQLdb() {
		try {
			const userData = JSON.parse(localStorage.getItem("user_data"));
			const db = await this.sqlite.create({
				name: `${userData.dni}.db`,
				location: "default",
			});
			if (db) {
				this.database = db;
			}
		} catch (error) {
			console.error("**********Error opening database offline service*********",error);
		}
	}

	async sendPendingsHttpRequests() {
    setTimeout(async () => {
      console.log("***send pending request****"	);
      // if (!this.database) {
      // 	await this.createSQLdb();
      // }
      await this.createSQLdb();
      this.database
        .executeSql("SELECT * FROM http_requests", [])
        .then((data) => {
          if (data.rows.length) {
            for (let i = 0; i < data.rows.length; i++) {
              const request = data.rows.item(i);
              this.sendRequests(request);
            }
          }
        })
        .catch((error) =>
          console.error(
            "****Error al obtener las solicitudes pendientes:******",
            error
          )
        );

    }, 4000);
	}

	sendRequests(request) {
		if (request.method === "GET") {
			this.http
				.request(request.method, request.url)
				.toPromise()
				.then((response) => {
					this.database
						.executeSql("DELETE FROM http_requests WHERE id = ?", [request.id])
						.then(() =>
							console.log(
								"****Solicitud pendiente enviada y eliminada correctamente*****",
								request.id
							)
						)
						.catch((error) =>
							console.error(
								"*****Error al eliminar las solicitudes pendientes****:",
								request.id,
								error
							)
						);
				})
				.catch((error) => {
					console.log(
						"error get http request: ",
						error
					);
				});
		} else if (request.method === "POST" && request.is_sst) {
			const token: string = localStorage.getItem("token");
			const parseData = JSON.parse(request.data);
			//Create form data
			const formData = new FormData();
			formData.append("signature", parseData.canvaImgData);
			formData.append("course_id", parseData.courseID);
			formData.append("is_offline", parseData.is_offline);
			formData.append("created_at", parseData.created_at);

			//POST request using fetch() because http request doesn´t work
			let promesa = fetch(request.url, {
				// Adding method type
				method: "POST",
				// Adding body or contents to send
				body: formData,
				// Adding headers to the request
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: `application/json, */*`,
				},
			})
				.then(() =>
					console.log(
						"**Solicitud pendiente enviada y eliminada correctamenteSst***",
						request.id
					)
				)
				.catch((error) =>
					console.error(
						"**Error al eliminar las solicitudes pendientesSst: ",
						request.id,
						error
					)
				);

			// .then( res => Promise.resolve(res.json()) )
			// .catch(error => Promise.reject(error));

			// return promesa;
		} else {
			this.http
				.request(request.method, request.url, { body: request.data })
				.toPromise()
				.then((response) => {
					this.database
						.executeSql("DELETE FROM http_requests WHERE id = ?", [request.id])
						.then(() =>
							console.log(
								"*******Solicitud pendiente enviada y eliminada correctamente*******",
								request.id
							)
						)
						.catch((error) =>
							console.error(
								"*******Error al eliminar las solicitudes pendientes:*******",
								request.id,
								error
							)
						);
				})
				.catch((error) => {
					console.log(
						"*******error http requestttt::******* ",
						error
					);
				});
		}
	}

	async createPendingRequestTable() {
		console.log("table de pending request");
		// if(!this.database) {
		// await this.createSQLdb();
		try {
			await this.createSQLdb();
			const res = await this.database.executeSql(
				`CREATE TABLE IF NOT EXISTS http_requests  (
          id  text primary key,
          url text,
          method text,
          data text,
          is_sst integer
        )`,
				[]
			);
			console.log("tabla request created: ", res);
		} catch (error) {
			console.log("ERROR createPendingRequestTable ", error);
		}
		// }
		// else {
		//   try {
		//     const res = await this.database.executeSql(`CREATE TABLE IF NOT EXISTS http_requests  (
		//       id text primary key,
		//       url text,
		//       method text,
		//       data text,
		//       is_sst integer
		//     )`,[]);
		//     console.log("tabla http creada::",res);

		//   } catch (error) {
		//     console.log("ERROR createPendingRequestTable ",error)
		//   }
		// }
	}
}
