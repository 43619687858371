<div class="platform-modal mx-auto text-center bg-white my-auto w-72 md:w-80 lg:w-106">
	<div class="bg-primary header-modal py-3 lg:py-3.5 xxl:py-4 font-semibold text-base lg:text-lg xxl:text-xl text-white text-center relative">
		¡Nueva versión disponible!
		<ion-button  *ngIf="!isForcedToUpdate" class="btn-close absolute -right-5 -top-5 lg:-right-3 lg:-top-3 flex justify-center items-center text-white rounded-full" (click)="cancelar()">
			<ion-ripple-effect></ion-ripple-effect>
			<ion-icon name="close" class="w-5 h-5"></ion-icon>
		</ion-button>
	</div>
	<div class="flex flex-col px-7 lg:px-10  py-4  text-left text-base font-normal leading-5">
		<div class="flex flex-col  justify-center mb-6 text-gray-1000">
			<p class="text-left mb-2.5 message">Se encuentra disponible una nueva versión de la aplicación.</p>
			<p class="text-left mb-2.5 message custom-spacing">Actualiza para disfrutar de las nuevas funcionalidades.</p>
			<p class="text-left text-sm font-semibold">*Se recomienda estar conectado a una red wi-fi.</p>
		</div>
		<div class="flex items-center justify-center">
			<button
				(click)="updateAndroid()"
				class="flex items-center rounded-lg py-2 px-4 bg-primary text-base text-white font-normal">
				Actualiza ahora
			</button>
		</div>
	</div>
</div>
