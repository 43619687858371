<div class="backdrop" (click)="onSelectedOption(false)">
  <div class="modal-actividad">
    <div class="content">
      <div class="title-modal flex items-center justify-start py-2 text-sm text-left">
        {{ title }}
      </div>
      <div class="pt-5 pb-1 px-3 flex flex-col gap-5">
        <div *ngFor="let option of optionsList" (click)="onSelectedOption(option.code)"
          class="ion-activatable ripple-parent p-3 rounded-default cursor-pointer "
          [ngClass]="customClass ? customClass : 'text-white font-bold text-center text-sm'"
          [ngStyle]="{'background': option.color}">

          <ion-ripple-effect></ion-ripple-effect>
          {{ option.name }}
        </div>
      </div>

    </div>
  </div>
</div>