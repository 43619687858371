
    <div class="guide-modal rounded-lg  bg-white w-11/12 md:w-[580px] m-auto">
      <div class="modal-reward-title bg-primary  text-white py-3 lg:py-4 px-4 rounded-t-lg">
        <span class="">¿Cómo conseguir monedas?</span>
        <button (click)="closeModal()" class="flex justify-center items-center bg-transparent" >
          <ion-icon name="close" class="text-2xl leading-5 max-h-[24px]"></ion-icon>
        </button>
      </div>
      <div class="py-4 lg:pt-2 xxxl:pt-3 px-6 ">
        <!-- slider -->
        <div class="relative">
          <div *ngIf="!slideIsBeginning"  class="activities-slider-left-arrow text-primary flex items-center" (click)="prevSlide()">
            <div class="w-5 h-5 lg:w-7 lg:h-7 hover:bg-gray-100 rounded-full flex justify-center items-center cursor-pointer">
              <ion-icon name="chevron-back-outline" class="noHover"></ion-icon>
            </div>
          </div>
          <swiper-container #slidesGuideCards pagination="true"
          [slidesPerView]="1" [spaceBetween]="20" [speed]="800"  class="" (swiperslidechange)="checkSlider()"
           >
            <swiper-slide *ngFor="let item of howToGetCoins" class="pb-10  " >
              <div class="flex flex-col items-center">
                <ng-container *ngIf="item.id === 'complete_course'">
                  <app-image-svg name="book-lover" customClass="w-[202px] xxl:w-[210px] xxxl:w-[260px] text-secondary"  ></app-image-svg>
                  <p   class="text-center text-lg lg:text-xl xxxl:text-[22px] leading-[19px] lg:leading-[22px] xxl:leading-[25px] max-w-[350px]">Completar curso</p>
                </ng-container>

                <ng-container *ngIf="item.id === 'poll'">
                  <app-image-svg name="checklist-img" customClass="w-[202px] xxl:w-[210px] xxxl:w-[260px] text-secondary"></app-image-svg>
                  <p class="text-center text-lg lg:text-xl xxxl:text-[22px] leading-[19px] lg:leading-[22px] xxl:leading-[25px] max-w-[350px]">Completar una encuesta de curso</p>
                </ng-container>

                <ng-container *ngIf="item.id === 'download_certificate'">
                  <app-image-svg name="approval-img" customClass="w-[202px] xxl:w-[210px] xxxl:w-[260px] text-secondary" ></app-image-svg>
                  <p class="text-center text-lg lg:text-xl xxxl:text-[22px] leading-[19px] lg:leading-[22px] xxl:leading-[25px] max-w-[350px]">Descargar los certificados de tus cursos</p>
                </ng-container>
                <!--  <ng-container *ngIf="item.id === ''">
                  <app-image-svg name="approval-check-img" customClass="w-[202px] xxl:w-[210px] xxxl:w-[260px] text-secondary" ></app-image-svg>
                  <p class="text-center text-lg lg:text-xl xxxl:text-[22px] leading-[19px] lg:leading-[22px] xxl:leading-[25px] max-w-[350px]">Aprobar la evaluación en menos tiempo</p>
                </ng-container> -->

                <ng-container *ngIf="item.id === 'max_quiz_grade'">
                  <app-image-svg name="career-img" customClass="w-[202px] xxl:w-[210px] xxxl:w-[260px] text-secondary" ></app-image-svg>
                  <p class="text-center text-lg lg:text-xl xxxl:text-[22px] leading-[19px] lg:leading-[22px] xxl:leading-[25px] max-w-[350px]">Al obtener la nota máxima en tus evaluaciones</p>
                </ng-container>

                <ng-container *ngIf="item.id === 'open_once_a_week'">
                  <app-image-svg name="authentication-img" customClass="w-[202px] xxl:w-[210px] xxxl:w-[260px] text-secondary" ></app-image-svg>
                  <p class="text-center text-lg lg:text-xl xxxl:text-[22px] leading-[19px] lg:leading-[22px] xxl:leading-[25px] max-w-[350px]">Ingresa al menos una vez por semana a la plataforma</p>
                </ng-container>

                <div class="border-1 border-gray-250 px-3 py-1 rounded-lgmax flex gap-1 mt-2">
                  <div class="w-[26px] h-[26px]">
                    <img *ngIf="coinsCustomImage" [src]="coinsCustomImage" class="w-full h-full object-contain">
                    <ng-lottie
                    *ngIf="!coinsCustomImage"
                    [options]="coinSpinOptions"
                    width="100%"
                    height="100%"
                    ></ng-lottie>
                  </div>
                  <span class="font-bold">{{item.coin}}</span>
                  <span>{{currencyName}}</span>
                </div>

              </div>
            </swiper-slide>
          </swiper-container>
          <div *ngIf="!slideIsEnd"  class="activities-slider-right-arrow text-primary flex items-center " (click)="nextSlide()">
            <div class="w-5 h-5 lg:w-7 lg:h-7 hover:bg-gray-100 rounded-full flex justify-center items-center cursor-pointer">
              <ion-icon name="chevron-forward-outline" class="noHover"></ion-icon>
            </div>
          </div>

        </div>

        <div class="flex flex-col gap-2 lg:pt-3 lg:px-5 xxl:px-6">
          <p class="text-lg lg:text-base xxl:text-xl leading-[18px] lg:leading-5 font-bold">¿Cómo usar las monedas?</p>
          <ul class="text-sm xxl:text-base list-disc list-outside ml-5">
            <li>Úsalo para canjear beneficios en nuestra sección de <span class="text-primary underline font-bold cursor-pointer" (click)="goTo()">beneficios.</span></li>
          </ul>
          <button (click)="closeModal()" class="bg-primary text-white rounded-lg py-2.5 px-6 w-fit mx-auto mt-2">Aceptar</button>
        </div>

      </div>

    </div>

