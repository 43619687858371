import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { AlertController, ModalController, NavParams, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/api.service';
import { LoaderProvider } from "src/app/service/loader/loader";


@Component({
  selector: 'app-pasantia-modal',
  templateUrl: './pasantia-modal.component.html',
  styleUrls: ['./pasantia-modal.component.scss'],
})
export class PasantiaModalComponent implements OnInit {
  @Input()subtitle: string | null = "";
  @Input()buttonText: string | null = "";
  @Input()customStyle: string | null = null;
  @Input()customTitleSize: string | null = null;
  @Input()notClose: boolean | null = false;
  @Input()process_id: number | null = null;
  @Input()activity_id: number | null = null;


	//pasantia
	leaders_list = [];
	cargando: boolean = false;
	cargando_lider: boolean = false;
	search_input = null;
  title:string = 'Solicita reunión'
  step:number = 1
	fecha_reunion1: any = null;
	fecha_reunion2: any = null;
	hora_reunion1: any = null;
	hora_reunion2: any = null;
  isIos: boolean = false;
  overlayInput1:boolean = false;
  overlayInput2:boolean = false;
  user_selected:any = null;
  disabled_btn:boolean = true

	@ViewChild('DateInput1', {static: false}) dateInput1: ElementRef;
	@ViewChild('DateInput2', {static: false}) dateInput2: ElementRef;

  constructor(  private modalCtrl: ModalController,
                private navParams: NavParams,
                private api: ApiService,
                private alertCtrl: AlertController,
                private platform: Platform,
                private loader: LoaderProvider,
            )
  {
		// this.getDataHora();
		if (this.platform.is("ios")) this.isIos = true;
  }

	displayDatePicker1() {
		// console.log(this.isIos);

		try {
			if(!this.isIos) {
				this.dateInput1.nativeElement.showPicker();
				console.log("show picker");
			}

		} catch (error) {
			console.log("catch show picker");
			this.overlayInput1 = true;
		}
	}

	displayDatePicker2() {
		// console.log(this.isIos);

		try {
			if(!this.isIos) {
				this.dateInput2.nativeElement.showPicker();
				console.log("show picker");
			}

		} catch (error) {
			console.log("catch show picker");
			this.overlayInput2 = true;
		}
	}
	setTime1(e) {
		this.hora_reunion1 = e;
	}
	setTime2(e) {
		this.hora_reunion2 = e;
	}
	// getDataHora() {
	// 	var d = new Date();
	// 	this.fecha_actual = moment(d).format("YYYY-MM-DD");
	// 	this.hora_actual = moment(d).format("HH:mm");
	// }
	async ngOnInit() {
		await this.obtenerLideresPasantia();
	}

	async ionViewWillEnter() {
    // await this.obtenerLideresPasantia();
	}

	async obtenerLideresPasantia(filter = '') {
		this.cargando = true;
		try {
			let res: any = await this.api.getActivityPasantia(this.process_id, this.activity_id, filter);
			this.leaders_list = res.data.leaders;

			this.cargando = false;

		} catch (error) {
			console.log(error);

			this.cargando = false;
			if(error.status !== 401){
				this.showAlert(null, "Excediste el tiempo límite de carga, por favor, vuelve a intentarlo más tarde");
			}
			return
		}
	}

	async guardarLiderPasantia(user, data) {
		this.cargando_lider = true;
    this.loader.show();
		try {
			let res: any = await this.api.saveActivityPasantia(this.process_id, this.activity_id, user, data);

			this.cargando_lider = false;
			this.loader.hide();

      this.user_selected = null;
      this.step = 1
      this.title = 'Solicita reunión'

      this.fecha_reunion1 = null;
      this.fecha_reunion2 = null;
      this.hora_reunion1 = null;
      this.hora_reunion2 = null;

      await this.obtenerLideresPasantia();

		} catch (error) {
			console.log(error);

			this.cargando_lider = false;
			this.loader.hide();
			if(error.status !== 401){
				this.showAlert(null, "Excediste el tiempo límite de carga, por favor, vuelve a intentarlo más tarde");
			}
			return
		}
	}

	async filterLeader() {
    await this.obtenerLideresPasantia(this.search_input);
	}
  async sendDate() {
    let data = {
      'fecha1' : this.fecha_reunion1,
      'hora1' : this.hora_reunion1,
      'fecha2' : this.fecha_reunion2,
      'hora2' : this.hora_reunion2,
    }
    await this.guardarLiderPasantia(this.user_selected.id, data)
  }

  verActividad(user) {
    if(user.status == 'pending')
    {
      this.user_selected = user;
      this.step = 2
      this.title = 'Selección de fechas'
    }
  }
  async showAlert(title?: string, message?: string, redirect:boolean = true) {
		let alert = await this.alertCtrl.create({
			header: title ? title : "",
			message: message,
			mode: "ios",
			buttons:  [
						{
							text: "Aceptar",
							handler: () => {
								this.alertCtrl.dismiss();
                this.closeModal(false)
							},
						},
				  ],
		});
		await alert.present();
	}

  closeModal(okStatus) {
    //okStatus: true/false, define si se aceptó el mensaje del modal o se cerró el modal sin aceptar
    this.modalCtrl.dismiss({
      aceptar: okStatus
    });
  }

}
