<div class="flex flex-col gap-3">
  <ng-content select="[questionLabel]"></ng-content>

  <div class="flex flex-col gap-1 md:gap-2 ">
    <div class=" flex justify-between">
      <p class="text-xs lg:text-sm ">{{textMin}}</p>
      <p class="text-xs lg:text-sm  ">{{textMax}}</p>
    </div>
    <!-- <ion-radio-group class="mobile-radio-group flex lg:hidden flex-col gap-3 " [(ngModel)]="question.answer">
      <ion-radio
        mode="md"
        *ngFor="let option of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        labelPlacement="end"
        justify="start"
        [value]="option"
        >{{ option }}
      </ion-radio>
    </ion-radio-group> -->
    <ion-radio-group class="desktop-radio-group flex gap-1 lg:gap-3 justify-between" [(ngModel)]="question.answer">
      <ion-radio
        mode="md"
        *ngFor="let option of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        labelPlacement="end"
        justify="start"
        [value]="option"
        [aria-label]="option"
        [ngClass]="{
          'red': option <= 6,
          'yellow': option > 6 && option < 9,
          'green': option > 8
        }"
        >{{ option }}
      </ion-radio>
    </ion-radio-group>

  </div>
</div>
