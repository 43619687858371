<div class="relative w-full ">
  <!-- <div *ngIf="blurStart && categorias.length && categorias.length > 1" class="nav-gradient"></div> -->
  <swiper-container #checklistSlides  [observer]="true" [observeParents]="true" [loop]="false"
  [slidesPerView]="'auto'" [spaceBetween]="0" [speed]="800" [slidesOffsetBefore]="3" [slidesOffsetAfter]="10" (swiperslidermove)="checkSlider()" class="normalized"
  >
    <swiper-slide
    *ngFor="let option of optionsList; index as i"
    class="px-5 py-3 rounded-lg border-1 border-solid w-auto-slider cursor-pointer text-center mr-3 lg:mr-5"
      [ngClass]="selectedOptionIndex === i ? 'border-primary bg-primary text-white ' : 'border-secondary  bg-white  text-secondary '"
      (click)="onClickItem(option.value, i)"
    >
      <span
        [ngClass]="selectedOptionIndex === i ? 'text-white' : 'text-secondary'"
        class="text-base  leading-4 lg:text-lg font-medium lg:leading-5 whitespace-nowrap ">
        {{option.label}}
      </span>
    </swiper-slide>

  </swiper-container>
  <!-- <div *ngIf="blurEnd" class="nav-gradient"></div> -->
</div>
