import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pregunta-opc-multiple',
  templateUrl: './pregunta-opc-multiple.component.html',
  styleUrls: ['./pregunta-opc-multiple.component.scss'],
})
export class PreguntaOpcMultipleComponent  implements OnInit {
  @Input() question: any;
  @Input() questionOptions: any;
  @Input() optionsType: any;
  multiple: any[] = [];
  constructor() { }

  ngOnInit() {}

  updateQuestionResponses(selectedOption) {
    // console.log("selectedOption: ", selectedOption);
    this.multiple = [];
    if (this.question.answer) {
      this.multiple = this.question.answer;

      let savedPreviusAnswer = this.question.answer.find((e) => e == selectedOption);
      // console.log(savedPreviusAnswer);
      if (savedPreviusAnswer == selectedOption) {
        // console.log("existe");
        let ind = this.multiple.indexOf(savedPreviusAnswer);
        this.multiple.splice(ind, 1);
      } else {
        // console.log("No existe");
        this.multiple.push(selectedOption);
      }
    } else {
      this.multiple.push(selectedOption);
    }

    this.question.answer = this.multiple;
  }
}
