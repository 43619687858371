import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pregunta-opc-valoracion',
  templateUrl: './pregunta-opc-valoracion.component.html',
  styleUrls: ['./pregunta-opc-valoracion.component.scss'],
})
export class PreguntaOpcValoracionComponent  implements OnInit {
  @Input() question: any;
  @Input() valorationType: string = "";
  valorationSelected = "";

  constructor() { }

  ngOnInit() {}

  handleChange(ev) {
    // console.log("change event: ", ev.target.value);
    this.valorationSelected = ev.target.value;
  }

}
