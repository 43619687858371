import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { BasicModalComponent } from 'src/app/components/basic-modal/basic-modal.component';
// import { ApiService } from 'src/app/api.service';
import { RewardReceivedModalComponent } from 'src/app/components/reward-received-modal/reward-received-modal.component';
import { RewardsGuideComponent } from 'src/app/components/rewards-guide/rewards-guide.component';

interface RankingData {
  nombre: string,
  position: number,
  rank: string,
  usuario_id: number,
  last_ev: string
}
export interface RewardConfig {
  rewardsIsActive: boolean | null,
  currencyName: string,
  coinCustomImage: string,
  coinsCustomImage: string,
  userRankData: RankingData,
  howToGetCoins: any,
  totalCoins: number,
}

@Injectable({
  providedIn: 'root'
})
export class RewardsService {
  // rewardsIsActive: boolean | null = null;
  // currencyName = "";
  // public coinCustomImage;
  // public coinsCustomImage;
  // public userRankData;
  // public howToGetCoins = [];
  // private totalCoins = new BehaviorSubject<number>(0);
  // totalCoins$ = this.totalCoins.asObservable();


  private rewardConfigData = new BehaviorSubject<RewardConfig>(
    {
      rewardsIsActive: null,
      currencyName: null,
      coinCustomImage: null,
      coinsCustomImage: null,
      userRankData: null,
      howToGetCoins: null,
      totalCoins: null
    }
  );
  rewardConfigData$ = this.rewardConfigData.asObservable();

  constructor(
    private modalController: ModalController,
    public router: Router,
		private api: ApiService,
  ) { }

  // public set setRewardsStaticConfig(data) {
  //   this.rewardsIsActive = data.activeStatus;
  //   this.currencyName = data.currencyName;
  //   this.coinCustomImage = data.coinCustomImage;
  //   this.coinsCustomImage = data.coinsCustomImage;
  //   this.userRankData = data.rankData;
  //   this.howToGetCoins = data.howData;
  // }

  public setRewardConfig(rewardData:RewardConfig) {
    this.rewardConfigData.next(rewardData);
  }

  getTotalCoins(): number {
    const rewardData = this.rewardConfigData.getValue();
    return rewardData.totalCoins;
  }

  public updateTotalCoins(coins) {
    this.setRewardConfig({ ...this.getRewardData(), totalCoins: coins });
  }

  public getRewardActiveStatus() {
    const rewardData = this.rewardConfigData.getValue()
    return rewardData?.rewardsIsActive;
  }

  public getRewardData() {
    return this.rewardConfigData.getValue();
  }

  async rewardReceivedConfirmation(coins, coinName, coinImage, reasons, fromLogin = false) {
    const modalAvailable = await this.modalController.create({
      component: RewardReceivedModalComponent,
			backdropDismiss: false,
			cssClass: "basic-modal",
			componentProps: {
        coinsReceived: coins,
        coinsName: coinName,
        coinImage: coinImage,
        reasons: reasons
			},
		});
		await modalAvailable.present();
    this.confetiStart();
    modalAvailable.onWillDismiss().then(() => {
      if(!fromLogin) {
        const updateTotalCoins =  this.getTotalCoins() + coins;
        this.updateTotalCoins(updateTotalCoins);
      }
    })
  }

	confetiStart() {
		setTimeout(async () => {
			const confetti = document.getElementById('confetti');
			if(confetti){
				confetti.innerHTML = '';
				function random(max){
					return Math.random() * (max - 0) + 0;
				}
				const width = document.documentElement.clientWidth;
				const height = document.documentElement.clientHeight;

				var c = document.createDocumentFragment();
				for (var i=0; i<200; i++) {
				var styles = 'transform: translate3d(' + (random(width) - width/2) + 'px, ' + (random(height) - height/2) + 'px, 0) rotate(' + random(360) + 'deg);\
								background: hsla('+random(360)+',100%,50%,1);\
								animation: bang 1500ms ease-out forwards;\
								opacity: 0';

				var e = document.createElement("i");
				e.style.cssText = styles.toString();
				c.appendChild(e);
				}
				confetti.appendChild(c);

			}
		}, 500);

	}

  async openCoinCounterModal(coinCounterModalContent) {
    if(!this.getRewardActiveStatus()) return;
    const modalAvailable = await this.modalController.create({
			component: BasicModalComponent,
			backdropDismiss: false,
			cssClass: "basic-modal",
			componentProps: {
				customStyle: "w-60 lg:w-96",
				projectedContent: coinCounterModalContent,
        notClose: true
			},
		});
		await modalAvailable.present();
  }

  async displayRewardsGuide() {
    this.closeModal();
    const modalAvailable = await this.modalController.create({
			component: RewardsGuideComponent,
			backdropDismiss: false,
			cssClass: "basic-modal",
			componentProps: {
				// customStyle: "",
				// projectedContent: this.coinCounterModalContent,
        // notClose: true
			},
		});
		await modalAvailable.present();
    const {data} = await modalAvailable.onWillDismiss();
		if (data === "goToBenefits") {
      this.goToBenefits();
		}
  }

  goToBenefits() {
    this.goToPage("beneficios");
    this.closeModal();
  }

  closeModal() {
		this.modalController.dismiss();
	}

  goToPage(route) {
    this.router.navigate([`${route}`]);
  }
  goToCoinsTracking() {
    this.goToPage('historial-monedas')
    this.closeModal();
  }

  refreshRewardConfiguration() {
    this.api.getRewardsConfig().subscribe(
      {
        next: ({data}) => {
          const rewardConfig:RewardConfig = {
            rewardsIsActive: data.has_rewards,
            currencyName: data.rewards?.coin_name,
            coinCustomImage: data.rewards?.coin_image,
            coinsCustomImage: data.rewards?.coins_image,
            userRankData: data.user_rank,
            howToGetCoins: data.rewards?.how_to_get_coins,
            totalCoins: data.rewards?.coins,
          };
          this.setRewardConfig(rewardConfig);
        },
        error: (e) => {
          console.log(e);
        }
      }
    );
  }

}
