<div 
	class="basic-modal-container mx-auto text-center bg-white my-auto"
	[ngClass]=" customStyle ? customStyle : 'w-72 md:w-84'"
>
	<div
		class="bg-primary header-modal py-3 px-2 lg:py-3.5 font-bold text-base leading-5 text-white text-center relative" *ngIf="title || subtitle"
	>
		<span class="text-xl">{{ title }}</span> <br>
		<span *ngIf="subtitle">{{ subtitle }}</span>

		<ion-button
			class="btn-close absolute -right-5 -top-5 lg:-right-3 lg:-top-3 flex justify-center items-center text-white rounded-full"
			(click)="closeModal(false)"
		>
			<ion-ripple-effect></ion-ripple-effect>
			<ion-icon name="close" class="w-5 h-5"></ion-icon>
		</ion-button>
	</div>
	<div class="relative"  *ngIf="!title && !subtitle">
		<ion-button
		class="btn-close absolute -right-5 -top-5 lg:-right-3 lg:-top-3 flex justify-center items-center text-white rounded-full"
		(click)="closeModal(false)"
	>
		<ion-ripple-effect></ion-ripple-effect>
		<ion-icon name="close" class="w-5 h-5"></ion-icon>
	</ion-button>
	</div>
	<div class="flex flex-col items-center px-5 py-5 gap-6 text-gray-900 text-left text-base font-normal leading-5">
		<span class=" text-center" *ngIf="description">{{ description }}</span>
		<div class="relative">
			<canvas #canvasss id="canv"></canvas>
			<div class="absolute top-0 right-0 p-2 cursor-pointer">
				<mat-icon class="mdi mdi-eraser text-primary text-2xl" (click)="limpiarCanva()"></mat-icon>

			</div>
		</div>
		
		
		<div *ngIf="buttonText" class="flex items-center justify-center">
			<ion-button class="" type="button" color="primary" (click)="closeModal(true)">
				<span> {{buttonText}} </span>
			</ion-button>
		</div>
	</div>
</div>
