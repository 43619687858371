import { Pipe, PipeTransform } from '@angular/core';
import { UsuarioServiceProvider } from 'src/app/service/index.providers';

@Pipe({
  name: 'completeUrl',
})
export class CompleteUrlPipe implements PipeTransform {
  urlBase = "";
  constructor(
  	private up: UsuarioServiceProvider,
  ) {}

  transform(mediaUrl: any, ...args: any[]): any {
    // console.log("*pipe verify media url  ", mediaUrl );
    if (mediaUrl.includes("https")) {
      return mediaUrl;
    } else {
      this.urlBase = this.up.getBucketBaseUrl;
      // console.log("complete url: ",this.urlBase + mediaUrl);
      return  this.urlBase + mediaUrl;
    }
  }

}
