import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent  implements OnInit {
  @ViewChild('checklistSlides') checklistSlides: ElementRef | undefined;
  blurStart = false;
	blurEnd = true;

  @Input() optionsList = [];
  @Input() selectedOptionIndex:number = 0;
  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();

  // navOptionsList= [
  //   {value: 'pendiente', label: 'Pendientes'},
  //   {value: 'realizado', label: 'Realizados'},
  //   {value: 'progreso', label: 'Progreso'}
  // ]
  constructor() { }

  ngOnInit() {}

  onClickItem(selectedOption, i) {
    // this.selectedOptionIndex = i;
    const props = {
      selectedOption,
      index: i
    }
    this.itemClick.emit(props);
  }

  async checkSlider() {
    let isBeginning = this.checklistSlides?.nativeElement.swiper.isBeginning;
		let isEnd = this.checklistSlides?.nativeElement.swiper.isEnd;
		isBeginning ? (this.blurStart = false) : (this.blurStart = true);
		isEnd ? (this.blurEnd = false) : (this.blurEnd = true);
		// console.log("slide moviendose",isBeginning,isEnd);
	}

}
