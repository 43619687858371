<!-- *ngFor="let item of novedades; let i = index" -->
<div class="announcement-card-container mx-auto xl::mx-0 relative rounded-lg bg-white shadow-lg w-full"
[ngClass]="{
  'sm-scroll-card': smScrollIsActive,
  'sm-slide-card': !smScrollIsActive,
  'blur': applyBlur,
  'scale-90': applyScale
}"
>
  <div *ngIf="cardChipLeftIsActive" class="absolute top-3 right-3 z-[3] w-[34px] h-[34px] flex justify-center items-center middle-level-chip rounded-lg">
    <mat-icon class="mdi mdi-pin text-xl leading-5 text-white rotate-[40deg]"></mat-icon>
  </div>

    <ng-container *ngIf="mediaType === 'image'">
      <!-- Imagen mobile-->
      <!-- <div class="relative md:hidden w-full rounded-t-lg h-60">
        <img [src]="cardMediaUrl" alt="Portada" class="w-full h-full z-2 rounded-t-lg"/>
      </div> -->
      <!-- Imagen tablet-desktop-->
      <div class="background-blur-img relative  w-full rounded-t-lg cursor-pointer h-60 lg:h-64 xxl:h-72"
          [ngStyle]="{'background-image': 'url(' + cardMediaUrl + ')'}"
          (click)="onClick()"
      >
        <img [src]="cardMediaUrl" alt="Portada" class="card-image  w-full h-full  object-contain z-5 rounded-t-lg" />
        <!-- zoom btn -->
        <button class="h-7 w-7 bg-gray-350 bg-opacity-80 hover:bg-opacity-95 absolute bottom-2 right-2 rounded-default ion-activatable ripple-parent cursor-pointer flex justify-center items-center">
          <ion-ripple-effect class="rounded-default"></ion-ripple-effect>
          <mat-icon class="mdi mdi-fullscreen text-2xl"></mat-icon>
        </button>
      </div>
    </ng-container>

    <!-- video -->
    <ng-container *ngIf="mediaType === 'video'">
      <div (click)="onClick()" class="background-blur-img cursor-pointer h-60 lg:h-64 xxl:h-72 rounded-t-lg relative"
      [ngStyle]="{'background-image': 'url(' + cardMediaUrl + ')'}"
      >
        <img [src]="cardMediaUrl" class="card-image w-full h-full  object-contain rounded-t-lg z-1" >
        <ion-icon name="caret-forward-circle"  class="center-element-translate play-video-icon text-[82px] text-primary  z-2 rounded-full "></ion-icon>
      </div>
    </ng-container>
    <!-- audio -->
    <ng-container *ngIf="mediaType === 'audio'">
      <div (click)="onClick()" class="background-blur-img h-60 lg:h-64 xxl:h-72 rounded-t-lg relative" [ngClass]="{'cursor-pointer': !audioPlayerIsActive}"
      [ngStyle]="{'background-image': 'url(' + cardMediaUrl + ')'}"
      >
        <img [src]="cardMediaUrl" class="card-image w-full h-full  object-contain rounded-t-lg z-1">
        <ng-content select="[audioPlayer]">
        </ng-content>
      </div>
    </ng-container>

    <!-- Contenido -->
    <div class="grid announcement-details w-full px-3 h-[calc(100%-15rem)] lg:h-[calc(100%-16rem)] xxl:h-[calc(100%-18rem)]" >
      <ng-content select="[cardDescription]"></ng-content>
    </div>


</div>
