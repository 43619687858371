<div (click)="onClickCard()" class="card-checklist-component rounded-default shadow-normal  flex flex-col">
  <div class="card-info-container grid w-full py-4 px-3 xxl:py-5">
    <p class="overflow-text-cover-2 text-base font-bold" [ngClass]="{'translate-y-2.5': !existBottomLeftText}" >{{cardTitle}} </p>
    <div [ngClass]="{'translate-y-2.5': !existBottomLeftText}">
      <ng-content select="[cardChips]"></ng-content>
    </div>
  </div>

  <div class="w-full px-3 py-1 h-[22px] grid grid-cols-12 items-center text-white text-xxs rounded-b-default" [ngClass]="existBottomLeftText ? 'bg-primary' : 'bg-white'">
    <div class="flex gap-4 col-span-7">
      <!-- <span>{{cardBottomLeftText}}</span> -->
      <ng-content select="[bottomLeftText]"></ng-content>
    </div>
    <div class="col-span-5 flex justify-end items-center">
      <span class=" whitespace-nowrap overflow-hidden text-ellipsis">{{cardBottomRightText}}</span>
    </div>
  </div>
</div>
