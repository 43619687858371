<ion-content>
  <ion-list>
    <ion-item *ngIf="tipo" button lines="full" (click)="cerrarReunion(1)">
      <ion-label>Finalizar reunión para todos</ion-label>
    </ion-item>
    <ion-item button lines="full" (click)="cerrarReunion(2)">
      <ion-label>Salir de la reunión</ion-label>
    </ion-item>
    <ion-item button lines="full" (click)="cerrarReunion(0)">
      <ion-label>Cancelar</ion-label>
    </ion-item>
  </ion-list>
</ion-content>