import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { RewardsService } from 'src/app/service/rewards/rewards.service';
import Swiper from 'swiper';

@Component({
  selector: 'app-rewards-guide',
  templateUrl: './rewards-guide.component.html',
  styleUrls: ['./rewards-guide.component.scss'],
})
export class RewardsGuideComponent  implements OnInit,OnDestroy {
  swiperGuide: Swiper;
  slideIsBeginning = true;
	slideIsEnd = false;
  coinSpinOptions: AnimationOptions = {
    path: '/assets/animations/coins-spin.json',
    loop: true,
    autoplay: true
  };
  @ViewChild('slidesGuideCards') slidesGuideCards: ElementRef | undefined;
  currencyName;
  rewardSub:Subscription;
  howToGetCoins = [];
  coinsCustomImage = "";

  constructor(private modalCtrl: ModalController,  private rewardsService: RewardsService,) { }

  ngOnInit() {
    // console.log(this.rewardsService.howToGetCoins);
    this.rewardSub = this.rewardsService.rewardConfigData$.subscribe(rewardData => {
        this.currencyName = rewardData.currencyName;
        this.howToGetCoins = rewardData.howToGetCoins;
        this.coinsCustomImage = rewardData.coinsCustomImage;
    });

  }

  async nextSlide() {
    // console.log("nextSlide click....");
    if(!this.swiperGuide) {
      this.swiperGuide = this.slidesGuideCards?.nativeElement.swiper;
    }
    // console.log("nextSlide: ",this.swiperRef?.nativeElement.swiper);
    this.swiperGuide?.slideNext();
	}

	async prevSlide() {
    // console.log("prevSlide click....");

    if(!this.swiperGuide) {
      this.swiperGuide = this.slidesGuideCards?.nativeElement.swiper;
    }
    this.swiperGuide?.slidePrev();
		// this.swiperRef?.nativeElement.swiper.slidePrev();
	}

  async checkSlider() {
    this.slideIsBeginning = this.slidesGuideCards?.nativeElement.swiper.isBeginning;
		this.slideIsEnd = this.slidesGuideCards?.nativeElement.swiper.isEnd;
  }

  closeModal() {
		this.modalCtrl.dismiss();
	}

  goTo() {
    this.modalCtrl.dismiss("goToBenefits");
  }

  ngOnDestroy() {
    if(this.rewardSub) this.rewardSub.unsubscribe();
	}

}
