import { Injectable } from '@angular/core';
import { Filesystem, Directory, DownloadFileResult } from '@capacitor/filesystem';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import { Mediastore } from '@cotecna/capacitor-mediastore';
import { Platform, ToastController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor(
    public platform: Platform,
		private file: File,
		private toastCtrl: ToastController,
		private nativeHTTP: HTTP,
		private androidPermissions: AndroidPermissions,
  ) {

  }

  async downloadMediaInMobileDevice(url_media, subPath, enableShareFile = false) {
    //subPath === extension del archivo => video.mp4  pdf.mp4
    if(Capacitor.isNativePlatform()) {
      if (this.platform.is("android") ) {
        this.presentDownloadingToast(`Descargando...`);
        let permission = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
        if (permission.hasPermission) {
            console.log('Permission granted...');
            this.downloadFileWithPermission(url_media, subPath, enableShareFile);
        }
        else {
            console.log('Asking for Permission...');
            permission = await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
            if (permission.hasPermission) {
              this.downloadFileWithPermission(url_media, subPath, enableShareFile);
            } else {
              const deviceInfo = await Device.getInfo();
              console.log("DEVICE INFO:: ",JSON.stringify(deviceInfo));
              if(parseInt(deviceInfo.osVersion) > 12) { this.downloadFileWithPermission(url_media, subPath, enableShareFile); }
            };
        };

      };

      if (this.platform.is("ios")) {
        this.presentToast("Descargando...", 2000);
        // for iOS use this.file.documentsDirectory
        const filePath = this.file.documentsDirectory + subPath;
        this.nativeHTTP.downloadFile(url_media, {}, {}, filePath).then(response => {
          this.presentToast(`Descargado` , 5000);
          console.log('Descargado', JSON.stringify(response));
        }).catch(err => {
          console.log('error block ... ', err.status);
          console.log('error block ... ', err.error);
          this.presentToast("Algo salió mal, " + err.status + err, 5000);
        });

      };
    }
	}

  async downloadMediaWithBrowser(url) {
    if(Capacitor.isNativePlatform()) {
      await Browser.open({ url: url });
    } else {
      window.open(url, "_system");
    }
  }

  async downloadFileWithPermission(url_media, mediaPath, enableShareFile ) {
    const deviceInfo = await Device.getInfo();

    Filesystem.downloadFile({
      path: mediaPath,
      url: url_media,
      directory: Directory.Documents,
    })
    .then((res: DownloadFileResult) => {
      this.closeDownloadingToast();
      setTimeout(async () => {
            const uriRes =  await Filesystem.getUri({
              directory: Directory.Documents,
              path: mediaPath,
            });

            if(parseInt(deviceInfo.osVersion) > 10) {
              let res2 = await Mediastore.saveToDownloads({
                filename: mediaPath,
                path: decodeURIComponent(uriRes.uri)
              });
              console.log("mediatore res::", JSON.stringify(res2));
              this.presentToast("Descarga completada", 3000);
            } else{
               //el usuario elige donde guardar
              if(enableShareFile) {
                await Share.share({ url: uriRes.uri });
              } else {
                this.presentToast("Descargado en: " + res.path.split("/").slice(-2).join('/'), 5000);
              }
            }
      }, 100);
    })
    .catch(error => {
      	this.closeDownloadingToast();
				setTimeout(() => {
					this.presentToast("Error: " + JSON.stringify(error), 5000);
				}, 300);
    });
	}

  async presentToast(msg, duration) {
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: duration,
		});
		toast.present();
	}

	async presentDownloadingToast(msg) {
		const toast = await this.toastCtrl.create({
			message: msg
		});
		toast.present();
	}

	closeDownloadingToast() {
		this.toastCtrl.dismiss();
	}

}
