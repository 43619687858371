import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Storage } from '@ionic/storage-angular';
import { NotificacionService } from '../notificaciones-service/notificacion.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(public platform: Platform, private Storage: Storage, private ns: NotificacionService,) {
    if (this.platform.is('capacitor')){
      this.initializeNotificationEvents();
    }
  }

  private initializeNotificationEvents() {
     // On success, we should be able to receive notifications
     PushNotifications.addListener('registration',
      (token: Token) => {
        // alert('Push registration success, token: ' + token.value);
        let data = {
          status: 'true',
          token: token.value,
        };
        this.Storage.set('token_fcm', data);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log("error al registrar token fcm",  JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received when APP IS OPEN: ' + JSON.stringify(notification));
        console.log('Push received when APP IS OPEN');
        this.ns.changeListNotification(true, null);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );

  }

  public requestPermissions() {
    console.log("request notificacion permissions! ");
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      }
    });
  }
}
