import { Component, Input, OnInit, Output, EventEmitter, ElementRef, SimpleChanges, Inject, ViewChild } from "@angular/core";
import { UsuarioServiceProvider } from "src/app/service/index.providers";
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, NavController, Platform } from "@ionic/angular";
import { ApiService } from 'src/app/api.service';
import { findIndex } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
@Component({
	selector: "app-basic-map",
	templateUrl: "./basic-map.component.html",
	styleUrls: ["./basic-map.component.scss"],
})
export class BasicMapComponent implements OnInit {
	@Input() finish: boolean = false;
	@Input() dataProcess: any = [];
	@Input() reloadMap: boolean = false;
	@Input() activity_selected: any = {id: null, stage_id: null};
	@Input() direfentStageSelected: null | number = null;
	@Input() updateStageOnly: null | boolean = null;
	@Input() brandLogo: string = "";

	@Output() onShowModal = new EventEmitter();
	@Output() onHoverActivityMap = new EventEmitter();
	@Output() to = new EventEmitter();
	@Output() changeReloadMap = new EventEmitter();
	startHeightHtml:number;
	ruta_imagen: string = "";
	tab_selected: number = 0;
	activity_map: any = {id: null, stage_id: null};
  stageSelected: null | number = null;
  isIphoneDevice: boolean = false;
	isIpadDevice: boolean = false;

	constructor(public up: UsuarioServiceProvider,
		private el: ElementRef,
    private router: Router,
    private alertCtrl: AlertController,
    private api: ApiService,
		private navCtrl: NavController,
    @Inject(DOCUMENT) private document: any,
    public platform: Platform,
  ){
    if (this.platform.is("iphone")) {
			this.isIphoneDevice = true;
		};
		if (this.platform.is("ipad")) {
			this.isIpadDevice = true;
		}
  }

	ngOnChanges(changes: SimpleChanges) {
		if(this.reloadMap) {
			setTimeout(() => {
				this.draw();
				this.reloadMap = false
				this.changeReloadMap.emit(false);
			}, 100);
		};

    if(this.direfentStageSelected !== null) { // &&  this.direfentStageSelected !== this.stageSelected
      if(!this.updateStageOnly) {
        setTimeout(() => {
          this.scrollToCard(this.direfentStageSelected);
        }, 500);
      };
      this.stageSelected = this.direfentStageSelected;
    };

		// if(this.activity_selected.stage_id !== this.activity_map.stage_id){
    //   console.log("kheeee", this.activity_selected.stage_id, this.activity_map.stage_id);

		// 	const index = this.dataProcess.stages.findIndex((e) => (e.id === this.activity_selected.stage_id));
    //   console.log("index kheeee", index);
		// 	const el = document.getElementById('title-'+index);
    //   console.log("index el kheeee", el);
		// 	if(el){
		// 		setTimeout(() => {
		// 			let content_c = document.getElementById("div4");
		// 			content_c.scroll({
		// 				top: el.offsetTop-700,
		// 				left: 0,
		// 				behavior: "smooth",
		// 			  });
		// 			  this.activity_map = this.activity_selected;
		// 		}, 100);
		// 	}
		// }
	}

  scrollToCard(positionDataIdx) {
		try {
      let allMapsContainer = document.getElementById("div4");
      const onlyOneMap = document.getElementById('stage-'+positionDataIdx);
      allMapsContainer.scroll({
        top: this.dataProcess.certificate?.enabled ? onlyOneMap.offsetTop - (allMapsContainer.offsetHeight - onlyOneMap.offsetHeight) + 160 : onlyOneMap.offsetTop - (allMapsContainer.offsetHeight - onlyOneMap.offsetHeight) + 20,  // + 20 para margin bottom y 160 por distancia a botón de certficado
        // top: onlyOneMap.offsetTop - (allMapsContainer.offsetHeight - onlyOneMap.offsetHeight) + 20,  // + 20 para margin bottom
        left: 0,
        behavior: "smooth",
      });

		} catch (error) {
			console.log(error);
		};
	}

	ngOnInit() {
		this.ruta_imagen = this.up.getBucketBaseUrl;
		setTimeout(() => {
			this.draw();
		}, 100);
    this.setMapPathColorToDocument();
	}

  setMapPathColorToDocument() {
    let rootelement = this.document.documentElement;
    rootelement.style.setProperty('--mapPathColor', this.dataProcess.color);
  }

	onMouseEnterTooltip(i, activity) {
		const tooltipElement = this.el.nativeElement.querySelector(`#tooltip-${i}`);
		const tooltipRect = tooltipElement.getBoundingClientRect();
		const bodyRect = document.body.getBoundingClientRect();

		// Verificar y ajustar la posición del tooltip
		if (tooltipRect.right > (bodyRect.width / 1.4) ) {
			tooltipElement.classList.add('tooltip-visible');
			return;
		}
		if (tooltipRect.left < 20 ) {
			tooltipElement.classList.add('custom-margin-tooltip');
		}
		this.onHoverActivityMap.emit(activity);
	}

	iconActivity(type){
		switch (type) {
			case 'checklist':
				return 'mdi-list-box';
			case 'temas':
				return 'mdi-bookmark-box';
			case 'encuesta':
				return 'mdi-medal';
			case 'evaluacion':
				return 'mdi-note-text';
			case 'tareas':
				return 'mdi-cloud-upload';
			case 'sesion_online':
				return 'mdi-video';
      case 'pasantia':
        return 'mdi-calendar-multiple-check';
      case 'firmas':
        return 'mdi-draw';
			default:
        return '';
				break;
		}
	}

	mostrarModal(activity){
		this.onShowModal.emit(activity);
	}

	toRoute(route){
		this.to.emit(route);
	}

	redirectCertificate(url){
		let extras: NavigationExtras = {
			queryParams: {
				url: url,
			},
		};
		this.router.navigate(["certificado"], extras);
	}

	async confirmarSalir() {
		let alert = await this.alertCtrl.create({
			header: "Cerrar Sesión",
			message: "¿Seguro que deseas salir de la aplicación?",
			mode: "ios",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
				},
				{
					text: "Seguro",
					handler: () => {
						this.cerrarSesion();
					},
				},
			],
		});
		await alert.present();
	}

	async cerrarSesion() {
		await this.api.logout_token()
		.then((res) => {
			this.up.clearStorage();
			this.navCtrl.navigateRoot("login");
			})
		.catch((e) => {
			console.log(e.status, e.url);
		});
	}

	draw() {
		let numbersActivities = 0;
		this.dataProcess.stages.forEach(stage => {
			if(stage.status !== 'locked') numbersActivities += stage.activities.length;
		});
		const canvas = document.getElementById("canvas") as HTMLCanvasElement;
		if (canvas.getContext) {
			const ctx = canvas.getContext("2d");
			let width = document.documentElement.clientWidth;
			width = width <= 1024 ? width : width <= 1680 ?  width * 0.34 : width <= 1540 ? width * 0.29 : 520
			let height = 40 + (this.dataProcess.certificate && this.dataProcess.certificate.enabled ? 320 : 0);
			let countStageLocked = 0;
			this.dataProcess.stages.forEach((stage, idx) => {
				if(stage.status !== 'locked'){
					const additional = Math.ceil((stage.activities.length-8)/6) > 0 ? Math.ceil((stage.activities.length-8)/6) : 0
					const additional1 = Math.ceil(additional/2);
					const additional2 = Math.floor(additional/2);
					height += 830 + (200  * additional1) + (340  * additional2);
          // console.log("height:: ",height);
          // console.log("additional, additional1, additional2:: ",additional, additional1, additional2);

				} else {
					countStageLocked += 1;
				}
			});

			if(this.dataProcess.stages.length == countStageLocked) height += 600;

			// if(numbersActivities > 8) height = height + (Math.ceil((numbersActivities-8)/3)*130) //200
			const midWidth = width / 2;
			let startHeight = height - 140;
			canvas.width = width;
			canvas.height = height;

			const elementHtml = document.getElementById("elementHtml");
			elementHtml.style.width = `${width}px`;
			elementHtml.style.height = `${height}px`;

			const elementHtml2 = document.getElementById("elementHtml2");
			elementHtml2.style.width = `${width}px`;
			let heightRoad = 0;
			this.dataProcess.stages.forEach((stage, idx) => {
				if(stage.status !== 'locked'){
					if(idx % 2 === 0) {
						this.drawRoad1(ctx,midWidth,startHeight,stage.activities.length, this.dataProcess);
						this.drawElementHtml(startHeight, 'activity'+(idx+1), 1, 'title-'+(idx),ctx, midWidth)
					}
					else {
						this.drawRoad2(ctx,midWidth,startHeight,stage.activities.length, this.dataProcess);
						this.drawElementHtml(startHeight, 'activity'+(idx+1), -1, 'title-'+(idx),ctx, midWidth)
					}
					// const additional = Math.ceil((stage.activities.length-8)/6) > 0 ? Math.ceil((stage.activities.length-8)/6) : 0
					const additional = Math.ceil((stage.activities.length-8)/6)  > 0 ? Math.ceil((stage.activities.length-8)/6) : 0
					const additional1 = Math.ceil(additional/2);
					const additional2 = Math.floor(additional/2);
					heightRoad = 830;
					heightRoad +=(200  * additional1) + (340  * additional2);
					startHeight = startHeight - heightRoad;
					heightRoad = 0;
				}
			});

			// this.drawRoad1(ctx,midWidth,startHeight,numberActivity);
			// this.drawElementHtml(startHeight, 'activity1', 1)

			// startHeight = startHeight - 780;
			// this.drawRoad2(ctx,midWidth,startHeight,numberActivity);
			// this.drawElementHtml(startHeight, 'activity2', -1)


			const activities = Array.from(document.getElementsByClassName('elementHtml') as HTMLCollectionOf<HTMLElement>)
      if(this.updateStageOnly !== false) {
        setTimeout(() => {
          let content_c = document.getElementById("div4");
          content_c.scroll({
            top: height,
            left: 0,
            behavior: "smooth",
            });
        }, 100);
      }



		}
	}

	drawRoad1(ctx,midWidth,startHeight,numberActivity, stage){
		const additional = Math.ceil((numberActivity-8)/3)  > 0 ? Math.ceil((numberActivity-8)/3) : 0
		const additional1 = Math.ceil(additional/2);
		const additional2 = Math.floor(additional/2);
		const startH = startHeight;

    let colorFondo = stage.color_map_odd ? stage.color_map_odd : '#8BFC89'
		let colorEmpresa = stage.color ? stage.color : '#2e36ce'

		this.drawElementRoad1(ctx,midWidth,startH,numberActivity, colorFondo);

		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(1,1);
		ctx.save();
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.strokeStyle=colorEmpresa;
		ctx.lineWidth=12;
		ctx.miterLimit=10;
		ctx.beginPath();
		ctx.moveTo(midWidth,startHeight);
		ctx.lineTo(midWidth,startHeight-125.49);
		ctx.bezierCurveTo(midWidth,startHeight-158.77,midWidth+26.97,startHeight-185.74,midWidth+60.25,startHeight-185.74);
		ctx.bezierCurveTo(midWidth+93.53,startHeight-185.74,midWidth+120.5,startHeight-212.71,midWidth+120.5,startHeight-245.99);
		ctx.lineTo(midWidth+120.5,startHeight-264.1);
		ctx.bezierCurveTo(midWidth+120.5,startHeight-297.38,midWidth+93.53,startHeight-324.35,midWidth+60.25,startHeight-324.35); // aqui va al otro
		ctx.bezierCurveTo(midWidth+13.192,startHeight-324.35,midWidth-13.192,startHeight-324.35,midWidth-60.25,startHeight-324.35);
		ctx.bezierCurveTo(midWidth-93.53,startHeight-324.35,midWidth-120.5,startHeight-351.32,midWidth-120.5,startHeight-384.6);
		ctx.lineTo(midWidth-120.5,startHeight-401.6);
		ctx.bezierCurveTo(midWidth-120.5,startHeight-434.88,midWidth-93.53,startHeight-461.85,midWidth-60.25,startHeight-461.85);
		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth-26.97,startHeight-461.85,midWidth,startHeight-488.82,midWidth,startHeight-522.09999);
			ctx.lineTo(midWidth,startHeight-824.95);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				startHeight= startHeight - 266.11;
				ctx.bezierCurveTo(midWidth+93.53,startHeight-185.74,midWidth+120.5,startHeight-212.71,midWidth+120.5,startHeight-245.99);
				ctx.lineTo(midWidth+120.5,startHeight-264.1);
				ctx.bezierCurveTo(midWidth+120.5,startHeight-297.38,midWidth+93.53,startHeight-324.35,midWidth+60.25,startHeight-324.35);
				if (index === additional1 && additional1 !== additional2) {
					//Fin de recorrido por por derecha
					ctx.bezierCurveTo(midWidth+26.97,startHeight-324.35,midWidth,startHeight-351.32,midWidth,startHeight-384.59999);
					ctx.lineTo(midWidth,startHeight-824.95);
				}

				if (index <= additional2) {
					ctx.bezierCurveTo(midWidth+13.192,startHeight-324.35,midWidth-13.192,startHeight-324.35,midWidth-60.25,startHeight-324.35);
					ctx.bezierCurveTo(midWidth-93.53,startHeight-324.35,midWidth-120.5,startHeight-351.32,midWidth-120.5,startHeight-384.6);
					ctx.lineTo(midWidth-120.5,startHeight-401.6);
					ctx.bezierCurveTo(midWidth-120.5,startHeight-434.88,midWidth-93.53,startHeight-461.85,midWidth-60.25,startHeight-461.85);
					if (index === additional2 && additional1 === additional2) {
						//Fin de recorrido por izquierda
						ctx.bezierCurveTo(midWidth-26.97,startHeight-461.85,midWidth,startHeight-488.82,midWidth,startHeight-522.09999);
						ctx.lineTo(midWidth,startHeight-824.95);
					}
				}
			}
		}
		startHeight = startH;
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.strokeStyle=colorEmpresa;
		ctx.miterLimit=10;
		ctx.beginPath();
		ctx.moveTo(midWidth+11.5,startHeight);
		ctx.lineTo(midWidth-11.5,startHeight);
		ctx.lineTo(midWidth-11.5,startHeight-125.49);
		ctx.bezierCurveTo(midWidth-11.5,startHeight-165.05,midWidth+20.69,startHeight-197.24,midWidth+60.25,startHeight-197.24);
		ctx.bezierCurveTo(midWidth+87.13,startHeight-197.24,midWidth+109,startHeight-219.11,midWidth+109,startHeight-245.99);
		ctx.lineTo(midWidth+109,startHeight-264.11);
		ctx.bezierCurveTo(midWidth+109,startHeight-290.99,midWidth+87.13,startHeight-312.86,midWidth+60.25,startHeight-312.86);
		ctx.lineTo(midWidth-60.25,startHeight-312.86);
		ctx.bezierCurveTo(midWidth-99.81,startHeight-312.86,midWidth-132,startHeight-345.05,midWidth-132,startHeight-384.61);
		ctx.lineTo(midWidth-132,startHeight-401.61);
		ctx.bezierCurveTo(midWidth-132,startHeight-441.17,midWidth-99.81,startHeight-473.36,midWidth-60.25,startHeight-473.36);
		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth-33.37,startHeight-473.36,midWidth-11.5,startHeight-495.23,midWidth-11.5,startHeight-522.11);
			ctx.lineTo(midWidth-11.5,startHeight-824.95);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				startHeight= startHeight - 266.11;
				ctx.bezierCurveTo(midWidth+87.13,startHeight-197.24,midWidth+109,startHeight-219.11,midWidth+109,startHeight-245.99);
				ctx.lineTo(midWidth+109,startHeight-264.11);
				ctx.bezierCurveTo(midWidth+109,startHeight-290.99,midWidth+87.13,startHeight-312.86,midWidth+60.25,startHeight-312.86);

				if (index === additional1 && additional1 !== additional2) {
					// Fin de recorrido derecha
					ctx.bezierCurveTo(midWidth+33.37,startHeight-312.86,midWidth-11.5,startHeight-334.73,midWidth-11.5,startHeight-381.61);
					ctx.lineTo(midWidth-11.5,startHeight-824.95);
				}

				if (index <= additional2) {
					ctx.lineTo(midWidth-60.25,startHeight-312.86);
					ctx.bezierCurveTo(midWidth-99.81,startHeight-312.86,midWidth-132,startHeight-345.05,midWidth-132,startHeight-384.61);
					ctx.lineTo(midWidth-132,startHeight-401.61);
					ctx.bezierCurveTo(midWidth-132,startHeight-441.17,midWidth-99.81,startHeight-473.36,midWidth-60.25,startHeight-473.36);
					if (index === additional2 && additional1 === additional2) {
						// Fin de recorrido izquierda
						ctx.bezierCurveTo(midWidth-33.37,startHeight-473.36,midWidth-11.5,startHeight-495.23,midWidth-11.5,startHeight-522.11);
						ctx.lineTo(midWidth-11.5,startHeight-824.95);
					}

				}
			}
		}
		ctx.lineTo(midWidth+11.5,startHeight-824.95);
		ctx.lineTo(midWidth+11.5,startHeight-522.11);
		if (additional1 !== additional2) {
			// Fin de recorrido derecha
			ctx.lineTo(midWidth+11.5,startHeight-382.11);
			ctx.bezierCurveTo(midWidth+11.5,startHeight-372.55,midWidth+20.69,startHeight-335.86,midWidth+60.25,startHeight-335.86);
		}

		if (additional1 === additional2) {
			// Fin de recorrido izquierda
			ctx.bezierCurveTo(midWidth+11.5,startHeight-482.55,midWidth-20.69,startHeight-450.36,midWidth-60.25,startHeight-450.36);
			ctx.bezierCurveTo(midWidth-87.13,startHeight-450.36,midWidth-109,startHeight-428.49,midWidth-109,startHeight-401.61);
			ctx.lineTo(midWidth-109,startHeight-384.61);
			ctx.bezierCurveTo(midWidth-109,startHeight-357.73,midWidth-87.13,startHeight-335.86,midWidth-60.25,startHeight-335.86);
		}

		ctx.lineTo(midWidth+60.25,startHeight-335.86);
		ctx.bezierCurveTo(midWidth+99.81,startHeight-335.86,midWidth+132,startHeight-303.67,midWidth+132,startHeight-264.11);
		ctx.lineTo(midWidth+132,startHeight-245.99);

		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth+132,startHeight-206.43,midWidth+99.81,startHeight-174.24,midWidth+60.25,startHeight-174.24);
			ctx.bezierCurveTo(midWidth+33.37,startHeight-174.24,midWidth+11.5,startHeight-152.37,midWidth+11.5,startHeight-125.49);
			ctx.lineTo(midWidth+11.5,startHeight);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				ctx.bezierCurveTo(midWidth+132,startHeight-206.43,midWidth+99.81,startHeight-174.24 - 18,midWidth+60.25,startHeight-174.24 - 12);
				startHeight= startHeight + 266.11;
				ctx.bezierCurveTo(midWidth+11.5,startHeight-482.55 + 38,midWidth-20.69,startHeight-450.36,midWidth-60.25,startHeight-450.36);
				ctx.bezierCurveTo(midWidth-87.13,startHeight-450.36,midWidth-109,startHeight-428.49,midWidth-109,startHeight-401.61);
				ctx.lineTo(midWidth-109,startHeight-384.61);
				ctx.bezierCurveTo(midWidth-109,startHeight-357.73,midWidth-87.13,startHeight-335.86,midWidth-60.25,startHeight-335.86);
				ctx.lineTo(midWidth+60.25,startHeight-335.86);
				ctx.bezierCurveTo(midWidth+99.81,startHeight-335.86,midWidth+132,startHeight-303.67,midWidth+132,startHeight-264.11);
				ctx.lineTo(midWidth+132,startHeight-245.99);
				if (index === additional1) {
					ctx.bezierCurveTo(midWidth+132,startHeight-206.43,midWidth+99.81,startHeight-174.24,midWidth+60.25,startHeight-174.24);
					ctx.bezierCurveTo(midWidth+33.37,startHeight-174.24,midWidth+11.5,startHeight-152.37,midWidth+11.5,startHeight-125.49);
					ctx.lineTo(midWidth+11.5,startHeight);
				}
			}
		}
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

	drawRoad2(ctx,midWidth,startHeight,numberActivity, stage){
		const additional = Math.ceil((numberActivity-8)/3) > 0 ? Math.ceil((numberActivity-8)/3) : 0
		const additional1 = Math.ceil(additional/2);
		const additional2 = Math.floor(additional/2);
		const startH = startHeight;
		this.drawElementRoad2(ctx,midWidth,startH,numberActivity, stage);

		let colorEmpresa = stage.color ? stage.color : '#2e36ce'


		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(1,1);
		ctx.save();
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.strokeStyle=colorEmpresa;
		ctx.lineWidth=12;
		ctx.miterLimit=10;
		ctx.beginPath();
		ctx.moveTo(midWidth,startHeight+80);
		ctx.lineTo(midWidth,startHeight-125.49);
		ctx.bezierCurveTo(midWidth,startHeight-158.77,midWidth-26.97,startHeight-185.74,midWidth-60.25,startHeight-185.74);
		ctx.bezierCurveTo(midWidth-93.53,startHeight-185.74,midWidth-120.5,startHeight-212.71,midWidth-120.5,startHeight-245.99);
		ctx.lineTo(midWidth-120.5,startHeight-264.1);
		ctx.bezierCurveTo(midWidth-120.5,startHeight-297.38,midWidth-93.53,startHeight-324.35,midWidth-60.25,startHeight-324.35); // aqui va al otro
		ctx.bezierCurveTo(midWidth-13.192,startHeight-324.35,midWidth+13.192,startHeight-324.35,midWidth+60.25,startHeight-324.35);
		ctx.bezierCurveTo(midWidth+93.53,startHeight-324.35,midWidth+120.5,startHeight-351.32,midWidth+120.5,startHeight-384.6);
		ctx.lineTo(midWidth+120.5,startHeight-401.6);
		ctx.bezierCurveTo(midWidth+120.5,startHeight-434.88,midWidth+93.53,startHeight-461.85,midWidth+60.25,startHeight-461.85);
		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth+26.97,startHeight-461.85,midWidth,startHeight-488.82,midWidth,startHeight-522.09999);
			ctx.lineTo(midWidth,startHeight-824.95);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				startHeight= startHeight - 266.11;
				ctx.bezierCurveTo(midWidth-93.53,startHeight-185.74,midWidth-120.5,startHeight-212.71,midWidth-120.5,startHeight-245.99);
				ctx.lineTo(midWidth-120.5,startHeight-264.1);
				ctx.bezierCurveTo(midWidth-120.5,startHeight-297.38,midWidth-93.53,startHeight-324.35,midWidth-60.25,startHeight-324.35);
				if (index === additional1 && additional1 !== additional2) {
					//Fin de recorrido por por derecha
					ctx.bezierCurveTo(midWidth-26.97,startHeight-324.35,midWidth,startHeight-351.32,midWidth,startHeight-384.59999);
					ctx.lineTo(midWidth,startHeight-824.95);
				}

				if (index <= additional2) {
					ctx.bezierCurveTo(midWidth-13.192,startHeight-324.35,midWidth+13.192,startHeight-324.35,midWidth+60.25,startHeight-324.35);
					ctx.bezierCurveTo(midWidth+93.53,startHeight-324.35,midWidth+120.5,startHeight-351.32,midWidth+120.5,startHeight-384.6);
					ctx.lineTo(midWidth+120.5,startHeight-401.6);
					ctx.bezierCurveTo(midWidth+120.5,startHeight-434.88,midWidth+93.53,startHeight-461.85,midWidth+60.25,startHeight-461.85);
					if (index === additional2 && additional1 === additional2) {
						//Fin de recorrido por izquierda
						ctx.bezierCurveTo(midWidth+26.97,startHeight-461.85,midWidth,startHeight-488.82,midWidth,startHeight-522.09999);
						ctx.lineTo(midWidth,startHeight-824.95);
					}
				}
			}
		}
		startHeight = startH;
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.strokeStyle=colorEmpresa;
		ctx.miterLimit=10;
		ctx.beginPath();
		// ctx.moveTo(midWidth-11.5,startHeight);
		// ctx.lineTo(midWidth+11.5,startHeight);

		ctx.moveTo(midWidth+11.5,startHeight+66);
		ctx.lineTo(midWidth+11.5,startHeight-125.49);
		ctx.bezierCurveTo(midWidth+11.5,startHeight-165.05,midWidth-20.69,startHeight-197.24,midWidth-60.25,startHeight-197.24);
		ctx.bezierCurveTo(midWidth-87.13,startHeight-197.24,midWidth-109,startHeight-219.11,midWidth-109,startHeight-245.99);
		ctx.lineTo(midWidth-109,startHeight-264.11);
		ctx.bezierCurveTo(midWidth-109,startHeight-290.99,midWidth-87.13,startHeight-312.86,midWidth-60.25,startHeight-312.86);
		ctx.lineTo(midWidth+60.25,startHeight-312.86);
		ctx.bezierCurveTo(midWidth+99.81,startHeight-312.86,midWidth+132,startHeight-345.05,midWidth+132,startHeight-384.61);
		ctx.lineTo(midWidth+132,startHeight-401.61);
		ctx.bezierCurveTo(midWidth+132,startHeight-441.17,midWidth+99.81,startHeight-473.36,midWidth+60.25,startHeight-473.36);
		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth+33.37,startHeight-473.36,midWidth+11.5,startHeight-495.23,midWidth+11.5,startHeight-522.11);
			ctx.lineTo(midWidth+11.5,startHeight-824.95);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				startHeight= startHeight - 266.11;
				ctx.bezierCurveTo(midWidth-87.13,startHeight-197.24,midWidth-109,startHeight-219.11,midWidth-109,startHeight-245.99);
				ctx.lineTo(midWidth-109,startHeight-264.11);
				ctx.bezierCurveTo(midWidth-109,startHeight-290.99,midWidth-87.13,startHeight-312.86,midWidth-60.25,startHeight-312.86);

				if (index === additional1 && additional1 !== additional2) {
					// Fin de recorrido derecha
					ctx.bezierCurveTo(midWidth-33.37,startHeight-312.86,midWidth+11.5,startHeight-334.73,midWidth+11.5,startHeight-381.61);
					ctx.lineTo(midWidth+11.5,startHeight-824.95);
				}

				if (index <= additional2) {
					ctx.lineTo(midWidth+60.25,startHeight-312.86);
					ctx.bezierCurveTo(midWidth+99.81,startHeight-312.86,midWidth+132,startHeight-345.05,midWidth+132,startHeight-384.61);
					ctx.lineTo(midWidth+132,startHeight-401.61);
					ctx.bezierCurveTo(midWidth+132,startHeight-441.17,midWidth+99.81,startHeight-473.36,midWidth+60.25,startHeight-473.36);
					if (index === additional2 && additional1 === additional2) {
						// Fin de recorrido izquierda
						ctx.bezierCurveTo(midWidth+33.37,startHeight-473.36,midWidth+11.5,startHeight-495.23,midWidth+11.5,startHeight-522.11);
						ctx.lineTo(midWidth+11.5,startHeight-824.95);
					}

				}
			}
		}
		ctx.lineTo(midWidth-11.5,startHeight-824.95);
		ctx.lineTo(midWidth-11.5,startHeight-522.11);
		if (additional1 !== additional2) {
			// Fin de recorrido derecha
			ctx.lineTo(midWidth-11.5,startHeight-382.11);
			ctx.bezierCurveTo(midWidth-11.5,startHeight-372.55,midWidth-20.69,startHeight-335.86,midWidth-60.25,startHeight-335.86);
		}

		if (additional1 === additional2) {
			// Fin de recorrido izquierda
			ctx.bezierCurveTo(midWidth-11.5,startHeight-482.55,midWidth+20.69,startHeight-450.36,midWidth+60.25,startHeight-450.36);
			ctx.bezierCurveTo(midWidth+87.13,startHeight-450.36,midWidth+109,startHeight-428.49,midWidth+109,startHeight-401.61);
			ctx.lineTo(midWidth+109,startHeight-384.61);
			ctx.bezierCurveTo(midWidth+109,startHeight-357.73,midWidth+87.13,startHeight-335.86,midWidth+60.25,startHeight-335.86);
		}

		ctx.lineTo(midWidth-60.25,startHeight-335.86);
		ctx.bezierCurveTo(midWidth-99.81,startHeight-335.86,midWidth-132,startHeight-303.67,midWidth-132,startHeight-264.11);
		ctx.lineTo(midWidth-132,startHeight-245.99);

		if(numberActivity <= 8){
			ctx.bezierCurveTo(midWidth-132,startHeight-206.43,midWidth-99.81,startHeight-174.24,midWidth-60.25,startHeight-174.24);
			ctx.bezierCurveTo(midWidth-33.37,startHeight-174.24,midWidth-11.5,startHeight-152.37,midWidth-11.5,startHeight-125.49);
			ctx.lineTo(midWidth-11.5,startHeight+66);
		}
		if(numberActivity > 8){
			for (let index = 1; index <= additional1; index++) {
				ctx.bezierCurveTo(midWidth-132,startHeight-206.43,midWidth-99.81,startHeight-174.24 - 18,midWidth-60.25,startHeight-174.24 - 12);
				startHeight= startHeight + 266.11;
				ctx.bezierCurveTo(midWidth-11.5,startHeight-482.55 + 38,midWidth+20.69,startHeight-450.36,midWidth+60.25,startHeight-450.36);
				ctx.bezierCurveTo(midWidth+87.13,startHeight-450.36,midWidth+109,startHeight-428.49,midWidth+109,startHeight-401.61);
				ctx.lineTo(midWidth+109,startHeight-384.61);
				ctx.bezierCurveTo(midWidth+109,startHeight-357.73,midWidth+87.13,startHeight-335.86,midWidth+60.25,startHeight-335.86);
				ctx.lineTo(midWidth-60.25,startHeight-335.86);
				ctx.bezierCurveTo(midWidth-99.81,startHeight-335.86,midWidth-132,startHeight-303.67,midWidth-132,startHeight-264.11);
				ctx.lineTo(midWidth-132,startHeight-245.99);
				if (index === additional1) {
					ctx.bezierCurveTo(midWidth-132,startHeight-206.43,midWidth-99.81,startHeight-174.24,midWidth-60.25,startHeight-174.24);
					ctx.bezierCurveTo(midWidth-33.37,startHeight-174.24,midWidth-11.5,startHeight-152.37,midWidth-11.5,startHeight-125.49);
					ctx.lineTo(midWidth-11.5,startHeight);
				}
			}
		}
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

	drawElementHtml(startHeight, activity, type, title, ctx,midWidth) {

		const activities = Array.from(document.getElementsByClassName(activity) as HTMLCollectionOf<HTMLElement>)
		this.startHeightHtml = startHeight;
		const card = document.getElementById(title);
		let constTree = 0;
		card.style.top = `${this.startHeightHtml}px`
		activities.forEach((activity,index) => {
			switch ((index > 7 ? index + 3 : index + 1) % 8) {
				case 1:
					this.startHeightHtml= this.startHeightHtml - 125;
					activity.style.top = `${this.startHeightHtml}px`
					constTree = 10;
					break;
				case 2:
					this.startHeightHtml= this.startHeightHtml - 70;
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginLeft = "4rem": activity.style.marginRight = "4rem"
					constTree = 28;
					break;
				case 3:
					this.startHeightHtml= this.startHeightHtml - (index > 7 ? 24 :  40);
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginLeft = "14rem": activity.style.marginRight = "14rem"
					constTree = 0;
					break;
				case 4:
					this.startHeightHtml= this.startHeightHtml - 90;
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginLeft = "13rem" : activity.style.marginRight = "13rem"
					constTree = 60;
					break;
				case 5:
					this.startHeightHtml= this.startHeightHtml - 18;
					activity.style.top = `${this.startHeightHtml}px`
					constTree = 60;
					break;
				case 6:
					this.startHeightHtml= this.startHeightHtml - 18;
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginRight = "13rem" : activity.style.marginLeft = "13rem"
					constTree = 10;
					break;
				case 7:
					this.startHeightHtml= this.startHeightHtml - 100;
					activity.style.top = `${this.startHeightHtml}px`;
					(type === 1) ? activity.style.marginRight = "13rem" : activity.style.marginLeft = "13rem"
					constTree = 50;
					break;
				case 0:
					if (activities.length > 8) {
						this.startHeightHtml= this.startHeightHtml - 18;
					} else {
						this.startHeightHtml= this.startHeightHtml - 40;
						(type === 1) ? activity.style.marginRight = "2rem" :  activity.style.marginLeft = "2rem"
					}
					activity.style.top = `${this.startHeightHtml}px`
					break;
				default:
					break;
			}
		});

		if(activities.length > 8){

		} else if(activities.length < 8) {
			midWidth = type === 1 ? midWidth : midWidth - 192;
			midWidth  = ((activities.length % 6) > 3 || activities.length == 6) ? midWidth +(-190*type) : activities.length == 7 ? midWidth+(-92*type): midWidth;
			// midWidth = type === 1 ? midWidth : (midWidth*type) + 412 - 200;
			const startTree = this.startHeightHtml + constTree;

			this.drawElementTree(ctx,1,midWidth+60,startTree-130);
			this.drawElementTree(ctx,1,midWidth+80,startTree-135);
			this.drawElementTree(ctx,1,midWidth+100,startTree-128);
			this.drawElementTree(ctx,1,midWidth+50,startTree-108);
			this.drawElementTree(ctx,1,midWidth+71,startTree-114);
			this.drawElementTree(ctx,1,midWidth+90,startTree-120);
			this.drawElementTree(ctx,1,midWidth+110,startTree-112);
			this.drawElementTree(ctx,1,midWidth+61,startTree-96);
			this.drawElementTree(ctx,1,midWidth+96,startTree-100);
			this.drawElementTree(ctx,1,midWidth+80,startTree-98);
		}
	}

	drawElementRoad1(ctx,midWidth,startHeight,numberActivity, bgmap1){

		let colorFondoOpacity = bgmap1 ? bgmap1+'54' : '#8BFC8954'

		const additional = Math.ceil((numberActivity-8)/6) > 0 ? Math.ceil((numberActivity-8)/6) : 0
		// Fondo
		let heightBase = 144;
		let heightBackground = 115;
		for (let index = 0; index < additional + 2; index++) {
			if(index % 2 === 0) {
				heightBase = heightBase + 130;
				this.drawBaseBackground1(ctx,1, midWidth-210, startHeight-heightBase, colorFondoOpacity)
			} else {
				heightBase = heightBase + 400;
				this.drawBaseBackground2(ctx,1, midWidth-210, startHeight-heightBase, colorFondoOpacity)
			}
		}
		for (let index = 0; index < additional + 2; index++) {
			if(index % 2 === 0) {
				heightBackground = heightBackground + 195;
				this.drawBackground1(ctx,1, midWidth-198, startHeight-heightBackground, bgmap1)
			} else {
				heightBackground = heightBackground + 330;
				this.drawBackground2(ctx,1, midWidth-186, startHeight-heightBackground, bgmap1)
			}
		}

		// Elementos
		let heigthElement = 0;
		for (let index = 0; index < additional + 1; index++) {
			switch (index % 4) {
				case 0:
					this.drawElementBush(ctx,0.4,midWidth-100,startHeight-105);
					this.drawElementBush(ctx,0.4,midWidth-122,startHeight-92);

					this.drawElementTree(ctx,1,midWidth+60,startHeight-130);
					this.drawElementTree(ctx,1,midWidth+80,startHeight-135);
					this.drawElementTree(ctx,1,midWidth+100,startHeight-128);
					this.drawElementTree(ctx,1,midWidth+50,startHeight-108);
					this.drawElementTree(ctx,1,midWidth+71,startHeight-114);
					this.drawElementTree(ctx,1,midWidth+90,startHeight-120);
					this.drawElementTree(ctx,1,midWidth+110,startHeight-112);
					this.drawElementTree(ctx,1,midWidth+61,startHeight-96);
					this.drawElementTree(ctx,1,midWidth+96,startHeight-100);
					this.drawElementTree(ctx,1,midWidth+80,startHeight-98);

					this.drawElementRock(ctx,0.42,midWidth+18,startHeight-250);
					this.drawElementRock(ctx,0.42,midWidth-4,startHeight-232);

					this.drawElementRock(ctx,0.8,midWidth-94,startHeight-252);
					this.drawElementRock(ctx,0.42,midWidth-22,startHeight-278);
					this.drawElementRock(ctx,0.42,midWidth-78,startHeight-194);

					this.drawElementMountain(ctx,0.52,midWidth+74,startHeight-450);
					this.drawElementMountain(ctx,1,midWidth-20,startHeight-440);
					this.drawElementMountain(ctx,1,midWidth+36,startHeight-436);
					this.drawElementMountain(ctx,0.6,midWidth+6,startHeight-402);
					this.drawElementMountain(ctx,0.6,midWidth+36,startHeight-394);
					this.drawElementMountain(ctx,0.35,midWidth-6,startHeight-378);

					// Fin de recorrido
					this.drawElementRock(ctx,0.5,midWidth-80,startHeight-550);
					this.drawElementBush(ctx,0.45,midWidth-140,startHeight-540);
					this.drawElementRock(ctx,0.5,midWidth-156,startHeight-526);

					this.drawElementRock(ctx,0.42,midWidth+72+(additional>0?-40:0),startHeight-550);
					this.drawElementRock(ctx,0.42,midWidth+50+(additional>0?-40:0),startHeight-532);
					this.drawElementRock(ctx,0.42,midWidth+78+(additional>0?-40:0),startHeight-512);
					this.drawElementRock(ctx,0.42,midWidth+30+(additional>0?-40:0),startHeight-572);
					break;
				case 1:
					heigthElement += 580;
					this.drawElementBush(ctx,0.4,midWidth-100,startHeight-heigthElement-105);
					this.drawElementBush(ctx,0.4,midWidth-122,startHeight-heigthElement-92);

					this.drawElementTree(ctx,1,midWidth+60,startHeight-heigthElement-130);
					this.drawElementTree(ctx,1,midWidth+80,startHeight-heigthElement-135);
					this.drawElementTree(ctx,1,midWidth+100,startHeight-heigthElement-128);
					this.drawElementTree(ctx,1,midWidth+50,startHeight-heigthElement-108);
					this.drawElementTree(ctx,1,midWidth+71,startHeight-heigthElement-114);
					this.drawElementTree(ctx,1,midWidth+90,startHeight-heigthElement-120);
					this.drawElementTree(ctx,1,midWidth+110,startHeight-heigthElement-112);
					this.drawElementTree(ctx,1,midWidth+61,startHeight-heigthElement-96);
					this.drawElementTree(ctx,1,midWidth+96,startHeight-heigthElement-100);
					this.drawElementTree(ctx,1,midWidth+80,startHeight-heigthElement-98);
					break;
				case 2:
					this.drawElementRock(ctx,0.8,midWidth-94,startHeight-heigthElement-252);
					this.drawElementRock(ctx,0.42,midWidth-78,startHeight-heigthElement-194);

					this.drawElementRock(ctx,0.42,midWidth+(numberActivity%8>1?50:0)-22,startHeight-heigthElement-278);
					this.drawElementRock(ctx,0.42,midWidth+(numberActivity%8>1?50:0)+18,startHeight-heigthElement-250);
					this.drawElementRock(ctx,0.42,midWidth+(numberActivity%8>1?50:0)-4,startHeight-heigthElement-232);

					break;
				case 3:
					break;
				default:
					break;
			}
		}

	}

	drawElementRoad2(ctx,midWidth,startHeight,numberActivity, stage){
		// Fondo
    let colorFondo = stage.color_map_even ? stage.color_map_even : '#8BFC89'
		let colorFondoOpacity = colorFondo ? colorFondo+'54' : '#8BFC8954'

		this.drawBaseBackground1(ctx,1, midWidth-210, startHeight-274, colorFondoOpacity) //#F7EBC0
		this.drawBaseBackground2(ctx,1, midWidth-198, startHeight-640, colorFondoOpacity) //#F7EBC0
		this.drawBackground1(ctx,1, midWidth-198, startHeight-310, colorFondo) //#8BFC89
		this.drawBackground2(ctx,1, midWidth-182, startHeight-630, colorFondo) //#8BFC89


		// Elementos

		this.drawElementRock(ctx,0.3,midWidth-108,startHeight-100);
		this.drawElementRock(ctx,0.65,midWidth-84,startHeight-120);
		this.drawElementBush(ctx,0.25,midWidth-98,startHeight-94);
		this.drawElementRock(ctx,0.3,midWidth-84,startHeight-82);

		this.drawElementBush(ctx,0.4,midWidth+62,startHeight-185);
		this.drawElementBush(ctx,0.4,midWidth+40,startHeight-172);

		this.drawElementBush(ctx,0.4,midWidth+50,startHeight-110);

		// this.drawElementTree(ctx,1,midWidth+60,startHeight-130);
		// this.drawElementTree(ctx,1,midWidth+80,startHeight-135);
		// this.drawElementTree(ctx,1,midWidth+100,startHeight-128);
		// this.drawElementTree(ctx,1,midWidth+50,startHeight-108);
		// this.drawElementTree(ctx,1,midWidth+71,startHeight-114);
		// this.drawElementTree(ctx,1,midWidth+90,startHeight-120);
		// this.drawElementTree(ctx,1,midWidth+110,startHeight-112);
		// this.drawElementTree(ctx,1,midWidth+61,startHeight-96);
		// this.drawElementTree(ctx,1,midWidth+96,startHeight-100);
		// this.drawElementTree(ctx,1,midWidth+80,startHeight-98);

		this.drawElementRock(ctx,0.42,midWidth+18,startHeight-420);
		this.drawElementRock(ctx,0.42,midWidth-4,startHeight-402);

		this.drawElementRock(ctx,0.8,midWidth-94,startHeight-422);
		this.drawElementRock(ctx,0.42,midWidth-22,startHeight-448);
		this.drawElementRock(ctx,0.42,midWidth-78,startHeight-464);

		this.drawElementMountain(ctx,0.52,midWidth+74,startHeight-300);
		this.drawElementMountain(ctx,1,midWidth-20,startHeight-290);
		this.drawElementMountain(ctx,1,midWidth+36,startHeight-286);
		this.drawElementMountain(ctx,0.6,midWidth+6,startHeight-252);
		this.drawElementMountain(ctx,0.5,midWidth+36,startHeight-244);
		this.drawElementMountain(ctx,0.25,midWidth-6,startHeight-228);

		// Fin de recorrido
		this.drawElementRock(ctx,0.5,midWidth-80,startHeight-550);
		this.drawElementBush(ctx,0.45,midWidth-140,startHeight-540);
		this.drawElementRock(ctx,0.5,midWidth-156,startHeight-526);

		this.drawElementRock(ctx,0.42,midWidth+72,startHeight-550);
		this.drawElementRock(ctx,0.42,midWidth+50,startHeight-532);
		this.drawElementRock(ctx,0.42,midWidth+78,startHeight-512);
		this.drawElementRock(ctx,0.42,midWidth+30,startHeight-572);
	}

	drawBackground1(ctx, scale, x, y, color){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle=color;
		ctx.strokeStyle=color;
		ctx.lineWidth=4;
		ctx.beginPath();
		ctx.moveTo(x+336.59,y+150.361);
		ctx.lineTo(x+336.59,y+267.34);
		ctx.bezierCurveTo(x+336.59,y+274.847,x+342.981,y+280.75,x+350.635,y+280.75);
		ctx.lineTo(x+366.955,y+280.75);
		ctx.bezierCurveTo(x+372.606,y+280.75,x+377,y+285.063,x+377,y+290.16);
		ctx.lineTo(x+377,y+402.922);
		ctx.bezierCurveTo(x+377,y+408.019,x+372.606,y+412.332,x+366.955,y+412.332);
		ctx.lineTo(x+272.605,y+412.332);
		ctx.bezierCurveTo(x+266.953,y+412.332,x+262.56,y+408.019,x+262.56,y+402.922);
		ctx.lineTo(x+262.56,y+386.058);
		ctx.bezierCurveTo(x+262.56,y+378.551,x+256.168,y+372.648,x+248.514,y+372.648);
		ctx.lineTo(x+187.333,y+372.648);
		ctx.bezierCurveTo(x+179.68,y+372.648,x+173.288,y+378.551,x+173.288,y+386.058);
		ctx.lineTo(x+173.288,y+423.59);
		ctx.bezierCurveTo(x+173.288,y+428.686,x+168.894,y+433,x+163.243,y+433);
		ctx.lineTo(x+120.725,y+433);
		ctx.bezierCurveTo(x+115.073,y+433,x+110.679,y+428.686,x+110.679,y+423.59);
		ctx.lineTo(x+110.679,y+386.058);
		ctx.bezierCurveTo(x+110.679,y+378.551,x+104.288,y+372.648,x+96.6339,y+372.648);
		ctx.lineTo(x+63.5945,y+372.648);
		ctx.bezierCurveTo(x+57.9429,y+372.648,x+53.5491,y+368.334,x+53.5491,y+363.238);
		ctx.bezierCurveTo(x+53.5491,y+355.731,x+47.1574,y+349.828,x+39.5037,y+349.828);
		ctx.lineTo(x+24.5853,y+349.828);
		ctx.bezierCurveTo(x+18.9338,y+349.828,x+14.5399,y+345.514,x+14.5399,y+340.418);
		ctx.lineTo(x+14.5399,y+290.171);
		ctx.bezierCurveTo(x+14.5399,y+285.075,x+18.9338,y+280.761,x+24.5853,y+280.761);
		ctx.lineTo(x+39.5037,y+280.761);
		ctx.bezierCurveTo(x+47.1574,y+280.761,x+53.5491,y+274.858,x+53.5491,y+267.351);
		ctx.lineTo(x+53.5491,y+216.256);
		ctx.bezierCurveTo(x+53.5491,y+208.749,x+47.1574,y+202.846,x+39.5037,y+202.846);
		ctx.lineTo(x+12.0454,y+202.846);
		ctx.bezierCurveTo(x+6.39385,y+202.846,x+2,y+198.533,x+2,y+193.436);
		ctx.lineTo(x+2,y+150.372);
		ctx.bezierCurveTo(x+2,y+145.276,x+6.39384,y+140.962,x+12.0454,y+140.962);
		ctx.lineTo(x+39.5037,y+140.962);
		ctx.bezierCurveTo(x+47.1574,y+140.962,x+53.5491,y+135.059,x+53.5491,y+127.552);
		ctx.lineTo(x+53.5491,y+41.4578);
		ctx.bezierCurveTo(x+53.5491,y+36.3613,x+57.9429,y+32.0477,x+63.5945,y+32.0477);
		ctx.lineTo(x+82.9283,y+32.0477);
		ctx.bezierCurveTo(x+90.582,y+32.0477,x+96.9737,y+26.1447,x+96.9737,y+18.6376);
		ctx.lineTo(x+96.9737,y+11.4101);
		ctx.bezierCurveTo(x+96.9737,y+6.31363,x+101.368,y+2,x+107.019,y+2);
		ctx.lineTo(x+221.303,y+2);
		ctx.bezierCurveTo(x+226.955,y+2,x+231.349,y+6.31363,x+231.349,y+11.4101);
		ctx.lineTo(x+231.349,y+18.6376);
		ctx.bezierCurveTo(x+231.349,y+26.1447,x+237.74,y+32.0477,x+245.394,y+32.0477);
		ctx.lineTo(x+326.544,y+32.0477);
		ctx.bezierCurveTo(x+332.196,y+32.0477,x+336.59,y+36.3613,x+336.59,y+41.4578);
		ctx.lineTo(x+336.59,y+66.0291);
		ctx.bezierCurveTo(x+336.59,y+73.5361,x+342.981,y+79.4392,x+350.635,y+79.4392);
		ctx.lineTo(x+354.415,y+79.4392);
		ctx.bezierCurveTo(x+360.066,y+79.4392,x+364.46,y+83.7528,x+364.46,y+88.8493);
		ctx.lineTo(x+364.46,y+127.541);
		ctx.bezierCurveTo(x+364.46,y+132.637,x+360.066,y+136.951,x+354.415,y+136.951);
		ctx.lineTo(x+350.635,y+136.951);
		ctx.bezierCurveTo(x+342.981,y+136.951,x+336.59,y+142.854,x+336.59,y+150.361);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawBackground2(ctx, scale, x, y, color){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle=color;
		ctx.strokeStyle=color;
		ctx.lineWidth=4;
		ctx.beginPath();
		ctx.moveTo(x+42.3998,y+284.639);
		ctx.lineTo(x+42.3998,y+169.66);
		ctx.lineTo(x+42.4116,y+169.66);
		ctx.lineTo(x+42.4116,y+167.66);
		ctx.bezierCurveTo(x+42.4116,y+160.153,x+36.0197,y+154.25,x+28.3658,y+154.25);
		ctx.lineTo(x+12.0458,y+154.25);
		ctx.bezierCurveTo(x+6.39394,y+154.25,x+2,y+149.937,x+2,y+144.84);
		ctx.lineTo(x+2,y+32.0776);
		ctx.bezierCurveTo(x+2,y+26.9812,x+6.39394,y+22.6675,x+12.0458,y+22.6675);
		ctx.lineTo(x+106.387,y+22.6675);
		ctx.bezierCurveTo(x+112.038,y+22.6675,x+116.432,y+26.9812,x+116.432,y+32.0776);
		ctx.lineTo(x+116.432,y+48.9418);
		ctx.bezierCurveTo(x+116.432,y+56.4489,x+122.824,y+62.3519,x+130.478,y+62.3519);
		ctx.lineTo(x+191.661,y+62.3519);
		ctx.bezierCurveTo(x+199.315,y+62.3519,x+205.706,y+56.4489,x+205.706,y+48.9418);
		ctx.lineTo(x+205.706,y+11.4101);
		ctx.bezierCurveTo(x+205.706,y+6.31368,x+210.1,y+2,x+215.752,y+2);
		ctx.lineTo(x+258.272,y+2);
		ctx.bezierCurveTo(x+263.923,y+2,x+268.317,y+6.31368,x+268.317,y+11.4101);
		ctx.lineTo(x+268.317,y+48.9418);
		ctx.bezierCurveTo(x+268.317,y+56.4489,x+274.709,y+62.3519,x+282.363,y+62.3519);
		ctx.lineTo(x+315.404,y+62.3519);
		ctx.bezierCurveTo(x+321.055,y+62.3519,x+325.449,y+66.6656,x+325.449,y+71.762);
		ctx.bezierCurveTo(x+325.449,y+79.2691,x+331.841,y+85.1721,x+339.495,y+85.1721);
		ctx.lineTo(x+354.414,y+85.1721);
		ctx.bezierCurveTo(x+360.066,y+85.1721,x+364.46,y+89.4858,x+364.46,y+94.5822);
		ctx.lineTo(x+364.46,y+144.829);
		ctx.bezierCurveTo(x+364.46,y+149.925,x+360.066,y+154.239,x+354.414,y+154.239);
		ctx.lineTo(x+339.495,y+154.239);
		ctx.bezierCurveTo(x+331.841,y+154.239,x+325.449,y+160.142,x+325.449,y+167.649);
		ctx.lineTo(x+325.449,y+218.744);
		ctx.bezierCurveTo(x+325.449,y+226.251,x+331.841,y+232.154,x+339.495,y+232.154);
		ctx.lineTo(x+366.954,y+232.154);
		ctx.bezierCurveTo(x+372.606,y+232.154,x+377,y+236.467,x+377,y+241.564);
		ctx.lineTo(x+377,y+284.628);
		ctx.bezierCurveTo(x+377,y+289.724,x+372.606,y+294.038,x+366.954,y+294.038);
		ctx.lineTo(x+339.495,y+294.038);
		ctx.bezierCurveTo(x+331.841,y+294.038,x+325.449,y+299.941,x+325.449,y+307.448);
		ctx.lineTo(x+325.449,y+393.542);
		ctx.bezierCurveTo(x+325.449,y+398.639,x+321.055,y+402.952,x+315.404,y+402.952);
		ctx.lineTo(x+296.069,y+402.952);
		ctx.bezierCurveTo(x+288.415,y+402.952,x+282.023,y+408.855,x+282.023,y+416.362);
		ctx.lineTo(x+282.023,y+423.59);
		ctx.bezierCurveTo(x+282.023,y+428.686,x+277.629,y+433,x+271.978,y+433);
		ctx.lineTo(x+157.69,y+433);
		ctx.bezierCurveTo(x+152.038,y+433,x+147.644,y+428.686,x+147.644,y+423.59);
		ctx.lineTo(x+147.644,y+416.362);
		ctx.bezierCurveTo(x+147.644,y+408.855,x+141.252,y+402.952,x+133.598,y+402.952);
		ctx.lineTo(x+52.4456,y+402.952);
		ctx.bezierCurveTo(x+46.7938,y+402.952,x+42.3998,y+398.639,x+42.3998,y+393.542);
		ctx.lineTo(x+42.3998,y+368.971);
		ctx.bezierCurveTo(x+42.3998,y+361.464,x+36.0079,y+355.561,x+28.3541,y+355.561);
		ctx.lineTo(x+24.5743,y+355.561);
		ctx.bezierCurveTo(x+18.9225,y+355.561,x+14.5285,y+351.247,x+14.5285,y+346.151);
		ctx.lineTo(x+14.5285,y+307.459);
		ctx.bezierCurveTo(x+14.5285,y+302.363,x+18.9225,y+298.049,x+24.5743,y+298.049);
		ctx.lineTo(x+28.3541,y+298.049);
		ctx.bezierCurveTo(x+36.0079,y+298.049,x+42.3998,y+292.146,x+42.3998,y+284.639);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawBaseBackground1(ctx, scale, x, y, color){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle=color;
		ctx.beginPath();
		ctx.moveTo(x+164.469,y+38.2807);
		ctx.lineTo(x+279.221,y+38.2807);
		ctx.bezierCurveTo(x+285.403,y+38.2807,x+290.414,y+33.1721,x+290.414,y+26.8701);
		ctx.lineTo(x+290.414,y+23.2896);
		ctx.bezierCurveTo(x+290.414,y+16.9876,x+295.425,y+11.8791,x+301.607,y+11.8791);
		ctx.lineTo(x+339.562,y+11.8791);
		ctx.bezierCurveTo(x+345.744,y+11.8791,x+350.755,y+16.9876,x+350.755,y+23.2896);
		ctx.lineTo(x+350.755,y+26.8701);
		ctx.bezierCurveTo(x+350.755,y+33.1721,x+355.766,y+38.2807,x+361.948,y+38.2807);
		ctx.lineTo(x+386.052,y+38.2807);
		ctx.bezierCurveTo(x+392.233,y+38.2807,x+397.244,y+43.3892,x+397.244,y+49.6912);
		ctx.lineTo(x+397.244,y+126.565);
		ctx.bezierCurveTo(x+397.244,y+132.867,x+402.256,y+137.975,x+408.437,y+137.975);
		ctx.lineTo(x+415.527,y+137.975);
		ctx.bezierCurveTo(x+421.709,y+137.975,x+426.72,y+143.084,x+426.72,y+149.386);
		ctx.lineTo(x+426.72,y+257.647);
		ctx.bezierCurveTo(x+426.72,y+263.949,x+421.709,y+269.058,x+415.527,y+269.058);
		ctx.lineTo(x+408.437,y+269.058);
		ctx.bezierCurveTo(x+402.256,y+269.058,x+397.244,y+274.166,x+397.244,y+280.468);
		ctx.lineTo(x+397.244,y+298.783);
		ctx.bezierCurveTo(x+397.244,y+305.085,x+392.233,y+310.194,x+386.052,y+310.194);
		ctx.lineTo(x+301.596,y+310.194);
		ctx.bezierCurveTo(x+295.414,y+310.194,x+290.403,y+315.302,x+290.403,y+321.604);
		ctx.lineTo(x+290.403,y+347.616);
		ctx.bezierCurveTo(x+290.403,y+353.918,x+285.392,y+359.026,x+279.21,y+359.026);
		ctx.lineTo(x+236.966,y+359.026);
		ctx.bezierCurveTo(x+230.784,y+359.026,x+225.773,y+353.918,x+225.773,y+347.616);
		ctx.lineTo(x+225.773,y+321.604);
		ctx.bezierCurveTo(x+225.773,y+315.302,x+220.762,y+310.194,x+214.58,y+310.194);
		ctx.lineTo(x+164.458,y+310.194);
		ctx.bezierCurveTo(x+158.276,y+310.194,x+153.265,y+315.302,x+153.265,y+321.604);
		ctx.lineTo(x+153.265,y+335.736);
		ctx.bezierCurveTo(x+153.265,y+342.038,x+148.254,y+347.147,x+142.072,y+347.147);
		ctx.lineTo(x+92.7819,y+347.147);
		ctx.bezierCurveTo(x+86.6001,y+347.147,x+81.589,y+342.038,x+81.589,y+335.736);
		ctx.lineTo(x+81.589,y+321.604);
		ctx.bezierCurveTo(x+81.589,y+315.302,x+76.5779,y+310.194,x+70.3961,y+310.194);
		ctx.bezierCurveTo(x+64.2143,y+310.194,x+59.2032,y+305.085,x+59.2032,y+298.783);
		ctx.lineTo(x+59.2032,y+267.485);
		ctx.bezierCurveTo(x+59.2032,y+261.183,x+54.1921,y+256.074,x+48.0103,y+256.074);
		ctx.lineTo(x+11.1929,y+256.074);
		ctx.bezierCurveTo(x+5.0111,y+256.074,x+0,y+250.966,x+0,y+244.664);
		ctx.lineTo(x+0,y+204.387);
		ctx.bezierCurveTo(x+0,y+198.085,x+5.0111,y+192.976,x+11.1929,y+192.976);
		ctx.lineTo(x+48.0103,y+192.976);
		ctx.bezierCurveTo(x+54.1921,y+192.976,x+59.2032,y+187.867,x+59.2032,y+181.565);
		ctx.lineTo(x+59.2032,y+123.609);
		ctx.bezierCurveTo(x+59.2032,y+117.307,x+54.1921,y+112.198,x+48.0103,y+112.198);
		ctx.lineTo(x+31.4671,y+112.198);
		ctx.bezierCurveTo(x+25.2853,y+112.198,x+20.2742,y+107.09,x+20.2742,y+100.788);
		ctx.lineTo(x+20.2742,y+11.4106);
		ctx.bezierCurveTo(x+20.2742,y+5.10856,x+25.2853,y+0,x+31.4671,y+0);
		ctx.lineTo(x+142.083,y+0);
		ctx.bezierCurveTo(x+148.265,y+0,x+153.276,y+5.10856,x+153.276,y+11.4106);
		ctx.lineTo(x+153.276,y+26.8701);
		ctx.bezierCurveTo(x+153.276,y+33.1721,x+158.287,y+38.2807,x+164.469,y+38.2807);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawBaseBackground2(ctx, scale, x, y, color){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle=color;
		ctx.beginPath();
		ctx.moveTo(x+262.251,y+320.757);
		ctx.lineTo(x+147.499,y+320.757);
		ctx.bezierCurveTo(x+141.317,y+320.757,x+136.306,y+325.865,x+136.306,y+332.167);
		ctx.lineTo(x+136.306,y+335.748);
		ctx.bezierCurveTo(x+136.306,y+342.05,x+131.295,y+347.158,x+125.113,y+347.158);
		ctx.lineTo(x+87.1581,y+347.158);
		ctx.bezierCurveTo(x+80.9763,y+347.158,x+75.9652,y+342.05,x+75.9652,y+335.748);
		ctx.lineTo(x+75.9652,y+332.167);
		ctx.bezierCurveTo(x+75.9652,y+325.865,x+70.9541,y+320.757,x+64.7723,y+320.757);
		ctx.lineTo(x+40.6687,y+320.757);
		ctx.bezierCurveTo(x+34.4869,y+320.757,x+29.4758,y+315.648,x+29.4758,y+309.346);
		ctx.lineTo(x+29.4758,y+232.472);
		ctx.bezierCurveTo(x+29.4758,y+226.17,x+24.4647,y+221.062,x+18.2829,y+221.062);
		ctx.lineTo(x+11.1929,y+221.062);
		ctx.bezierCurveTo(x+5.0111,y+221.062,x+0,y+215.953,x+0,y+209.651);
		ctx.lineTo(x+0,y+101.39);
		ctx.bezierCurveTo(x+0,y+95.0882,x+5.0111,y+89.9796,x+11.1929,y+89.9796);
		ctx.lineTo(x+18.2829,y+89.9796);
		ctx.bezierCurveTo(x+24.4647,y+89.9796,x+29.4758,y+84.8711,x+29.4758,y+78.569);
		ctx.lineTo(x+29.4758,y+60.2541);
		ctx.bezierCurveTo(x+29.4758,y+53.9521,x+34.4869,y+48.8435,x+40.6687,y+48.8435);
		ctx.lineTo(x+125.124,y+48.8435);
		ctx.bezierCurveTo(x+131.306,y+48.8435,x+136.317,y+43.735,x+136.317,y+37.4329);
		ctx.lineTo(x+136.317,y+11.4106);
		ctx.bezierCurveTo(x+136.317,y+5.10855,x+141.328,y+0,x+147.51,y+0);
		ctx.lineTo(x+189.754,y+0);
		ctx.bezierCurveTo(x+195.936,y+0,x+200.947,y+5.10855,x+200.947,y+11.4106);
		ctx.lineTo(x+200.947,y+37.4218);
		ctx.bezierCurveTo(x+200.947,y+43.7238,x+205.958,y+48.8324,x+212.14,y+48.8324);
		ctx.lineTo(x+262.262,y+48.8324);
		ctx.bezierCurveTo(x+268.444,y+48.8324,x+273.455,y+43.7238,x+273.455,y+37.4218);
		ctx.lineTo(x+273.455,y+23.2896);
		ctx.bezierCurveTo(x+273.455,y+16.9876,x+278.466,y+11.879,x+284.648,y+11.879);
		ctx.lineTo(x+333.938,y+11.879);
		ctx.bezierCurveTo(x+340.12,y+11.879,x+345.131,y+16.9876,x+345.131,y+23.2896);
		ctx.lineTo(x+345.131,y+37.4218);
		ctx.bezierCurveTo(x+345.131,y+43.7238,x+350.142,y+48.8324,x+356.324,y+48.8324);
		ctx.bezierCurveTo(x+362.506,y+48.8324,x+367.517,y+53.9409,x+367.517,y+60.2429);
		ctx.lineTo(x+367.517,y+91.5412);
		ctx.bezierCurveTo(x+367.517,y+97.8432,x+372.528,y+102.952,x+378.71,y+102.952);
		ctx.lineTo(x+415.527,y+102.952);
		ctx.bezierCurveTo(x+421.709,y+102.952,x+426.72,y+108.06,x+426.72,y+114.362);
		ctx.lineTo(x+426.72,y+154.64);
		ctx.bezierCurveTo(x+426.72,y+160.942,x+421.709,y+166.05,x+415.527,y+166.05);
		ctx.lineTo(x+378.71,y+166.05);
		ctx.bezierCurveTo(x+372.528,y+166.05,x+367.517,y+171.159,x+367.517,y+177.461);
		ctx.lineTo(x+367.517,y+235.417);
		ctx.bezierCurveTo(x+367.517,y+241.719,x+372.528,y+246.828,x+378.71,y+246.828);
		ctx.lineTo(x+395.253,y+246.828);
		ctx.bezierCurveTo(x+401.435,y+246.828,x+406.446,y+251.936,x+406.446,y+258.238);
		ctx.lineTo(x+406.446,y+347.616);
		ctx.bezierCurveTo(x+406.446,y+353.918,x+401.435,y+359.026,x+395.253,y+359.026);
		ctx.lineTo(x+284.637,y+359.026);
		ctx.bezierCurveTo(x+278.455,y+359.026,x+273.444,y+353.918,x+273.444,y+347.616);
		ctx.lineTo(x+273.444,y+332.156);
		ctx.bezierCurveTo(x+273.444,y+325.854,x+268.433,y+320.745,x+262.251,y+320.745);
		ctx.lineTo(x+262.251,y+320.757);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

	drawElementBush(ctx, scale, x, y){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle="#3BB753";
		ctx.beginPath();
		ctx.moveTo(x+146.835,y+37.8301);
		ctx.bezierCurveTo(x+144.806,y+37.8301,x+142.849,y+38.1165,x+140.987,y+38.6178);
		ctx.bezierCurveTo(x+141.083,y+37.9017,x+141.154,y+37.1618,x+141.154,y+36.422);
		ctx.bezierCurveTo(x+141.154,y+27.2329,x+133.708,y+19.8101,x+124.542,y+19.8101);
		ctx.bezierCurveTo(x+122.418,y+19.8101,x+120.39,y+20.2158,x+118.528,y+20.9557);
		ctx.bezierCurveTo(x+113.874,y+8.71166,x+102.059,y+0,x+88.1922,y+0);
		ctx.bezierCurveTo(x+88.1683,y+0,x+88.1444,y+0,x+88.0967,y+0);
		ctx.lineTo(x+88.0967,y+60.5998);
		ctx.lineTo(x+169.103,y+60.5998);
		ctx.bezierCurveTo(x+169.103,y+60.4327,x+169.103,y+60.2895,x+169.103,y+60.1224);
		ctx.bezierCurveTo(x+169.103,y+47.8068,x+159.127,y+37.8301,x+146.811,y+37.8301);
		ctx.lineTo(x+146.835,y+37.8301);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#239B48";
		ctx.beginPath();
		ctx.moveTo(x+88.0953,y+0.03125);
		ctx.bezierCurveTo(x+76.3047,y+0.0551176,x+66.0416,y+6.38,x+60.3611,y+15.7838);
		ctx.bezierCurveTo(x+58.9052,y+15.2587,x+57.33,y+14.9485,x+55.6831,y+14.9485);
		ctx.bezierCurveTo(x+48.6899,y+14.9485,x+42.9378,y+20.2232,x+42.174,y+27.0016);
		ctx.bezierCurveTo(x+41.6728,y+26.9539,x+41.1955,y+26.93,x+40.6704,y+26.93);
		ctx.bezierCurveTo(x+33.9159,y+26.93,x+28.116,y+30.9636,x+25.5145,y+36.7396);
		ctx.bezierCurveTo(x+24.4643,y+36.5964,x+23.3902,y+36.477,x+22.2923,y+36.477);
		ctx.bezierCurveTo(x+9.97664,y+36.477,x+0,y+46.4537,x+0,y+58.7693);
		ctx.bezierCurveTo(x+0,y+59.3899,x+0.0477556,y+60.0105,x+0.0954907,y+60.631);
		ctx.lineTo(x+88.0953,y+60.631);
		ctx.lineTo(x+88.0953,y+0.03125);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}

	drawElementTree(ctx, scale, x, y){
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle="#684B1C";
		ctx.beginPath();
		ctx.moveTo(x+12.8621,y+50.6531);
		ctx.lineTo(x+10.9609,y+50.6531);
		ctx.lineTo(x+10.9609,y+32.043);
		ctx.lineTo(x+12.8621,y+32.043);
		ctx.lineTo(x+13.3887,y+43.2046);
		ctx.lineTo(x+12.8621,y+50.6531);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#845F29";
		ctx.beginPath();
		ctx.moveTo(x+14.7625,y+32.043);
		ctx.lineTo(x+12.8613,y+32.043);
		ctx.lineTo(x+12.8613,y+50.6531);
		ctx.lineTo(x+14.7625,y+50.6531);
		ctx.lineTo(x+14.7625,y+32.043);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#239B48";
		ctx.beginPath();
		ctx.moveTo(x+12.8621,y+0.453332);
		ctx.bezierCurveTo(x+8.53307,y+0.422092,x+5.01187,y+3.92545,x+5.01187,y+8.24997);
		ctx.bezierCurveTo(x+5.01187,y+8.54006,x+5.02973,y+8.83014,x+5.06097,y+9.1113);
		ctx.bezierCurveTo(x+5.32428,y+11.4766,x+4.74857,y+13.8821,x+3.20888,y+15.6985);
		ctx.bezierCurveTo(x+1.3434,y+17.8942,x+0.21875,y+20.7326,x+0.21875,y+23.8388);
		ctx.bezierCurveTo(x+0.21875,y+30.8098,x+5.8866,y+36.4598,x+12.8665,y+36.4285);
		ctx.lineTo(x+15.9057,y+20.9915);
		ctx.lineTo(x+12.8665,y+0.457793);
		ctx.lineTo(x+12.8621,y+0.453332);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#3BB753";
		ctx.beginPath();
		ctx.moveTo(x+22.4043,y+15.6938);
		ctx.bezierCurveTo(x+20.8647,y+13.8774,x+20.289,y+11.472,x+20.5523,y+9.10663);
		ctx.bezierCurveTo(x+20.5835,y+8.82547,x+20.6014,y+8.53539,x+20.6014,y+8.2453);
		ctx.bezierCurveTo(x+20.6014,y+3.96094,x+17.1426,y+0.484365,x+12.8672,y+0.453125);
		ctx.lineTo(x+12.8672,y+36.4239);
		ctx.bezierCurveTo(x+19.7936,y+36.3926,x+25.3945,y+30.7694,x+25.3945,y+23.8386);
		ctx.bezierCurveTo(x+25.3945,y+20.7324,x+24.2698,y+17.894,x+22.4043,y+15.6983);
		ctx.lineTo(x+22.4043,y+15.6938);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawElementRock (ctx, scale, x, y) {
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle="#3C5160";
		ctx.beginPath();
		ctx.moveTo(x+40.7558,y+54.377);
		ctx.lineTo(x+0.491211,y+54.377);
		ctx.lineTo(x+8.03345,y+12.2029);
		ctx.bezierCurveTo(x+9.25069,y+5.44839,x+15.1221,y+0.507812,x+21.9959,y+0.507812);
		ctx.lineTo(x+40.7558,y+0.507812);
		ctx.lineTo(x+47.3911,y+18.4324);
		ctx.lineTo(x+40.7558,y+54.3531);
		ctx.lineTo(x+40.7558,y+54.377);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#475D6B";
		ctx.beginPath();
		ctx.moveTo(x+40.7549,y+54.377);
		ctx.lineTo(x+81.0195,y+54.377);
		ctx.lineTo(x+73.4774,y+12.2029);
		ctx.bezierCurveTo(x+72.2601,y+5.44839,x+66.3888,y+0.507812,x+59.5149,y+0.507812);
		ctx.lineTo(x+40.7549,y+0.507812);
		ctx.lineTo(x+40.7549,y+54.377);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
	drawElementMountain (ctx, scale, x, y) {
		x = x / scale;
		y = y / scale;
		ctx.save();
		ctx.strokeStyle="rgba(0,0,0,0)";
		ctx.miterLimit=4;
		ctx.fillStyle="rgba(0,0,0,0)";
		ctx.scale(scale,scale);
		ctx.save();
		ctx.fillStyle="#19737C";
		ctx.beginPath();
		ctx.moveTo(x+0.448242,y+60.6716);
		ctx.lineTo(x+32.3065,y+12.5682);
		ctx.lineTo(x+40.4797,y+5.05078);
		ctx.lineTo(x+45.1058,y+30.6137);
		ctx.lineTo(x+40.4797,y+60.6716);
		ctx.lineTo(x+0.448242,y+60.6716);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#35A2A5";
		ctx.beginPath();
		ctx.moveTo(x+80.505,y+60.6726);
		ctx.lineTo(x+48.8494,y+12.8674);
		ctx.lineTo(x+40.4795,y+7.04297);
		ctx.lineTo(x+40.4795,y+60.6726);
		ctx.lineTo(x+80.505,y+60.6726);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="#F4F4F4";
		ctx.beginPath();
		ctx.moveTo(x+40.4796,y+0.230469);
		ctx.lineTo(x+32.1097,y+12.8689);
		ctx.bezierCurveTo(x+31.4837,y+13.8167,x+32.366,y+15.0329,x+33.4629,y+14.7288);
		ctx.lineTo(x+35.3408,y+14.2042);
		ctx.lineTo(x+34.9175,y+16.4696);
		ctx.bezierCurveTo(x+34.7089,y+17.5963,x+35.9787,y+18.4011,x+36.9027,y+17.7275);
		ctx.bezierCurveTo(x+38.5839,y+16.5113,x+40.4677,y+15.1342,x+40.4677,y+15.0985);
		ctx.bezierCurveTo(x+40.4677,y+15.0806,x+42.6377,y+15.6827,x+42.6377,y+15.6827);
		ctx.lineTo(x+40.4796,y+0.230469);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.save();
		ctx.fillStyle="white";
		ctx.beginPath();
		ctx.moveTo(x+40.4795,y+0.228614);
		ctx.lineTo(x+48.8494,y+12.867);
		ctx.bezierCurveTo(x+49.4754,y+13.8149,x+48.5931,y+15.031,x+47.4962,y+14.727);
		ctx.lineTo(x+45.6183,y+14.2024);
		ctx.lineTo(x+46.0416,y+16.4677);
		ctx.bezierCurveTo(x+46.2502,y+17.5945,x+44.9804,y+18.3993,x+44.0564,y+17.7256);
		ctx.bezierCurveTo(x+42.3752,y+16.5095,x+40.4914,y+15.1324,x+40.4914,y+15.0966);
		ctx.bezierCurveTo(x+40.4914,y+15.0787,x+40.4795,y+0.222656,x+40.4795,y+0.222656);
		ctx.lineTo(x+40.4795,y+0.228614);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
		ctx.restore();
	}
}
