import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filtro-toogle',
  templateUrl: './filtro-toogle.component.html',
  styleUrls: ['./filtro-toogle.component.scss'],
})
export class FiltroToogleComponent  implements OnInit {
  @Input() filterActive:boolean = false;
  @Input() filters;
  filterSelected = [];

  @Output() filterBy: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  filter() {
    this.filterSelected = [];
		this.filters.forEach(element => {
			if(element.checked){
				this.filterSelected.push(element.code)
			}
		});
    // console.log("aplicar: ",this.filterSelected);
    this.filterBy.emit(this.filterSelected);
  }

}
