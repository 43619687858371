<ion-searchbar
mode="ios"
animated="false"
[placeholder]="placeholder"
[debounce]="debounce"
inputmode="text"
(ionInput)="onSearch($event)"
show-cancel-button="never"
show-clear-button="never"
class="w-full custom-search-bar-comp"

>
</ion-searchbar>

<!-- [ngClass]="{'documents-searchbar-mobile': displaySearchbar}" -->
