<div class="reward-received w-11/12 sm:w-108 lg:w-[490px] rounded-lg  bg-white m-auto">
  <div id="confetti" *ngIf="showConfetti"></div>
  <div class="modal-reward-title bg-primary  text-white py-3 lg:py-4 px-4 rounded-t-lg">
    <span class="text-center font-bold">¡Felicitaciones!</span>
    <!-- <button (click)="closeModal()" class="flex justify-center items-center bg-transparent" >
      <ion-icon name="close" class="text-2xl leading-5 max-h-[24px]"></ion-icon>
    </button> -->
  </div>

  <div class=" flex flex-col items-center gap-4 p-4 rounded-lg">
    <p class="text-base font-bold">Has conseguido </p>
    <div class="flex gap-5">
      <div class="w-28 h-28">
        <img *ngIf="coinImage" [src]="coinImage" class="w-full h-full object-contain">
        <ng-lottie *ngIf="!coinImage"
        [options]="coinJumpOptions"
        width="100%"
        ></ng-lottie>
      </div>

      <div class="flex flex-col gap-1 justify-center items-start pb-2">
        <span class="text-[80px] leading-[84px] font-bold">{{coinsReceived}}</span>
        <span class="text-xl ">{{coinsName  | capitalize}}</span>
      </div>
    </div>
    <ul class="ml-3 pl-3  list-disc list-outside">
      <li *ngFor="let reason of reasons">{{reason}}</li>
    </ul>
    <app-primary-button btnText="Aceptar" (btnClick)="closeModal()"></app-primary-button>
  </div>

</div>
