import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-basic-modal-type-sheet',
  templateUrl: './basic-modal-type-sheet.component.html',
  styleUrls: ['./basic-modal-type-sheet.component.scss'],
})
export class BasicModalTypeSheetComponent implements OnInit {
  // modalMessages: string[] | null = null;
  @Input()projectedContent: TemplateRef<any>;
  @Input()notClose: boolean | null = false;

  constructor(private modalCtrl: ModalController, private navParams: NavParams,) { }

  ngOnInit() { }
  closeModal(okStatus) {
    //okStatus: true/false, define si se aceptó el mensaje del modal o se cerró el modal sin aceptar
    this.modalCtrl.dismiss({
      aceptar: okStatus
    });
  }

}
