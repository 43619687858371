import { Pipe, PipeTransform } from '@angular/core';
import { Capacitor } from '@capacitor/core';
const win: any = window;
@Pipe({
  name: 'renderLocalFile',
})
export class RenderLocalFilePipe implements PipeTransform {
  constructor(
  ) {}

  transform(value: any, ...args: any[]): any {
    const hasLocalPath = args[0];
    // console.log("***************pipe render local file capacitoe********", Capacitor.convertFileSrc(value) );
    if (hasLocalPath) {
      return Capacitor.convertFileSrc(value);
    } else {
      return value;
    }
  }
}
